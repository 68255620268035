import { useEffect, useState } from "react";
import Footer from "../sections/Footer";
import logo from '../assets/images/logo.png'
import herobanner from "../assets/images/hero-banner-v2-image.png"
import FooterLogo1 from "../assets/images/checkout-footer-logo-1.png"
import FooterLogo2 from "../assets/images/checkout-footer-logo-2.png"
import FooterLogo3 from "../assets/images/checkout-footer-logo-3.png"
import FooterLogo4 from "../assets/images/checkout-footer-logo-4.png"
import FooterLogo5 from "../assets/images/checkout-footer-logo-5.png"
import FooterLogo6 from "../assets/images/checkout-footer-logo-6.png"
import FooterLogo7 from "../assets/images/checkout-footer-logo-7.png"
import FooterLogo8 from "../assets/images/checkout-footer-logo-8.png"
import gridIcon1 from "../assets/images/grid-icon-1.png"
import gridIcon2 from "../assets/images/grid-icon-2.png"
import gridIcon5 from "../assets/images/grid-icon-5.png"
import gridIcon6 from "../assets/images/grid-icon-6.png"
import preventIcon1 from '../assets/images/prevent-icon-1.png'
import preventIcon2 from '../assets/images/prevent-icon-2.png'
import preventIcon3 from '../assets/images/prevent-icon-3.png'
import preventIcon4 from '../assets/images/prevent-icon-4.png'
import preventIcon5 from '../assets/images/prevent-icon-5.png'
import preventIcon6 from '../assets/images/prevent-icon-6.png'
import SliderCustomers from "../sections/SliderCustomers";
import FAQBlock from "../sections/FAQBlock";
import OverflowingImage1 from '../assets/images/overflowing-image-1.png'
import { showNextSibling } from "../helper";
import imageWithText1 from '../assets/images/landing-page-v2-image-with-text.jpg'


const LandingPageV2 = ({ classes }) =>{

    function getOperatingSystem(window) {
        let operatingSystem = 'Not known';
        if (window.navigator.appVersion.indexOf('Win') !== -1) { operatingSystem = 'Windows OS'; }
        if (window.navigator.appVersion.indexOf('Mac') !== -1) { operatingSystem = 'MacOS'; }
        if (window.navigator.appVersion.indexOf('X11') !== -1) { operatingSystem = 'UNIX OS'; }
        if (window.navigator.appVersion.indexOf('Linux') !== -1) { operatingSystem = 'Linux OS'; }
      
        return operatingSystem;
    }

    //const [position, setPosition] = useState({ latitude: null, longitude: null });

    const [userLocation, setUserLocation]  = useState(""); 

    const [ip, setIP] = useState("");

    const getData = async () => {
        fetch('https://api.ipify.org?format=json').then(response => {
            return response.json();
        }).then((res) => {
            setIP(res.ip);
            fetch('https://api.iplocation.net/?ip=' + res.ip ).then(response => {
                return response.json();
            }).then((res) => {
                console.log(res);
                setUserLocation(res.country_name)
            }).catch((err) => console.error('Problem fetching my IP', err))

        }).catch((err) => console.error('Problem fetching my IP', err))

    };

    useEffect(() => {
/*
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(function (position) {
                setPosition({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
                });
            });
        } else {
            console.log("Geolocation is not available in your browser.");
        }
*/
        document.querySelectorAll('.lazyload-bg').forEach( (div) => {
            div.classList.add('loaded')
        })
        getData();
    }, [] );

    return (
        <>
            <div className="landing-page-v2">
                <div id="page-container">
                    
                    <header id="header">
                        <div className="wrapper flex-wrap flex-justify-between flex-align-center">
                            <div className="header-logo flex-wrap flex-align-center">
                                <a href="/" className="logo"><img loading="lazy" src={ logo } alt="" className="img-as-block" /></a>
                                <div className="site-slogan">Start Your 24/7 Complete Protection Trial Today, For Only $1!</div>
                            </div>
                            <div className="btn-flex-holder">
                                <a href="/checkout" className="btn blue-btn">
                                    <span>Protect My Identify</span>
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.5264 1.47359H2.98494C2.78953 1.47359 2.60213 1.39596 2.46395 1.25778C2.32578 1.11961 2.24815 0.932203 2.24815 0.736793C2.24815 0.541384 2.32578 0.353977 2.46395 0.215802C2.60213 0.0776263 2.78953 0 2.98494 0H13.2632C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793V11.0519C14 11.2473 13.9224 11.4347 13.7842 11.5729C13.646 11.7111 13.4586 11.7887 13.2632 11.7887C13.0678 11.7887 12.8804 11.7111 12.7422 11.5729C12.604 11.4347 12.5264 11.2473 12.5264 11.0519V1.47359Z" fill="white"/>
                                        <path d="M12.7416 0.215143C12.8799 0.0767931 13.0676 0 13.2632 0C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793C14 0.93245 13.9232 1.12009 13.7849 1.25844L1.25937 13.7839C1.19087 13.8524 1.10954 13.9068 1.02004 13.9438C0.930534 13.9809 0.834603 14 0.737724 14C0.640844 14 0.544914 13.9809 0.455409 13.9438C0.365904 13.9068 0.284578 13.8524 0.216074 13.7839C0.14757 13.7154 0.09323 13.6341 0.0561559 13.5446C0.0190818 13.4551 0 13.3592 0 13.2623C0 13.1654 0.0190818 13.0695 0.0561559 12.98C0.09323 12.8905 0.14757 12.8091 0.216074 12.7406L12.7416 0.215143Z" fill="white"/>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </header>

                    <section id="hero-banner" className="hero-banner section radial-bg loaded">
                        <div className="wrapper">
                            <div className="hero-content">
                                <span className="text-intro text-red">ONLINE THREATS ARE INCREASING</span>
                                <h1 className="med">Protect Your Identity</h1>
                                <p className="big">Protecting your identity has never been more important. As online dangers continue to evolve, safeguarding yourself is imperative.</p>
                                <ul className="checklist">
                                    <li>Enhanced Credit Report Monitoring</li>
                                    <li>Dark Web & Internet Monitoring</li>
                                    <li>$1 Million Identity Theft Insurance*</li>
                                </ul>
                                <div className="btn-flex-holder">
                                    <a href="/checkout" className="btn blue-btn">
                                        <span>Protect My Identify</span>
                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.5264 1.47359H2.98494C2.78953 1.47359 2.60213 1.39596 2.46395 1.25778C2.32578 1.11961 2.24815 0.932203 2.24815 0.736793C2.24815 0.541384 2.32578 0.353977 2.46395 0.215802C2.60213 0.0776263 2.78953 0 2.98494 0H13.2632C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793V11.0519C14 11.2473 13.9224 11.4347 13.7842 11.5729C13.646 11.7111 13.4586 11.7887 13.2632 11.7887C13.0678 11.7887 12.8804 11.7111 12.7422 11.5729C12.604 11.4347 12.5264 11.2473 12.5264 11.0519V1.47359Z" fill="white"/>
                                        <path d="M12.7416 0.215143C12.8799 0.0767931 13.0676 0 13.2632 0C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793C14 0.93245 13.9232 1.12009 13.7849 1.25844L1.25937 13.7839C1.19087 13.8524 1.10954 13.9068 1.02004 13.9438C0.930534 13.9809 0.834603 14 0.737724 14C0.640844 14 0.544914 13.9809 0.455409 13.9438C0.365904 13.9068 0.284578 13.8524 0.216074 13.7839C0.14757 13.7154 0.09323 13.6341 0.0561559 13.5446C0.0190818 13.4551 0 13.3592 0 13.2623C0 13.1654 0.0190818 13.0695 0.0561559 12.98C0.09323 12.8905 0.14757 12.8091 0.216074 12.7406L12.7416 0.215143Z" fill="white"/>
                                        </svg>
                                    </a>
                                    <div className="logos flex-wrap flex-align-center">
                                        <img loading="lazy" src={ FooterLogo1 } className="img-as-block" alt="" />
                                        <img loading="lazy" src={ FooterLogo2 } className="img-as-block" alt="" />
                                        <img loading="lazy" src={ FooterLogo3 } className="img-as-block" alt="" />
                                        <img loading="lazy" src={ FooterLogo4 } className="img-as-block" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="hero-image">
                                <img loading="lazy" src={ herobanner } alt="" className="img-as-block" />
                            </div>
                        </div>
                    </section>

                    <section className="image-with-text section">
                        <div className="wrapper">
                            <div className="image-with-text-block">
                                <div className="image">
                                    <img src={ imageWithText1 } alt="" loading="lazy" />
                                </div>
                                <div className="page-content-block">
                                    <div className="intro button-type"><span>how it works</span></div>
                                    <h2>CyberAlerts Scans the Dark Web</h2>
                                    <p>CyberAlerts scans and monitors dark web API sources that filter the Clearnet and return results back to customers about their compromised information.</p>
                                    <ul>
                                        <li>Multisource monitoring</li>
                                        <li>Real-time alerts</li>
                                        <li>Details of compromised sources</li>
                                        <li>Full dark-web coverage</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>

                    
                    <section id="v2-custom-section-1" className="section flexbox">
                        <div className="wrapper">
                            <div className="medium-page-content-block">
                                <div className="page-content-block text-center">
                                    <div className="intro">Even the most careful people are at risk</div>
                                    <h2>Your Information Is Already Out There!<br/>Have a look:</h2>
                                    <div className="ip-block text-black">
                                        <div>Your IP Address is <span className="">{ip}</span></div>
                                        
                                        { userLocation ? (
                                            <div>You are at <span className=""> { userLocation }
                                            </span></div>
                                        ) : (<></>)}
                                        <div>You use a  <span className="">{ getOperatingSystem(window) }</span></div>
                                    </div>
                                    <p className="desc">And this just scratches the surface of what is known about you. Bad actors can have your identity, your family members’ identity, phone numbers, email info, and even passwords.</p>
                                </div>
                            </div>
                        </div>
                    </section>



                    
                    <section id="section-3" className="flexbox section radial6-bg loaded">
                        <div className="wrapper">
                            <div className="flex-wrap col-gap-30 content-right">
                                <div className="one-half">
                                    <div className="content-block width-2">
                                        <div className="intro">All-in-One Protection Against Identity Theft</div>
                                        <h2>Gain complete visibility of your organisation’s security posture continuously.</h2>
                                        <p>At the core of our mission is the belief that everyone deserves peace of mind when navigating the online realm. We strive to empower our clients with the tools and information they need to defend against cyber-attacks, data breaches, and identity theft. By offering a robust suite of Cyber Security services, we aim to make the internet a safer place for individuals and businesses alike.</p>
                                        <div className="btn-flex-holder">
                                            <a href="/checkout" className="btn blue-btn">
                                                <span>Get Protected</span>
                                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.5264 1.47359H2.98494C2.78953 1.47359 2.60213 1.39596 2.46395 1.25778C2.32578 1.11961 2.24815 0.932203 2.24815 0.736793C2.24815 0.541384 2.32578 0.353977 2.46395 0.215802C2.60213 0.0776263 2.78953 0 2.98494 0H13.2632C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793V11.0519C14 11.2473 13.9224 11.4347 13.7842 11.5729C13.646 11.7111 13.4586 11.7887 13.2632 11.7887C13.0678 11.7887 12.8804 11.7111 12.7422 11.5729C12.604 11.4347 12.5264 11.2473 12.5264 11.0519V1.47359Z" fill="white"/>
                                                <path d="M12.7416 0.215143C12.8799 0.0767931 13.0676 0 13.2632 0C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793C14 0.93245 13.9232 1.12009 13.7849 1.25844L1.25937 13.7839C1.19087 13.8524 1.10954 13.9068 1.02004 13.9438C0.930534 13.9809 0.834603 14 0.737724 14C0.640844 14 0.544914 13.9809 0.455409 13.9438C0.365904 13.9068 0.284578 13.8524 0.216074 13.7839C0.14757 13.7154 0.09323 13.6341 0.0561559 13.5446C0.0190818 13.4551 0 13.3592 0 13.2623C0 13.1654 0.0190818 13.0695 0.0561559 12.98C0.09323 12.8905 0.14757 12.8091 0.216074 12.7406L12.7416 0.215143Z" fill="white"/>
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="one-half">
                                    <div className="grid-icon-boxes">
                                        <div className="grid-icon-box">
                                            <div className="icon"><img loading="lazy" src={ gridIcon1 } alt="" className="img-as-block" /></div>
                                            <h3>Dark Web Monitoring</h3>
                                            <p>Keep your sensitive data safe by actively monitoring the dark web for any leaked information related to your organization.</p>
                                        </div>
                                        <div className="grid-icon-box">
                                            <div className="icon"><img loading="lazy" src={ gridIcon2 } alt="" className="img-as-block" /></div>
                                            <h3>Identity Theft Protection</h3>
                                            <p>Get alerted to any suspicious activities related to your personal information, minimizing the risk of identity theft.</p>
                                        </div>
                                        <div className="grid-icon-box">
                                            <div className="icon"><img loading="lazy" src={ gridIcon5 } alt="" className="img-as-block" /></div>
                                            <h3>Vulnerability Scanning and Assessment</h3>
                                            <p>Our platform provides regular vulnerability scans and assessments to identify and prioritize potential security risks, allowing our clients to take proactive measures to protect their systems.</p>
                                        </div>
                                        <div className="grid-icon-box">
                                            <div className="icon"><img loading="lazy" src={ gridIcon6 } alt="" className="img-as-block" /></div>
                                            <h3>Critical CVE Alerts</h3>
                                            <p>Stay ahead of cyber threats with real-time alerts on critical Common Vulnerabilities and Exposures (CVEs).</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    
                    <section id="section-2c" className="flexbox section">
                        <div className="wrapper">
                            <div className="custom-block-style-2 box-style-1">
                                <div className="styled-content">
                                    <h2>Taking charge of your Online Security</h2>
                                    <p> In an ever-evolving digital landscape riddled with potential threats, our all-in-one Cyber Security service is your trusted guardian. With over 15,456 satisfied users protected daily by our comprehensive tool, we are committed to ensuring a secure online experience for individuals and businesses. Trust us to protect your digital realm, because, together, we are "Safeguarding your digital world, one user at a time."</p>
                                    <div className="flex-wrap col-gap-30 number-grid">
                                        <div className="grid-item">
                                            <div className="number-block">157,345</div>
                                            <p className="small">satisfied users protected daily with our tool.</p>
                                        </div>
                                        <div className="grid-item">
                                            <div className="number-block">302 Million</div>
                                            <p className="small">Scans over dark web pages daily for leaked personal data</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="section-2" className="flexbox section">
                        <div className="wrapper">
                            <div className="flex-wrap col-gap-30 content-right">
                                <div className="one-whole">
                                    <div className="content-block full-width">
                                        <div className="intro">Dark Web Monitoring</div>
                                        <h2>Instantly Identify Stolen Credentials, Infected Devices, and Third-Party Data Exposures on the Dark Web</h2>
                                        <p>CyberAlerts monitors thousands of cybercrime channels across sources as diverse as Telegram, the traditional dark web (Tor) and I2P. Our platform automatically collects, analyzes, structures, and contextualizes dark web data to provide our customers with high-value intelligence specific to their organization. Flare sets up and integrates into your existing security program in 30 minutes and can be easily picked up by junior analysts in a matter of minutes.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="custom-block-style-1 box-style-1">
                                <div className="styled-content">
                                    <h3>Comprehensive Surveillance of Key Dark Web Forums & Markets</h3>
                                    <p>CyberAlert’s platform sets up in 30-minutes, integrates with leading SIEM/SOAR/Ticketing tools, and provides actionable alerts across our vast dataset of current and historic dark web data.</p>
                                    <div className="flex-wrap col-gap-30 number-grid">
                                        <div className="one-third">
                                            <div className="number-block">4,000</div>
                                            <p className="small">Cybercrime Communities Monitored</p>
                                        </div>
                                        <div className="one-third">
                                            <div className="number-block">21 Million</div>
                                            <p className="small">Stealer Logs Ingested</p>
                                        </div>
                                        <div className="one-third">
                                            <div className="number-block">2 Million</div>
                                            <p className="small">Threat Actor Profiles</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="flexbox section">
                        <div className="wrapper text-center">
                            <div className="small-wrap">
                                <h2 className="big gradient-text"><span>Comprehensive Cyber Security Service</span></h2>
                                <h3>Defend against today’s cyber threats.</h3>
                                <p>At the core of our mission is the belief that everyone deserves peace of mind when navigating the online realm. We strive to empower our clients with the tools and information they need to defend against cyber-attacks, data breaches, and identity theft. By offering a robust suite of Cyber Security services, we aim to make the internet a safer place for individuals and businesses alike.</p>
                            </div>
                            

                            <div className="flex-wrap flex-wrap-block col-gap-30 icon-grid custom-slick-slider-6">
                                <div className="one-third">
                                    <div className="content-setup">
                                        <div className="icon"><img loading="lazy" src={ preventIcon1 } alt="" /></div>
                                        <h3>Real-Time Alerts</h3>
                                        <p>Users receive instant notifications if their personal data is found on the dark web. </p>
                                    </div>
                                </div>
                                <div className="one-third">
                                    <div className="content-setup">
                                        <div className="icon"><img loading="lazy" src={ preventIcon2 } alt="" /></div>
                                        <h3>Comprehensive Database Search</h3>
                                        <p>Using multiple API integrations, the tool searches extensively across various databases to ensure thorough coverage. </p>
                                    </div>
                                </div>
                                <div className="one-third">
                                    <div className="content-setup">
                                        <div className="icon"><img loading="lazy" src={ preventIcon3 } alt="" /></div>
                                        <h3>Personal Dashboard</h3>
                                        <p>A user-friendly interface where consumers can check their current risk status, see potential threats, and get advice on how to protect themselves.  </p>
                                    </div>
                                </div>
                                <div className="one-third">
                                    <div className="content-setup">
                                        <div className="icon"><img loading="lazy" src={ preventIcon4 } alt="" /></div>
                                        <h3>Monthly Reports</h3>
                                        <p>Regular insights into personal data exposure trends, potential threats, and security recommendations.</p>
                                    </div>
                                </div>
                                <div className="one-third">
                                    <div className="content-setup">
                                        <div className="icon"><img loading="lazy" src={ preventIcon5 } alt="" /></div>
                                        <h3> Multi-device Support</h3>
                                        <p>Ability to get alerts on all devices – desktop, mobile, and tablet.</p>
                                    </div>
                                </div>
                                <div className="one-third">
                                    <div className="content-setup">
                                        <div className="icon"><img loading="lazy" src={ preventIcon6 } alt="" /></div>
                                        <h3>User Education</h3>
                                        <p>A built-in resource center that educates users about the latest Cyber Security threats, preventive measures, and safe online practices.  </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    
                    <SliderCustomers />


                    <section id="section-7" className="section flexbox price-table radial-bg lazyload-bg" >
                        <div className="wrapper">
                            <div className="medium-page-content-block">
                                <div className="flex-wrap col-gap-30 content-right">
                                    <div className="one-half">
                                        <div className="content-block width-2">
                                            <div className="intro">Plans & Pricing</div>
                                            <h2>All-in-One Managed Cyber Security Subscriptions.</h2>
                                            <p className="med">A simple way to start protecting yourself and your family from emerging cyber threats.</p>
                                            <div className="logos flex-wrap flex-align-center mb-30">
                                                <img loading="lazy" src={ FooterLogo1 } className="img-as-block" alt="" />
                                                <img loading="lazy" src={ FooterLogo2 } className="img-as-block" alt="" />
                                                <img loading="lazy" src={ FooterLogo3 } className="img-as-block" alt="" />
                                                <img loading="lazy" src={ FooterLogo4 } className="img-as-block" alt="" />
                                            </div>
                                            <p>* The introductory purchase price today is valid for the first term of your new subscription. Your subscription renews each year at the then-current subscription renewal price. Pricing is subject to change.</p>
                                        </div>
                                    </div>
                                    <div className="one-half">
                                        <div className="pricing-single-block price-table-section flex-wrap-block">
                                            <div className="price-table-title one-whole">24 HOUR TRIAL</div>
                                            <div className="price-table-price one-whole"><span className="price">$1.00</span></div>
                                            <div className="price-table-content">
                                                <p className="med">Implement foundational security, reduce risk, and get continuous remediation lorem ipsum dolor sit amet.</p>
                                            </div>
                                            
                                            <div className="price-table-list">
                                                <ul>
                                                    <li>
                                                        <div class="accord-item" onClick={ showNextSibling }>Dark Web Alerts</div>
                                                        <div class="accord-content hide-element">
                                                            Implement foundational security, reduce risk, and get continuous remediation lorem ipsum dolor sit amet.
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div class="accord-item" onClick={ showNextSibling }>Monitoring</div>
                                                        <div class="accord-content hide-element">
                                                            Implement foundational security, reduce risk, and get continuous remediation lorem ipsum dolor sit amet.
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div class="accord-item" onClick={ showNextSibling }>Identity Theft Protection</div>
                                                        <div class="accord-content hide-element">
                                                            Implement foundational security, reduce risk, and get continuous remediation lorem ipsum dolor sit amet.
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            
                                            <div className="price-table-btn first">
                                                <a href="/checkout" className="btn blue-btn">
                                                    <span>Try it for 24 hours </span>
                                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12.5264 1.47359H2.98494C2.78953 1.47359 2.60213 1.39596 2.46395 1.25778C2.32578 1.11961 2.24815 0.932203 2.24815 0.736793C2.24815 0.541384 2.32578 0.353977 2.46395 0.215802C2.60213 0.0776263 2.78953 0 2.98494 0H13.2632C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793V11.0519C14 11.2473 13.9224 11.4347 13.7842 11.5729C13.646 11.7111 13.4586 11.7887 13.2632 11.7887C13.0678 11.7887 12.8804 11.7111 12.7422 11.5729C12.604 11.4347 12.5264 11.2473 12.5264 11.0519V1.47359Z" fill="white"/>
                                                    <path d="M12.7416 0.215143C12.8799 0.0767931 13.0676 0 13.2632 0C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793C14 0.93245 13.9232 1.12009 13.7849 1.25844L1.25937 13.7839C1.19087 13.8524 1.10954 13.9068 1.02004 13.9438C0.930534 13.9809 0.834603 14 0.737724 14C0.640844 14 0.544914 13.9809 0.455409 13.9438C0.365904 13.9068 0.284578 13.8524 0.216074 13.7839C0.14757 13.7154 0.09323 13.6341 0.0561559 13.5446C0.0190818 13.4551 0 13.3592 0 13.2623C0 13.1654 0.0190818 13.0695 0.0561559 12.98C0.09323 12.8905 0.14757 12.8091 0.216074 12.7406L12.7416 0.215143Z" fill="white"/>
                                                    </svg>
                                                </a>
                                            </div>
                                            <div className="price-table-btn">
                                                <a href="/pricing" className="btn">View full features</a>
                                            </div>
                                            
                                            <div className="logos flex-wrap flex-align-center flex-justify-center">
                                                <img loading="lazy" src={ FooterLogo5 } className="img-as-block" alt="" />
                                                <img loading="lazy" src={ FooterLogo6 } className="img-as-block" alt="" />
                                                <img loading="lazy" src={ FooterLogo7 } className="img-as-block" alt="" />
                                                <img loading="lazy" src={ FooterLogo8 } className="img-as-block" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <FAQBlock classes="section flexbox flexbox-spacing-2 faq-blocks linear-bg lazyload-bg" />
                    
                    <div className="gradient-border ">
                        <section className="large-padding-section section gradient-bg lazyload-bg loaded">
                            <div className="wrapper">
                                <div className="custom-block-style-3 box-style-2">
                                    <div className="styled-content">
                                            <div className="content-area">
                                                <h2 className="big gradient-text">Safeguarding your <span>digital world</span></h2>
                                                <h4>Comprehensive Cyber Security Solutions for Businesses and Consumers</h4>
                                                <div className="btn-flex-holder">
                                                    <a href="/checkout" className="btn blue-btn">
                                                        <span>Get Protected</span>
                                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M12.5264 1.47359H2.98494C2.78953 1.47359 2.60213 1.39596 2.46395 1.25778C2.32578 1.11961 2.24815 0.932203 2.24815 0.736793C2.24815 0.541384 2.32578 0.353977 2.46395 0.215802C2.60213 0.0776263 2.78953 0 2.98494 0H13.2632C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793V11.0519C14 11.2473 13.9224 11.4347 13.7842 11.5729C13.646 11.7111 13.4586 11.7887 13.2632 11.7887C13.0678 11.7887 12.8804 11.7111 12.7422 11.5729C12.604 11.4347 12.5264 11.2473 12.5264 11.0519V1.47359Z" fill="#000000"></path>
                                                        <path d="M12.7416 0.215143C12.8799 0.0767931 13.0676 0 13.2632 0C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793C14 0.93245 13.9232 1.12009 13.7849 1.25844L1.25937 13.7839C1.19087 13.8524 1.10954 13.9068 1.02004 13.9438C0.930534 13.9809 0.834603 14 0.737724 14C0.640844 14 0.544914 13.9809 0.455409 13.9438C0.365904 13.9068 0.284578 13.8524 0.216074 13.7839C0.14757 13.7154 0.09323 13.6341 0.0561559 13.5446C0.0190818 13.4551 0 13.3592 0 13.2623C0 13.1654 0.0190818 13.0695 0.0561559 12.98C0.09323 12.8905 0.14757 12.8091 0.216074 12.7406L12.7416 0.215143Z" fill="#000000"></path>
                                                        </svg>
                                                    </a>
                                                    <a href="/contact" className="btn white-btn"><span>Get in touch</span></a>
                                                </div>
                                            </div>
                                            <div className="position-relative overflowing-image-1">
                                                <img loading="lazy" src={ OverflowingImage1 } alt="" className="img-as-block" />
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>

                    <Footer />
                </div>
            </div>
        </>
    );
}
export default LandingPageV2