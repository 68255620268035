import { useEffect } from "react";
import EmailLink from "../globals/EmailLink";
import PhoneNumber from "../globals/PhoneNumber";

const FAQGeneral = ({ classes }) =>{
    const faqClick = (e) =>{
        e.target.closest('.faq-block').classList.toggle('active')
        console.log(e);
    }

    useEffect(() => {
        document.querySelectorAll('.lazyload-bg').forEach( (div) => {
            div.classList.add('loaded')
        })
    }, [] );
    return (
        <>
        <section id="section-8" className="section flexbox flexbox-spacing-2 faq-blocks large-padding-section">
            <div className="page-wrapper">
                <div className="faq-accord wider text-black">
                    <h2 className="mb-30">General Faqs</h2>
                    <div className="faq-block">
                        <div className="faq-title" onClick={ faqClick }>
                            <div className="faq-title-text med-text">How does Dark Web monitoring work?</div>
                            <div className="faq-icon">
                                <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M19.0622 0.84695C19.3425 1.12728 19.5 1.50728 19.5 1.90348C19.5 2.29968 19.3425 2.67968 19.0622 2.96001L11.0773 10.9338C10.7966 11.2138 10.4161 11.3711 10.0193 11.3711C9.62258 11.3711 9.24206 11.2138 8.96134 10.9338L0.976511 2.96001C0.829416 2.82314 0.711436 2.65808 0.629608 2.47468C0.547779 2.29129 0.50378 2.09331 0.500233 1.89256C0.496686 1.69182 0.533665 1.49241 0.608964 1.30625C0.684263 1.12008 0.796339 0.95097 0.938506 0.808998C1.08067 0.667026 1.25002 0.555104 1.43644 0.479908C1.62286 0.404713 1.82254 0.367784 2.02357 0.371326C2.22459 0.374868 2.42284 0.418808 2.60649 0.500524C2.79014 0.58224 2.95543 0.700058 3.09249 0.84695L10.0193 7.76425L16.9462 0.84695C17.2269 0.566968 17.6074 0.409704 18.0042 0.409704C18.4009 0.409704 18.7814 0.566968 19.0622 0.84695Z" fill="black"/>
                                </svg>
                            </div>
                        </div>
                        <div className="faq-content">
                            <p>Implement foundational security, reduce risk, and get continuous remediation lorem ipsum dolor sit amet.</p>
                        </div>
                    </div>
                    <div className="faq-block">
                        <div className="faq-title" onClick={ faqClick }>
                            <div className="faq-title-text med-text">What is included in identity theft protection services?</div>
                            <div className="faq-icon">
                                <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M19.0622 0.84695C19.3425 1.12728 19.5 1.50728 19.5 1.90348C19.5 2.29968 19.3425 2.67968 19.0622 2.96001L11.0773 10.9338C10.7966 11.2138 10.4161 11.3711 10.0193 11.3711C9.62258 11.3711 9.24206 11.2138 8.96134 10.9338L0.976511 2.96001C0.829416 2.82314 0.711436 2.65808 0.629608 2.47468C0.547779 2.29129 0.50378 2.09331 0.500233 1.89256C0.496686 1.69182 0.533665 1.49241 0.608964 1.30625C0.684263 1.12008 0.796339 0.95097 0.938506 0.808998C1.08067 0.667026 1.25002 0.555104 1.43644 0.479908C1.62286 0.404713 1.82254 0.367784 2.02357 0.371326C2.22459 0.374868 2.42284 0.418808 2.60649 0.500524C2.79014 0.58224 2.95543 0.700058 3.09249 0.84695L10.0193 7.76425L16.9462 0.84695C17.2269 0.566968 17.6074 0.409704 18.0042 0.409704C18.4009 0.409704 18.7814 0.566968 19.0622 0.84695Z" fill="black"/>
                                </svg>
                            </div>
                        </div>
                        <div className="faq-content">
                            <p>Implement foundational security, reduce risk, and get continuous remediation lorem ipsum dolor sit amet.</p>
                        </div>
                    </div>
                    <div className="faq-block">
                        <div className="faq-title" onClick={ faqClick }>
                            <div className="faq-title-text med-text">Why should I choose CyberAlerts over other Cyber Security solutions?</div>
                            <div className="faq-icon">
                                <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M19.0622 0.84695C19.3425 1.12728 19.5 1.50728 19.5 1.90348C19.5 2.29968 19.3425 2.67968 19.0622 2.96001L11.0773 10.9338C10.7966 11.2138 10.4161 11.3711 10.0193 11.3711C9.62258 11.3711 9.24206 11.2138 8.96134 10.9338L0.976511 2.96001C0.829416 2.82314 0.711436 2.65808 0.629608 2.47468C0.547779 2.29129 0.50378 2.09331 0.500233 1.89256C0.496686 1.69182 0.533665 1.49241 0.608964 1.30625C0.684263 1.12008 0.796339 0.95097 0.938506 0.808998C1.08067 0.667026 1.25002 0.555104 1.43644 0.479908C1.62286 0.404713 1.82254 0.367784 2.02357 0.371326C2.22459 0.374868 2.42284 0.418808 2.60649 0.500524C2.79014 0.58224 2.95543 0.700058 3.09249 0.84695L10.0193 7.76425L16.9462 0.84695C17.2269 0.566968 17.6074 0.409704 18.0042 0.409704C18.4009 0.409704 18.7814 0.566968 19.0622 0.84695Z" fill="black"/>
                                </svg>
                            </div>
                        </div>
                        <div className="faq-content">
                            <p>Implement foundational security, reduce risk, and get continuous remediation lorem ipsum dolor sit amet.</p>
                        </div>
                    </div>
                    <div className="faq-block">
                        <div className="faq-title" onClick={ faqClick }>
                            <div className="faq-title-text med-text">How often does CyberAlerts provide vulnerability scanning and assessment reports?</div>
                            <div className="faq-icon">
                                <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M19.0622 0.84695C19.3425 1.12728 19.5 1.50728 19.5 1.90348C19.5 2.29968 19.3425 2.67968 19.0622 2.96001L11.0773 10.9338C10.7966 11.2138 10.4161 11.3711 10.0193 11.3711C9.62258 11.3711 9.24206 11.2138 8.96134 10.9338L0.976511 2.96001C0.829416 2.82314 0.711436 2.65808 0.629608 2.47468C0.547779 2.29129 0.50378 2.09331 0.500233 1.89256C0.496686 1.69182 0.533665 1.49241 0.608964 1.30625C0.684263 1.12008 0.796339 0.95097 0.938506 0.808998C1.08067 0.667026 1.25002 0.555104 1.43644 0.479908C1.62286 0.404713 1.82254 0.367784 2.02357 0.371326C2.22459 0.374868 2.42284 0.418808 2.60649 0.500524C2.79014 0.58224 2.95543 0.700058 3.09249 0.84695L10.0193 7.76425L16.9462 0.84695C17.2269 0.566968 17.6074 0.409704 18.0042 0.409704C18.4009 0.409704 18.7814 0.566968 19.0622 0.84695Z" fill="black"/>
                                </svg>
                            </div>
                        </div>
                        <div className="faq-content">
                            <p>Implement foundational security, reduce risk, and get continuous remediation lorem ipsum dolor sit amet.</p>
                        </div>
                    </div>
                    <div className="faq-block">
                        <div className="faq-title" onClick={ faqClick }>
                            <div className="faq-title-text med-text">Is CyberAlerts suitable for both individuals and businesses?</div>
                            <div className="faq-icon">
                                <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M19.0622 0.84695C19.3425 1.12728 19.5 1.50728 19.5 1.90348C19.5 2.29968 19.3425 2.67968 19.0622 2.96001L11.0773 10.9338C10.7966 11.2138 10.4161 11.3711 10.0193 11.3711C9.62258 11.3711 9.24206 11.2138 8.96134 10.9338L0.976511 2.96001C0.829416 2.82314 0.711436 2.65808 0.629608 2.47468C0.547779 2.29129 0.50378 2.09331 0.500233 1.89256C0.496686 1.69182 0.533665 1.49241 0.608964 1.30625C0.684263 1.12008 0.796339 0.95097 0.938506 0.808998C1.08067 0.667026 1.25002 0.555104 1.43644 0.479908C1.62286 0.404713 1.82254 0.367784 2.02357 0.371326C2.22459 0.374868 2.42284 0.418808 2.60649 0.500524C2.79014 0.58224 2.95543 0.700058 3.09249 0.84695L10.0193 7.76425L16.9462 0.84695C17.2269 0.566968 17.6074 0.409704 18.0042 0.409704C18.4009 0.409704 18.7814 0.566968 19.0622 0.84695Z" fill="black"/>
                                </svg>
                            </div>
                        </div>
                        <div className="faq-content">
                            <p>Implement foundational security, reduce risk, and get continuous remediation lorem ipsum dolor sit amet.</p>
                        </div>
                    </div>
                    <div className="faq-block mb-50">
                        <div className="faq-title" onClick={ faqClick }>
                            <div className="faq-title-text med-text">What is CyberAlerts?</div>
                            <div className="faq-icon">
                                <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M19.0622 0.84695C19.3425 1.12728 19.5 1.50728 19.5 1.90348C19.5 2.29968 19.3425 2.67968 19.0622 2.96001L11.0773 10.9338C10.7966 11.2138 10.4161 11.3711 10.0193 11.3711C9.62258 11.3711 9.24206 11.2138 8.96134 10.9338L0.976511 2.96001C0.829416 2.82314 0.711436 2.65808 0.629608 2.47468C0.547779 2.29129 0.50378 2.09331 0.500233 1.89256C0.496686 1.69182 0.533665 1.49241 0.608964 1.30625C0.684263 1.12008 0.796339 0.95097 0.938506 0.808998C1.08067 0.667026 1.25002 0.555104 1.43644 0.479908C1.62286 0.404713 1.82254 0.367784 2.02357 0.371326C2.22459 0.374868 2.42284 0.418808 2.60649 0.500524C2.79014 0.58224 2.95543 0.700058 3.09249 0.84695L10.0193 7.76425L16.9462 0.84695C17.2269 0.566968 17.6074 0.409704 18.0042 0.409704C18.4009 0.409704 18.7814 0.566968 19.0622 0.84695Z" fill="black"/>
                                </svg>
                            </div>
                        </div>
                        <div className="faq-content">
                            <p>Implement foundational security, reduce risk, and get continuous remediation lorem ipsum dolor sit amet.</p>
                        </div>
                    </div>
                    <div className="send-message-inquiry linear-bg lazyload-bg">
                        <h3>Can't Find Your Answers?</h3>
                        <p className="med">If you haven't found the answers you're looking for in our FAQs, don't worry. Our team of experts is ready to assist you. Please reach out to our customer support team, and we'll be more than happy to provide you with the information you need to make informed decisions about your online security.</p>
                        <div className="send-message-contacts">
                            <div><EmailLink /></div>
                            <div><PhoneNumber /></div>
                        </div>
                        <div className="btn-flex-holder">
                            <a href="/contact" className="btn blue-btn">
                                <span>Send Message</span>
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.5264 1.47359H2.98494C2.78953 1.47359 2.60213 1.39596 2.46395 1.25778C2.32578 1.11961 2.24815 0.932203 2.24815 0.736793C2.24815 0.541384 2.32578 0.353977 2.46395 0.215802C2.60213 0.0776263 2.78953 0 2.98494 0H13.2632C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793V11.0519C14 11.2473 13.9224 11.4347 13.7842 11.5729C13.646 11.7111 13.4586 11.7887 13.2632 11.7887C13.0678 11.7887 12.8804 11.7111 12.7422 11.5729C12.604 11.4347 12.5264 11.2473 12.5264 11.0519V1.47359Z" fill="white"></path>
                                <path d="M12.7416 0.215143C12.8799 0.0767931 13.0676 0 13.2632 0C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793C14 0.93245 13.9232 1.12009 13.7849 1.25844L1.25937 13.7839C1.19087 13.8524 1.10954 13.9068 1.02004 13.9438C0.930534 13.9809 0.834603 14 0.737724 14C0.640844 14 0.544914 13.9809 0.455409 13.9438C0.365904 13.9068 0.284578 13.8524 0.216074 13.7839C0.14757 13.7154 0.09323 13.6341 0.0561559 13.5446C0.0190818 13.4551 0 13.3592 0 13.2623C0 13.1654 0.0190818 13.0695 0.0561559 12.98C0.09323 12.8905 0.14757 12.8091 0.216074 12.7406L12.7416 0.215143Z" fill="white"></path>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
}
export default FAQGeneral