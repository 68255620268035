import { Outlet } from "react-router-dom";
import { fetchData } from "../helper";
import Nav from "../sections/Nav";
import '../App.css';
import '../responsive.css';

export function sitepageLoader(){
    const userName = fetchData('userName')
    return { userName }
}

const Sitepage = () =>{
    return (
        <div className="layout">
            <Nav />
            <main>
                <Outlet />
            </main>
        </div>
    );
}
export default Sitepage