import { useEffect } from "react";
import Footer from "../sections/Footer";
import Nav from "../sections/Nav";
import blogPostImage1 from '../assets/images/blog-post-1.jpg'
import blogPostImage2 from '../assets/images/blog-post-2.jpg'
import blogPostImage3 from '../assets/images/blog-post-3.jpg'
import blogPostImage4 from '../assets/images/blog-post-4.jpg'
import blogPostImage5 from '../assets/images/blog-post-5.jpg'
import blogPostImage6 from '../assets/images/blog-post-6.jpg'
import BannerHomepage2 from "../sections/BannerHomepage2";


const BlogPage = ({ classes }) =>{


    useEffect(() => {
        document.querySelectorAll('.lazyload-bg').forEach( (div) => {
            div.classList.add('loaded')
        })
    }, [] );


    return (
        <>
            <div className={ classes }>
                <Nav />
                <section id="page-banner" className="page-banner page-banner-no-image section large-padding-section">
                    <div className="page-wrapper">
                        <div className="page-banner-content">
                            <div className="intro">RESOURCES</div>
                            <h1 className="med">CyberAlerts Blog</h1>
                            <p className="big">Cyber Security knowledge and tools from the CyberAlerts team</p>
                            <div className="blog-categories">
                                <a href="/blog">Account Takeover</a>
                                <a href="/blog">Active Directory</a>
                                <a href="/blog">Password Protection</a>
                                <a href="/blog">Cyber Security</a>
                                <a href="/blog">Data Breaches</a>
                                <a href="/blog">Identity Breach Monitoring</a>
                                <a href="/blog">Password Security</a>
                                <a href="/blog">CVE Assessment</a>
                                <a href="/blog">Password Tips</a>
                                <a href="/blog">Threats</a>
                                <a href="/blog" className="active">All Categories</a>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <section className="section blog-posts small-padding-section">
                <div className="page-wrapper">
                    <h3>Viewing All Blogs</h3>
                    <div className="blog-posts-block">
                        <div className="blog-post">
                            <div className="blog-post-image">
                                <img src={ blogPostImage1 } loading="lazy" alt="" className="img-as-block" />
                            </div>
                            <div className="blog-post-categories">Cyber Security</div>
                            <h4>Insights from 2023’s Cost of a Data Breach Report</h4>
                            <p>The average cost of a data breach in 2023 jumped to $4.45 million, marking a 15% increase over the past three years according to IBM's report.</p>
                            <div className="read-more">
                                <a href="/blog/articles/blog-article1">Read more 
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.5264 1.47359H2.98494C2.78953 1.47359 2.60213 1.39596 2.46395 1.25778C2.32578 1.11961 2.24815 0.932203 2.24815 0.736793C2.24815 0.541384 2.32578 0.353977 2.46395 0.215802C2.60213 0.0776263 2.78953 0 2.98494 0H13.2632C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793V11.0519C14 11.2473 13.9224 11.4347 13.7842 11.5729C13.646 11.7111 13.4586 11.7887 13.2632 11.7887C13.0678 11.7887 12.8804 11.7111 12.7422 11.5729C12.604 11.4347 12.5264 11.2473 12.5264 11.0519V1.47359Z" fill="black"></path>
                                <path d="M12.7416 0.215143C12.8799 0.0767931 13.0676 0 13.2632 0C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793C14 0.93245 13.9232 1.12009 13.7849 1.25844L1.25937 13.7839C1.19087 13.8524 1.10954 13.9068 1.02004 13.9438C0.930534 13.9809 0.834603 14 0.737724 14C0.640844 14 0.544914 13.9809 0.455409 13.9438C0.365904 13.9068 0.284578 13.8524 0.216074 13.7839C0.14757 13.7154 0.09323 13.6341 0.0561559 13.5446C0.0190818 13.4551 0 13.3592 0 13.2623C0 13.1654 0.0190818 13.0695 0.0561559 12.98C0.09323 12.8905 0.14757 12.8091 0.216074 12.7406L12.7416 0.215143Z" fill="black"></path>
                                </svg>
                                </a>
                            </div>
                        </div>
                        <div className="blog-post">
                            <div className="blog-post-image">
                                <img src={ blogPostImage2 } loading="lazy" alt="" className="img-as-block" />
                            </div>
                            <div className="blog-post-categories">Cyber Security</div>
                            <h4>NIST IA-5 Compliance</h4>
                            <p>Understanding the Role of NIST IA-5 in SP 800-53. One essential aspect is the Control Enhancement IA-5 for password-based authentication:</p>
                            <div className="read-more">
                                <a href="/blog/articles/blog-article2">Read more 
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.5264 1.47359H2.98494C2.78953 1.47359 2.60213 1.39596 2.46395 1.25778C2.32578 1.11961 2.24815 0.932203 2.24815 0.736793C2.24815 0.541384 2.32578 0.353977 2.46395 0.215802C2.60213 0.0776263 2.78953 0 2.98494 0H13.2632C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793V11.0519C14 11.2473 13.9224 11.4347 13.7842 11.5729C13.646 11.7111 13.4586 11.7887 13.2632 11.7887C13.0678 11.7887 12.8804 11.7111 12.7422 11.5729C12.604 11.4347 12.5264 11.2473 12.5264 11.0519V1.47359Z" fill="black"></path>
                                <path d="M12.7416 0.215143C12.8799 0.0767931 13.0676 0 13.2632 0C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793C14 0.93245 13.9232 1.12009 13.7849 1.25844L1.25937 13.7839C1.19087 13.8524 1.10954 13.9068 1.02004 13.9438C0.930534 13.9809 0.834603 14 0.737724 14C0.640844 14 0.544914 13.9809 0.455409 13.9438C0.365904 13.9068 0.284578 13.8524 0.216074 13.7839C0.14757 13.7154 0.09323 13.6341 0.0561559 13.5446C0.0190818 13.4551 0 13.3592 0 13.2623C0 13.1654 0.0190818 13.0695 0.0561559 12.98C0.09323 12.8905 0.14757 12.8091 0.216074 12.7406L12.7416 0.215143Z" fill="black"></path>
                                </svg>
                                </a>
                            </div>
                        </div>
                        <div className="blog-post">
                            <div className="blog-post-image">
                                <img src={ blogPostImage3 } loading="lazy" alt="" className="img-as-block" />
                            </div>
                            <div className="blog-post-categories">Cyber Security</div>
                            <h4>A Guide to GDPR Compliance</h4>
                            <p>Read the key steps an organization handling EU citizens' data should initiate if a data breach transpires to comply with GDPR.</p>
                            <div className="read-more">
                                <a href="/blog/articles/blog-article3">Read more 
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.5264 1.47359H2.98494C2.78953 1.47359 2.60213 1.39596 2.46395 1.25778C2.32578 1.11961 2.24815 0.932203 2.24815 0.736793C2.24815 0.541384 2.32578 0.353977 2.46395 0.215802C2.60213 0.0776263 2.78953 0 2.98494 0H13.2632C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793V11.0519C14 11.2473 13.9224 11.4347 13.7842 11.5729C13.646 11.7111 13.4586 11.7887 13.2632 11.7887C13.0678 11.7887 12.8804 11.7111 12.7422 11.5729C12.604 11.4347 12.5264 11.2473 12.5264 11.0519V1.47359Z" fill="black"></path>
                                <path d="M12.7416 0.215143C12.8799 0.0767931 13.0676 0 13.2632 0C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793C14 0.93245 13.9232 1.12009 13.7849 1.25844L1.25937 13.7839C1.19087 13.8524 1.10954 13.9068 1.02004 13.9438C0.930534 13.9809 0.834603 14 0.737724 14C0.640844 14 0.544914 13.9809 0.455409 13.9438C0.365904 13.9068 0.284578 13.8524 0.216074 13.7839C0.14757 13.7154 0.09323 13.6341 0.0561559 13.5446C0.0190818 13.4551 0 13.3592 0 13.2623C0 13.1654 0.0190818 13.0695 0.0561559 12.98C0.09323 12.8905 0.14757 12.8091 0.216074 12.7406L12.7416 0.215143Z" fill="black"></path>
                                </svg>
                                </a>
                            </div>
                        </div>
                        <div className="blog-post">
                            <div className="blog-post-image">
                                <img src={ blogPostImage4 } loading="lazy" alt="" className="img-as-block" />
                            </div>
                            <div className="blog-post-categories">Cyber Security</div>
                            <h4>Worried About Identity Theft? There’s a Reason for That.</h4>
                            <p>What is identity theft, and what’s happening to increase the impact so dramatically? Read what enterprises can do to make positive change.</p>
                            <div className="read-more">
                                <a href="/blog/articles/blog-article4">Read more 
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.5264 1.47359H2.98494C2.78953 1.47359 2.60213 1.39596 2.46395 1.25778C2.32578 1.11961 2.24815 0.932203 2.24815 0.736793C2.24815 0.541384 2.32578 0.353977 2.46395 0.215802C2.60213 0.0776263 2.78953 0 2.98494 0H13.2632C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793V11.0519C14 11.2473 13.9224 11.4347 13.7842 11.5729C13.646 11.7111 13.4586 11.7887 13.2632 11.7887C13.0678 11.7887 12.8804 11.7111 12.7422 11.5729C12.604 11.4347 12.5264 11.2473 12.5264 11.0519V1.47359Z" fill="black"></path>
                                <path d="M12.7416 0.215143C12.8799 0.0767931 13.0676 0 13.2632 0C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793C14 0.93245 13.9232 1.12009 13.7849 1.25844L1.25937 13.7839C1.19087 13.8524 1.10954 13.9068 1.02004 13.9438C0.930534 13.9809 0.834603 14 0.737724 14C0.640844 14 0.544914 13.9809 0.455409 13.9438C0.365904 13.9068 0.284578 13.8524 0.216074 13.7839C0.14757 13.7154 0.09323 13.6341 0.0561559 13.5446C0.0190818 13.4551 0 13.3592 0 13.2623C0 13.1654 0.0190818 13.0695 0.0561559 12.98C0.09323 12.8905 0.14757 12.8091 0.216074 12.7406L12.7416 0.215143Z" fill="black"></path>
                                </svg>
                                </a>
                            </div>
                        </div>
                        <div className="blog-post">
                            <div className="blog-post-image">
                                <img src={ blogPostImage5 } loading="lazy" alt="" className="img-as-block" />
                            </div>
                            <div className="blog-post-categories">Cyber Security</div>
                            <h4>Avoiding Insider Threats When Layoffs Occur</h4>
                            <p>As layoffs continue, organizations need to be aware of insider threats. Here are some tips you can use to protect your business.</p>
                            <div className="read-more">
                                <a href="/blog/articles/blog-article5">Read more 
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.5264 1.47359H2.98494C2.78953 1.47359 2.60213 1.39596 2.46395 1.25778C2.32578 1.11961 2.24815 0.932203 2.24815 0.736793C2.24815 0.541384 2.32578 0.353977 2.46395 0.215802C2.60213 0.0776263 2.78953 0 2.98494 0H13.2632C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793V11.0519C14 11.2473 13.9224 11.4347 13.7842 11.5729C13.646 11.7111 13.4586 11.7887 13.2632 11.7887C13.0678 11.7887 12.8804 11.7111 12.7422 11.5729C12.604 11.4347 12.5264 11.2473 12.5264 11.0519V1.47359Z" fill="black"></path>
                                <path d="M12.7416 0.215143C12.8799 0.0767931 13.0676 0 13.2632 0C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793C14 0.93245 13.9232 1.12009 13.7849 1.25844L1.25937 13.7839C1.19087 13.8524 1.10954 13.9068 1.02004 13.9438C0.930534 13.9809 0.834603 14 0.737724 14C0.640844 14 0.544914 13.9809 0.455409 13.9438C0.365904 13.9068 0.284578 13.8524 0.216074 13.7839C0.14757 13.7154 0.09323 13.6341 0.0561559 13.5446C0.0190818 13.4551 0 13.3592 0 13.2623C0 13.1654 0.0190818 13.0695 0.0561559 12.98C0.09323 12.8905 0.14757 12.8091 0.216074 12.7406L12.7416 0.215143Z" fill="black"></path>
                                </svg>
                                </a>
                            </div>
                        </div>
                        <div className="blog-post">
                            <div className="blog-post-image">
                                <img src={ blogPostImage6 } loading="lazy" alt="" className="img-as-block" />
                            </div>
                            <div className="blog-post-categories">Cyber Security</div>
                            <h4>Infostealers: The Growing Threat</h4>
                            <p>What should we know about infostealers? Hear our threat research experts explain what they are, what they steal, and much more.</p>
                            <div className="read-more">
                                <a href="/blog/articles/blog-article6">Read more 
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.5264 1.47359H2.98494C2.78953 1.47359 2.60213 1.39596 2.46395 1.25778C2.32578 1.11961 2.24815 0.932203 2.24815 0.736793C2.24815 0.541384 2.32578 0.353977 2.46395 0.215802C2.60213 0.0776263 2.78953 0 2.98494 0H13.2632C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793V11.0519C14 11.2473 13.9224 11.4347 13.7842 11.5729C13.646 11.7111 13.4586 11.7887 13.2632 11.7887C13.0678 11.7887 12.8804 11.7111 12.7422 11.5729C12.604 11.4347 12.5264 11.2473 12.5264 11.0519V1.47359Z" fill="black"></path>
                                <path d="M12.7416 0.215143C12.8799 0.0767931 13.0676 0 13.2632 0C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793C14 0.93245 13.9232 1.12009 13.7849 1.25844L1.25937 13.7839C1.19087 13.8524 1.10954 13.9068 1.02004 13.9438C0.930534 13.9809 0.834603 14 0.737724 14C0.640844 14 0.544914 13.9809 0.455409 13.9438C0.365904 13.9068 0.284578 13.8524 0.216074 13.7839C0.14757 13.7154 0.09323 13.6341 0.0561559 13.5446C0.0190818 13.4551 0 13.3592 0 13.2623C0 13.1654 0.0190818 13.0695 0.0561559 12.98C0.09323 12.8905 0.14757 12.8091 0.216074 12.7406L12.7416 0.215143Z" fill="black"></path>
                                </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <BannerHomepage2 />
            <Footer />
        </>
    );
}
export default BlogPage