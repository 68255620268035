import logo from '../assets/images/logo.png'
import IconCloseBlue from '../assets/svg/icon-close-blue';

const DCustomHeader = () =>{
    return (
        <>
        <div className="dashboard-custom-header">
            <div className='wrap'>
                <div className='flex-wrap flex-wrap-block flex-justify-between flex-align-center position-relative'>
                    <a href="/dashboard" id="d-logo"><img src={ logo } alt="" /></a>
                    <a href='/dashboard' className="custom-page-closer"><IconCloseBlue /></a>
                </div>
            </div>
        </div>
        </>
    );
}
export default DCustomHeader