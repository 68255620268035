const ButtonGetInTouch = ({ classes }) =>{

    let button;

    if( classes ){
        button = <a href="/contact" className={classes}><span>Get in touch</span></a>
    } else {
        button = <a href="/contact" className="btn"><span>Get in touch</span></a>
    }

    return (
        <>
        { button }
        </>
    );
}
export default ButtonGetInTouch