
import { useEffect } from "react";
import IconArrowLeft from "../assets/svg/icon-arrow-left";
import IconCheck from "../assets/svg/icon-check";
import IconGlobe from "../assets/svg/icon-globe";
import Icon3Dots from "../assets/svg/icon-threedots";
import DashboardHeader from "./dbHeader";
import DashboardPageTitle from "./dbPageTitle";
import { getSingleAsset } from "../helper";
import { useParams } from "react-router-dom";

const ProtectionReportPage = () => {

    let params = useParams();
    let firstload = 1
    useEffect(() => {
        if( firstload === 1){
            getSingleAsset(params.id)
        }
        firstload++
    } );
    
    return (
        <>
        <DashboardHeader active="protection" />
        <div className="d-wrapper position-relative">
            <DashboardPageTitle title="" show="hide" />
            <div className="back-to-previous-page">
                <a href="/dashboard/protection"><IconArrowLeft /> Back to protection</a>
            </div>
            <div className="d-block email-report-page layout-2">
                <h2><span id="email-report-id">Email Report</span></h2>
                <div className="wrap position-relative">
                    
                    <div className="full-page-loader" id="full-page-loader">
                        <div className="loader-spinner">
                            <span className="loader big"></span>
                        </div>
                    </div>
                    <div className="d-block-header">
                        <div className="value" id="asset-title"></div>
                        <div className="report-number-and-scan text-right">
                            <div className="report-number" id="report-number"></div>
                            <div className="last-scanned" id="email-report-last-scanned"></div>
                        </div>
                    </div>

                    <div className="flex-wrap flex-justify-between large-score-area">

                        <div className='credit-last-scan'>
                            <div className='icon'><IconCheck /></div>
                            <div className='label'>
                                We scanned the dark web and found <span className='numer-of-risks' id="number-of-risks">2 records for this asset</span>
                                <div className='last-scan-text'>Last scanned: 1:05PM - Feb 10, 2024</div>
                            </div>
                        </div>

                        <div className="large-score">
                            <div className="value" id="asset-score">6/10</div>
                            <div className="" id="asset-risk-type">High Risk</div>
                        </div>

                    </div>

                    <div className="risk-records-blocks">
                        <div id="risk-record-blocks"></div>
                        <div className="risk-record-block">
                            <div className="risk-record-name">
                                <div className="icon"><IconGlobe /></div>
                                <div className="labels">
                                    <div className="title">Phising Site</div>
                                    <div className="url">www.phismenow.com</div>
                                </div>
                            </div>
                            <div className="risk-score-and-type">
                                <div className="risk-score">7/10</div>
                                <div className="risk-type">High Risk</div>
                            </div>
                            <div className="risk-action">
                                <a href="/" className="options"><Icon3Dots /></a>
                            </div>
                        </div>
                        <div className="risk-record-block">
                            <div className="risk-record-name">
                                <div className="icon"><IconGlobe /></div>
                                <div className="labels">
                                    <div className="title">Phising Site</div>
                                    <div className="url">www.phismenow.com</div>
                                </div>
                            </div>
                            <div className="risk-score-and-type">
                                <div className="risk-score">7/10</div>
                                <div className="risk-type">High Risk</div>
                            </div>
                            <div className="risk-action">
                                <a href="/" className="options"><Icon3Dots /></a>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            
            <div className="d-block protection-options layout-2">
                <div className="risk-single-actions" id="risk-action-type">
                    <a href="/" className="options"><Icon3Dots /></a>
                    <a href="/" className="clean-this">Clean<IconArrowLeft /></a>
                </div>
                <div className="table-setup-email-reports">
                    <div className="table-setup-email-report flex-wrap flex-justify-between flex-align-center">
                        <div className="th">Status</div>
                        <div className="td" id="risk-type-status-text"><span class="inline-loader position-relative"><span className="loader-spinner"><span className="loader"></span></span></span></div>
                    </div>
                    <div className="table-setup-email-report action-require-block flex-wrap flex-justify-between flex-align-center">
                        <div className="th">Action <span>required:</span></div>
                        <div className="td" id="risk-action-required"><span class="inline-loader position-relative"><span className="loader-spinner"><span className="loader"></span></span></span></div>
                    </div>
                </div>

                <div className="wrap" id="risk-activity-wrap">
                    <h3>Risk Activity</h3>
                    <div id="risktypehtml">
                        <span class="inline-loader position-relative" style={{ height: '200px', display: 'block' }}><span className="loader-spinner"><span className="loader"></span></span></span>
                    </div>
                    <div className="button-link" id="button-link">
                        <a href="/dashboard/alerts">See all activity</a>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}
export default ProtectionReportPage