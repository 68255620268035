const IconLock = () =>{
    return (
        <>
        
        <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.5625 10.9688C6.5625 11.2148 6.34375 11.4062 6.125 11.4062C5.87891 11.4062 5.6875 11.2148 5.6875 10.9688V8.78125C5.6875 8.5625 5.87891 8.34375 6.125 8.34375C6.34375 8.34375 6.5625 8.5625 6.5625 8.78125V10.9688ZM6.125 0.25C8.03906 0.25 9.625 1.83594 9.625 3.75V5.5H10.0625C11.2656 5.5 12.25 6.48438 12.25 7.6875V12.0625C12.25 13.293 11.2656 14.25 10.0625 14.25H2.1875C0.957031 14.25 0 13.293 0 12.0625V7.6875C0 6.48438 0.957031 5.5 2.1875 5.5H2.625V3.75C2.625 1.83594 4.18359 0.25 6.125 0.25ZM6.125 1.125C4.64844 1.125 3.5 2.30078 3.5 3.75V5.5H8.75V3.75C8.75 2.30078 7.57422 1.125 6.125 1.125ZM2.1875 6.375C1.44922 6.375 0.875 6.97656 0.875 7.6875V12.0625C0.875 12.8008 1.44922 13.375 2.1875 13.375H10.0625C10.7734 13.375 11.375 12.8008 11.375 12.0625V7.6875C11.375 6.97656 10.7734 6.375 10.0625 6.375H2.1875Z" fill="#3C39B7"/>
        </svg>

        </>
    );
}
export default IconLock