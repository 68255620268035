import DashboardCustomGauge from "./DashboardCustomGauge";

const DashboardSectionGauge = ({ title }) =>{

    return (
        <>
        <div className="d-block d-gauge score-graph-1 layout-1 show-title-bar-loader position-relative">
            <div>
              <DashboardCustomGauge />
            </div>
        </div>
      </>
    );
}
export default DashboardSectionGauge