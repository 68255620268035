
const IconArrowRight = () =>{
    return (
        <>
        <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.0117 9.20508L2.4707 16.6816C2.14844 17.0039 1.63281 17.0039 1.375 16.6816L0.923828 16.2305C0.601562 15.9082 0.601562 15.457 0.923828 15.1348L7.49805 8.625L0.923828 2.17969C0.601562 1.85742 0.601562 1.40625 0.923828 1.08398L1.375 0.632812C1.63281 0.310547 2.14844 0.310547 2.4707 0.632812L10.0762 8.10938C10.334 8.43164 10.334 8.88281 10.0117 9.20508Z" fill="black"/>
        </svg>
        </>
    );
}
export default IconArrowRight