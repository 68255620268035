import { Form, Outlet } from "react-router-dom";
import { ModalClose, fetchData, getAssets, getIdentityBubbles, getMainAssets, getMainIdentities, loadNotifications, mongodbURL, removeSingleAsset } from "../helper";

import '../dashboard/assets/dashboard.css'
import '../dashboard/assets/dashboard-responsive.css'
import IconCloseBlue from "../assets/svg/icon-close-blue";
import IconEmail from "../assets/svg/icon-email";
import IconMobile from "../assets/svg/icon-mobile";
import IconLock from "../assets/svg/icon-lock";
import IconAssets from "../assets/svg/icon-assets";
import { useEffect } from "react";
import IconCheck from "../assets/svg/icon-check";

export function DashboardLayoutLoader(){
    const userName = fetchData('userName')
    //console.log(DashboardLayoutLoader,'loading here')
    return { userName }
}

async function formSubmitDeleteAsset(e){
    e.preventDefault()
    document.querySelector('#modal-close-identity').classList.add('redirect')
}

async function formSubmitAddIdentity(e) {
    e.preventDefault()
    e.target.closest('form').querySelector('.asset-placeholder-response').innerText = ''

    let identitytitle = document.querySelector('#identitytitle').value
    let email = fetchData('userName')

    let result = await fetch(
    mongodbURL + 'add-main-identity', {
        method: "post",
        body: JSON.stringify({ email, identitytitle }),
        headers: {
            'Content-Type': 'application/json'
        }
    })
    result = await result.json();
    console.warn(result);

    if( result.status === 'New Identity added.' ){
        document.querySelector('#identitytitle').value = ''
        getMainIdentities()
        getIdentityBubbles()
    }
    e.target.closest('form').querySelector('.asset-placeholder-response').innerText = result.status
}

function refreshAssetAndIdentities(identitygroup){
    if( document.querySelector('#single-identity-id') ){
        getMainAssets(identitygroup)
    } else {
        getAssets()
        getMainAssets()
    }
    if( document.querySelector('#create-your-identity-trigger') ){
        document.querySelectorAll('.custom-page-wrapper .form-blocks-for-assets').forEach(el => el.classList.remove('loaded') )
        document.querySelector('#create-your-identity-trigger').click();
    }
}

async function checkEmail(){
    let value = document.querySelector('#email').value
    let result = await fetch(
        mongodbURL + 'checkemail', {
            method: "post",
            body: JSON.stringify({ email : value }),
            headers: {
                'Content-Type': 'application/json'
            }
    })
    let emailField = document.querySelector('#email')
    result = await result.json();
  
    if(result.status === 'email-registered') {
        emailField.setCustomValidity("Email already registered");
    } else {
        emailField.setCustomValidity("");
    }
  
}

async function formSubmitAddAssetEmail(e) {
    e.preventDefault()
    e.target.closest('form').querySelector('.asset-placeholder-response').innerText = ''
    let assetEmail = document.querySelector('#email').value
    let assetNickname = document.querySelector('#email-nickname').value
    let email = fetchData('userName')
    let identitygroup = '1'
    if( document.querySelector('#single-identity-id') ){
        identitygroup = document.querySelector('#single-identity-id').value
    }

    let result = await fetch(
    mongodbURL + 'add-asset-email', {
        method: "post",
        body: JSON.stringify({ email, assetEmail, identitygroup, assetNickname }),
        headers: {
            'Content-Type': 'application/json'
        }
    })
    result = await result.json();
    console.warn(result);

    if( result.status === 'Email Address successfully added to assets.' ){
        document.querySelector('#email').value = ''
        document.querySelector('#email-nickname').value = ''
        refreshAssetAndIdentities(identitygroup)
    }
    e.target.closest('form').querySelector('.asset-placeholder-response').innerText = result.status
}
async function formSubmitAddAssetPhone(e) {
    e.preventDefault()
    e.target.closest('form').querySelector('.asset-placeholder-response').innerText = ''
    let assetPhone = document.querySelector('#phone').value
    let assetNickname = document.querySelector('#phone-nickname').value
    let email = fetchData('userName')
    let identitygroup = '1'
    if( document.querySelector('#single-identity-id') ){
        identitygroup = document.querySelector('#single-identity-id').value
    }

    let result = await fetch(
    mongodbURL + 'add-asset-phone', {
        method: "post",
        body: JSON.stringify({ email, assetPhone, identitygroup, assetNickname }),
        headers: {
            'Content-Type': 'application/json'
        }
    })
    result = await result.json();
    //console.warn(result);

    if( result.status === 'Phone Number successfully added to assets.' ){
        document.querySelector('#phone').value = ''
        document.querySelector('#phone-nickname').value = ''
        refreshAssetAndIdentities(identitygroup)
    }
    e.target.closest('form').querySelector('.asset-placeholder-response').innerText = result.status
}
async function formSubmitAddAssetPassword(e) {
    e.preventDefault()
    e.target.closest('form').querySelector('.asset-placeholder-response').innerText = ''
    let assetPassword = document.querySelector('#password').value
    let assetNickname = document.querySelector('#password-nickname').value
    let email = fetchData('userName')
    let identitygroup = '1'
    if( document.querySelector('#single-identity-id') ){
        identitygroup = document.querySelector('#single-identity-id').value
    }

    let result = await fetch(
    mongodbURL + 'add-asset-password', {
        method: "post",
        body: JSON.stringify({ email, assetPassword, identitygroup, assetNickname }),
        headers: {
            'Content-Type': 'application/json'
        }
    })
    result = await result.json();
    //console.warn(result);

    if( result.status === 'Password successfully added to assets.' ){
        document.querySelector('#password').value = ''
        document.querySelector('#password-nickname').value = ''
        refreshAssetAndIdentities(identitygroup)
    }
    e.target.closest('form').querySelector('.asset-placeholder-response').innerText = result.status
}
async function formSubmitAddAssetIdentity(e) {
    e.preventDefault()
    e.target.closest('form').querySelector('.asset-placeholder-response').innerText = ''
    let assetIdentity = document.querySelector('#identity').value
    let assetNickname = document.querySelector('#person-nickname').value
    let email = fetchData('userName')
    let identitygroup = '1'
    if( document.querySelector('#single-identity-id') ){
        identitygroup = document.querySelector('#single-identity-id').value
    }

    let result = await fetch(
    mongodbURL + 'add-asset-person', {
        method: "post",
        body: JSON.stringify({ email, assetIdentity, identitygroup, assetNickname }),
        headers: {
            'Content-Type': 'application/json'
        }
    })
    result = await result.json();
    //console.warn(result);

    if( result.status === 'Person successfully added to assets.' ){
        document.querySelector('#identity').value = ''
        document.querySelector('#person-nickname').value = ''
        refreshAssetAndIdentities(identitygroup)
    }
    e.target.closest('form').querySelector('.asset-placeholder-response').innerText = result.status
}

const DashboardLayout = () =>{

    console.log('window.location.pathname',window.location.pathname)


    const AssetAddButton = (e) => {
        e.preventDefault();
        document.querySelectorAll('.add-assets-actions-list li').forEach( li => {
            li.classList.remove('active')
        })
        document.querySelectorAll('.add-assets-main-blocks .add-asset-container').forEach( div => {
            div.classList.add('hide')
        })
        e.target.closest('li').classList.add('active')
        document.querySelector('.' + e.target.closest('a').getAttribute("data-target") ).classList.remove('hide')
    }

    
    useEffect(() => {
        if( window.location.pathname === '/dashboard/create-your-identity' || window.location.pathname.includes('/dashboard/identity')  || window.location.pathname.includes('/dashboard/help') ){
            document.querySelector('body').classList.add('custom-template-1')
        }

        if( window.location.pathname.includes('/dashboard/help') ){
            document.querySelector('body').classList.add('help-page')
        }
        if( fetchData('userName') ){
            document.querySelectorAll('.user-image-src').forEach(el => {
                el.src = fetchData('profile-image')
            })
            loadNotifications()
        }
    },[] );

    console.log('set user image')

    return (
        <div className="layout">
            <main id="main-dashboard">
                
                <Outlet />

                <div id="modal-pop-bg" className="modal-bg">
                    <div id="modal-add-assets" className="modal-content">
                        <a href="/" className="modal-close" onClick={ ModalClose }><IconCloseBlue /></a>
                        <div className="wrap">
                            <h3>Add New Assets</h3>
                            <ul className="add-assets-actions-list">
                                <li className="active assets-emails">
                                    <a href="/dashboard" onClick={ AssetAddButton } data-target="add-asset-email-container">
                                        <span className="icon-block"><IconEmail /></span>
                                        <span className="label">Email Address</span>
                                    </a>
                                </li>
                                <li className="assets-phones">
                                    <a href="/dashboard" onClick={ AssetAddButton } data-target="add-asset-phone-container">
                                        <span className="icon-block"><IconMobile /></span>
                                        <span className="label">Phone Number</span>
                                    </a>
                                </li>
                                <li className="assets-passwords">
                                    <a href="/dashboard" onClick={ AssetAddButton } data-target="add-asset-password-container">
                                        <span className="icon-block"><IconLock /></span>
                                        <span className="label">Password</span>
                                    </a>
                                </li>
                                <li className="assets-person">
                                    <a href="/dashboard" onClick={ AssetAddButton } data-target="add-asset-person-container">
                                        <span className="icon-block"><IconAssets /></span>
                                        <span className="label">Person</span>
                                    </a>
                                </li>
                            </ul>
                            <div className="add-assets-main-blocks">
                                <div className="add-asset-container add-asset-email-container">
                                    <Form method="post" className="form-block" onSubmit={ formSubmitAddAssetEmail }>
                                        <h4>Secure your email address</h4>
                                        <div className="asset-placeholder-response"></div>
                                        <div className="form-block flex-wrap flex-wrap-block">
                                            <div className="form-field one-whole">
                                                <input type="email" className="input-text" id="email" name="email" placeholder="Email address" required onChange={ checkEmail } />
                                            </div>
                                            <div className="form-field one-whole">
                                                <input type="text" className="input-text" id="email-nickname" name="email-nickname" placeholder="Asset Nickname" required />
                                            </div>
                                        </div>
                                        <div className="form-block flex-wrap flex-wrap-block">
                                            <div className="form-field checkbox-field">
                                                <input type="checkbox" id="checkbox1" name="checkbox1" value="agree" required />
                                                <label htmlFor="checkbox1">
                                                    <span></span>
                                                    <p>By checking this box and clicking ‘next’ you agree to be bound by theTerms of Service Agreement, Privacy Policy, and to receive important notices and other communications electronically.</p>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="form-block flex-wrap flex-wrap-block">
                                            <div className="form-field submit-field">
                                            <button type="submit" className="btn blue-btn btn-next" id="checkout-next-step" data-step="1">
                                                <span id="continue-label" className="label">Add Email Address</span></button>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                                <div className="add-asset-container add-asset-phone-container hide">
                                    <Form method="post" className="form-block" onSubmit={ formSubmitAddAssetPhone }>
                                        <h4>Secure your Phone Number</h4>
                                        <div className="asset-placeholder-response"></div>
                                        <div className="form-block flex-wrap flex-wrap-block">
                                            <div className="form-field one-whole">
                                                <input type="phone" className="input-text" id="phone" name="phone" placeholder="Phone Number" required />
                                            </div>
                                            <div className="form-field one-whole">
                                                <input type="text" className="input-text" id="phone-nickname" name="phone-nickname" placeholder="Asset Nickname" required />
                                            </div>
                                        </div>
                                        <div className="form-block flex-wrap flex-wrap-block">
                                            <div className="form-field checkbox-field">
                                                <input type="checkbox" id="checkbox2" name="checkbox1" value="agree" required />
                                                <label htmlFor="checkbox2">
                                                    <span></span>
                                                    <p>By checking this box and clicking ‘next’ you agree to be bound by theTerms of Service Agreement, Privacy Policy, and to receive important notices and other communications electronically.</p>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="form-block flex-wrap flex-wrap-block">
                                            <div className="form-field submit-field">
                                            <button type="submit" className="btn blue-btn btn-next" id="checkout-next-step" data-step="1">
                                                <span id="continue-label" className="label">Add Phone Number</span></button>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                                <div className="add-asset-container add-asset-password-container hide">
                                    <Form method="post" className="form-block" onSubmit={ formSubmitAddAssetPassword }>
                                        <h4>Secure your Password</h4>
                                        <div className="asset-placeholder-response"></div>
                                        <div className="form-block flex-wrap flex-wrap-block">
                                            <div className="form-field one-whole">
                                                <input type="text" className="input-text" id="password" name="password" placeholder="Password" required />
                                            </div>
                                            <div className="form-field one-whole">
                                                <input type="text" className="input-text" id="password-nickname" name="password-nickname" placeholder="Asset Nickname" required />
                                            </div>
                                        </div>
                                        <div className="form-block flex-wrap flex-wrap-block">
                                            <div className="form-field checkbox-field">
                                                <input type="checkbox" id="checkbox3" name="checkbox1" value="agree" required />
                                                <label htmlFor="checkbox3">
                                                    <span></span>
                                                    <p>By checking this box and clicking ‘next’ you agree to be bound by theTerms of Service Agreement, Privacy Policy, and to receive important notices and other communications electronically.</p>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="form-block flex-wrap flex-wrap-block">
                                            <div className="form-field submit-field">
                                            <button type="submit" className="btn blue-btn btn-next" id="checkout-next-step" data-step="1">
                                                <span id="continue-label" className="label">Add Password</span></button>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                                <div className="add-asset-container add-asset-person-container hide">
                                    <Form method="post" className="form-block" onSubmit={ formSubmitAddAssetIdentity }>
                                        <h4>Secure a Person's Identity</h4>
                                        <div className="asset-placeholder-response"></div>
                                        <div className="form-block flex-wrap flex-wrap-block">
                                            <div className="form-field one-whole">
                                                <input type="text" className="input-text" id="identity" name="identity" placeholder="Person's Identity" required />
                                            </div>
                                            <div className="form-field one-whole">
                                                <input type="text" className="input-text" id="person-nickname" name="person-nickname" placeholder="Asset Nickname" required />
                                            </div>
                                        </div>
                                        <div className="form-block flex-wrap flex-wrap-block">
                                            <div className="form-field checkbox-field">
                                                <input type="checkbox" id="checkbox4" name="checkbox1" value="agree" required />
                                                <label htmlFor="checkbox4">
                                                    <span></span>
                                                    <p>By checking this box and clicking ‘next’ you agree to be bound by theTerms of Service Agreement, Privacy Policy, and to receive important notices and other communications electronically.</p>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="form-block flex-wrap flex-wrap-block">
                                            <div className="form-field submit-field">
                                            <button type="submit" className="btn blue-btn btn-next" id="checkout-next-step" data-step="1">
                                                <span id="continue-label" className="label">Add Person</span></button>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                
                <div id="modal-pop-bg-delete" className="modal-bg">
                    <div id="modal-popup-asset-delete" className="modal-content" style={{ height: 'auto' }}>
                        <a href="/dashboard/assets" id="modal-close-identity" className="modal-close" onClick={ ModalClose }><IconCloseBlue /></a>
                        <div className="wrap">
                            <h3>Remove Asset</h3>
                            <div className="add-assets-main-blocks">
                                <div className="add-asset-container add-asset-email-container">
                                    <Form method="post" id="remove-single-asset-form" className="loaded d-block form-block position-relative" onSubmit={ formSubmitDeleteAsset }>
                                        <input type="hidden" value="" name="modal-popup-assetid" id="modal-popup-assetid" />
                                        <div className="loader-spinner">
                                            <span className="loader big"></span>
                                        </div>
                                        <h4 className="h4">Please confirm to delete asset</h4>
                                        <div className="form-block flex-wrap flex-wrap-block">
                                            <div className="form-field checkbox-field">
                                                <input type="checkbox" id="checkbox5" name="checkbox5" value="agree" required />
                                                <label htmlFor="checkbox5">
                                                    <span></span>
                                                    <p>By checking this box and clicking ‘Remove Asset’ you agree to delete this asset.</p>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="form-block flex-wrap flex-wrap-block">
                                            <div className="form-field submit-field">
                                            <button type="submit" className="btn blue-btn btn-next" onClick={ removeSingleAsset }>
                                                <span id="continue-label" className="label">Remove Asset</span></button>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="modal-pop-bg-identities" className="modal-bg">
                    <div id="modal-add-identities" className="modal-content">
                        <a href="/" id="modal-close-identity" className="modal-close" onClick={ ModalClose }><IconCloseBlue /></a>
                        <div className="wrap">
                            <h3>Add New Identity</h3>
                            <div className="add-assets-main-blocks">
                                <div className="add-asset-container add-asset-email-container">
                                    <Form method="post" className="form-block" onSubmit={ formSubmitAddIdentity }>
                                        <h4>Identity Title</h4>
                                        <div className="asset-placeholder-response"></div>
                                        <div className="form-block flex-wrap flex-wrap-block">
                                            <div className="form-field one-whole">
                                                <input type="text" className="input-text" id="identitytitle" name="identitytitle" placeholder="Title" required />
                                            </div>
                                        </div>
                                        <div className="form-block flex-wrap flex-wrap-block">
                                            <div className="form-field checkbox-field">
                                                <input type="checkbox" id="checkbox6" name="checkbox6" value="agree" required />
                                                <label htmlFor="checkbox6">
                                                    <span></span>
                                                    <p>By checking this box and clicking ‘Add Identity’ you agree to be bound by the Terms of Service Agreement, Privacy Policy, and to receive important notices and other communications electronically.</p>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="form-block flex-wrap flex-wrap-block">
                                            <div className="form-field submit-field">
                                            <button type="submit" className="btn blue-btn btn-next" id="checkout-next-step" data-step="1">
                                                <span id="continue-label" className="label">Add Identity</span></button>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="modal-custom-size"></div>
                <div id="modal-notification">
                    <h4>Notifications</h4>
                    <div id="notification-date" className="notification-date hide-element"></div>
                    <ul className="linklist-with-bg-and-arrow has-icon-block" id="site-notifications">
                        <li id="site-notifications-list" className="hide-element"></li>
                    </ul>

                    <div className="see-all-notifications">
                        <a href="/dashboard/alerts">View All Alerts</a>
                    </div>
                </div>

                <div id="modal-pop-bg-scan-assets" className="modal-bg">
                    <div id="modal-scan-assets" className="modal-content">
                        <div className="wrap">
                            <h3>Scan Assets</h3>
                            <div id="scanning-asset-text-response" className="text-center">Scanning Assets...</div>
                            <div className="position-relative" style={{ height: '250px' }}>
                                <div className="loader-spinner">
                                    <span className="loader big"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="modal-pop-bg-save-settings" className="modal-bg">
                    <div id="modal-save-settings" className="modal-content">
                        <div className="wrap">
                            <h3>Settings</h3>
                            <div id="scanning-asset-text-response" className="text-center">Updating...</div>
                            <div className="position-relative" style={{ height: '250px' }}>
                                <div className="loader-spinner">
                                    <span className="loader big"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="modal-pop-bg-loader" className="modal-bg">
                    <div id="modal-loader" className="modal-content">
                        <div className="wrap">
                            <h3 id="modal-loader-title">Applying Filter</h3>
                            <div className="position-relative" style={{ height: '250px' }}>
                                <div className="loader-spinner">
                                    <span className="loader big"></span>
                                </div>
                                <IconCheck />
                            </div>
                        </div>
                    </div>
                </div>

            </main>
        </div>
    );
}
export default DashboardLayout