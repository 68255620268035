
import { redirect } from "react-router-dom";
import { deleteItem } from "../helper";

//loader
export function LogoutLoader(){
    deleteItem('userName');
    deleteItem('id');
    deleteItem('userFullname');
    deleteItem('userData');
    deleteItem('userStatus');
    deleteItem('account-last-scanned');
    
    window.location.href = '/login'
    return redirect('/login')
}

const Logout = () =>{

    //deleteItem('userName')
    //console.log( localStorage.getItem('userName') );
    //window.location.href = '/login'

    return (
        <>
        <ul>
            <li><a href="/login">Login</a></li>
        </ul>
        </>
    );
}
export default Logout