import icon1 from '../assets/images/icon-about-1.png'
import ButtonCheckout from './ButtonCheckout'
import ButtonGetInTouch from './ButtonGetInTouch'

const IconBoxAbout = () => {
    return (
        <>
            <section className="flexbox section med-padding-section radial-bg lazyload-bg">
                <div className="page-wrapper">
                    <div className="page-content-block mb-50">
                        <div className="intro">OUR CORE GOALS</div>
                        <h2>We are bringing together the world’s security expertise</h2>
                        <p>In an increasingly interconnected world, protecting your sensitive information from falling into the wrong hands is essential. Our comprehensive Dark Web Monitoring service empowers you to stay one step ahead of cybercriminals and potential threats.</p>
                    </div>
                    <div className="flex-wrap flex-wrap-block col-gap-30 mb-50">
                        <div className="one-half">
                            <div className="content-setup">
                                <div className="icon"><img loading="lazy" src={ icon1 } alt="" /></div>
                                <h4>A concentration of adversarial security expertise</h4>
                                <p>Highly technical engineers and developers with deep security expertise.</p>
                            </div>
                        </div>
                        <div className="one-half">
                            <div className="content-setup">
                                <div className="icon"><img loading="lazy" src={ icon1 } alt="" /></div>
                                <h4>Powered by an engineering culture.</h4>
                                <p>Our services professionals are security engineers, not consultants. To us, that means real work, real value.</p>
                            </div>
                        </div>
                        <div className="one-half">
                            <div className="content-setup">
                                <div className="icon"><img loading="lazy" src={ icon1 } alt="" /></div>
                                <h4>Driven to innovate across technology.</h4>
                                <p>We're building next-generation solutions that give our customers a helping hand in protecting themselves.</p>
                            </div>
                        </div>
                        <div className="one-half">
                            <div className="content-setup">
                                <div className="icon"><img loading="lazy" src={ icon1 } alt="" /></div>
                                <h4>Determined to have an impact and make craters</h4>
                                <p>We are trusted partners devoted to improving the security of our customers.</p>
                            </div>
                        </div>
                    </div>
                    <div className="btn-flex-holder">
                        <ButtonCheckout />
                        <ButtonGetInTouch />
                    </div>
                </div>
            </section>
        </>
    );
}
export default IconBoxAbout