
const IconArrowDown = () =>{
    return (
        <>
        <svg width="17" height="10" viewBox="0 0 17 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.55429 9.48517L1.22485 2.20525C0.977229 2.00716 0.977229 1.61097 1.22485 1.36335L2.21531 0.422412C2.46293 0.174796 2.80959 0.174796 3.05721 0.422412L9 6.26616L14.8933 0.422412C15.1409 0.174796 15.5371 0.174796 15.7352 0.422412L16.7256 1.36335C16.9732 1.61097 16.9732 2.00716 16.7256 2.20525L9.39619 9.48517C9.14857 9.73279 8.80191 9.73279 8.55429 9.48517Z" fill="#3D3ABB"/>
        </svg>
        </>
    );
}
export default IconArrowDown