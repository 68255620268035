import IconAlerts from "../assets/svg/icon-alerts";
import IconCheck from "../assets/svg/icon-check";
import IconExclamation from "../assets/svg/icon-exclamation";
import IconProtection from "../assets/svg/icon-protection";

const DashboardSectionProtection = ({ title }) =>{
    return (
        <>
        <div className="d-block d-protection layout-1 show-title-bar-loader position-relative">
            <h3><a href="/dashboard/protection"><IconProtection /> Protection</a></h3>
            <div className="loader-spinner">
                <span className="loader big"></span>
            </div>
            <a href="/dashboard/protection?filter=danger" className="protection-block danger-block">
                <div className="icon-block"><IconAlerts /></div>
                <div className="total-val" id="asset-list-total-danger">0</div>
                <div className="block-label">Danger</div>
            </a>
            <a href="/dashboard/protection?filter=compromised" className="protection-block compromised-block">
                <div className="icon-block"><IconExclamation /></div>
                <div className="total-val"id="asset-list-total-compromised">0</div>
                <div className="block-label">Compromised</div>
            </a>
            <a href="/dashboard/protection?filter=corrected" className="protection-block corrected-block">
                <div className="icon-block"><IconCheck /></div>
                <div className="total-val"id="asset-list-total-corrected">0</div>
                <div className="block-label">Corrected</div>
            </a>
        </div>
        </>
    );
}
export default DashboardSectionProtection