import heroBannerTransparent from '../assets/images/hero-banner-transparent.png'
import ButtonCheckout from './ButtonCheckout';
import ButtonGetInTouch from './ButtonGetInTouch';
const BannerAbout = () =>{
    return (
        <>
        <section id="hero-banner" className="hero-banner section radial2-bg lazyload-bg">
            <div className="wrapper">
                <div className="hero-content">
                    <div className="intro">ABOUT US</div>
                    <h2>Actionable dark web threat</h2>
                    <h2 className="big gradient-text"><span>Intelligence</span></h2>
                    <p className="big text-black">We help our customers minimize the likelihood of compromise by using an adversarial perspective to uncover material risks the same way attackers do.</p>
                    <div className="btn-flex-holder">
                        <ButtonCheckout />
                        <ButtonGetInTouch />
                    </div>
                </div>
                <div className="hero-image">
                    <img loading="lazy" src={ heroBannerTransparent } alt="" className="img-as-block" />
                </div>
            </div>
        </section>
        </>
    );
}
export default BannerAbout