/* eslint-disable no-shadow */
import React from 'react';
import IconCheck from '../assets/svg/icon-check';
import IconArrowDown from '../assets/svg/icon-arrow-down';
import { fetchData, scanAssetsFunction } from '../helper';
import TimeAgo from 'react-timeago'
import DashboardCustomGauge from './DashboardCustomGauge';

const DashboardSectionCreditScoreMain = ({ title }) =>{
    return (
        <>
        <div className="d-block d-gauge layout-3">
            <div className="wrap flex-wrap flex-align-center flex-wrap-block">
                <div className="d-block-gauge position-relative loaded-wrapper">
                  <DashboardCustomGauge />
                </div>

                <div className='credit-last-scan position-relative loaded-wrapper'>
                    <div className="loader-spinner">
                        <span className="loader big"></span>
                    </div>
                    <div className='icon'><IconCheck/></div>
                    <div className='label'>
                        We scanned the dark web and found <span className='numer-of-risks'></span>
                        <div className='last-scan-text loaded'>Last scanned: <TimeAgo date={ fetchData('account-last-scanned') } /></div>
                    </div>
                </div>
                <div className='credit-more-action-buttons'>
                    <div className='flex-wrap flex-align-center flex-wrap-block'>
                        <div style={{ display: 'none' }}><a href='/' className='credit-score-more-options'>More options <IconArrowDown /></a></div>
                        <a href='/' className='credit-score-scan-assets' onClick={ scanAssetsFunction }>Scan Assets</a>
                    </div>
                </div>
            </div>
        </div>
      </>
    );
}
export default DashboardSectionCreditScoreMain