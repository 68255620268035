import { useState } from "react";
import logo from "../assets/images/logo.png";
import { mongodbURL } from "../helper";

const CheckoutReports = ({ classes }) =>{

    let i = new URLSearchParams(window.location.search).get("i");

    console.log(i)
    let decodei = atob(i);
    let email = ''

    const [reports, setReports] = useState();


    const getReport = async() => {

        if( decodei.includes('h6cyberalerts') ){
            email = decodei.split('h6cyberalerts');

            let value = email[0]

            let resultEnzoic = await fetch(
                mongodbURL + 'get-passwords-of-email-with-reports', {
                    method: "post",
                    body: JSON.stringify({ email : value }),
                    headers: {
                        'Content-Type': 'application/json'
                    }
            })
            resultEnzoic = await resultEnzoic.json();
            console.log(resultEnzoic)
            setReports(resultEnzoic)

            document.querySelector('#asset-title').innerText = value
            document.querySelector('#number-of-risks').innerText = resultEnzoic.length + ' records'

            document.querySelector('#remove-loader').classList.add('loaded')
        }
    }

    getReport()

    return(<>
            < div className={ classes }>
                <div id="page-container" className="checkout-page">
                    <div className=" radial4-bg lazyload-bg">
                        <header id="header">
                            <div className="wrapper flex-wrap flex-justify-between flex-align-center">
                                <div className="header-logo flex-wrap flex-align-center">
                                <a href="/" className="logo"><img loading="lazy" src={ logo } alt="" className="img-as-block" /></a>
                                </div>
                                <div className="btn-flex-holder flex-align-center with-login-link col-gap-30">
                                    <a href="/login">Login</a>

                                    <a href="/contact" className="btn blue-btn">
                                        <span>Get Help</span>
                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.5264 1.47359H2.98494C2.78953 1.47359 2.60213 1.39596 2.46395 1.25778C2.32578 1.11961 2.24815 0.932203 2.24815 0.736793C2.24815 0.541384 2.32578 0.353977 2.46395 0.215802C2.60213 0.0776263 2.78953 0 2.98494 0H13.2632C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793V11.0519C14 11.2473 13.9224 11.4347 13.7842 11.5729C13.646 11.7111 13.4586 11.7887 13.2632 11.7887C13.0678 11.7887 12.8804 11.7111 12.7422 11.5729C12.604 11.4347 12.5264 11.2473 12.5264 11.0519V1.47359Z" fill="white"/>
                                        <path d="M12.7416 0.215143C12.8799 0.0767931 13.0676 0 13.2632 0C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793C14 0.93245 13.9232 1.12009 13.7849 1.25844L1.25937 13.7839C1.19087 13.8524 1.10954 13.9068 1.02004 13.9438C0.930534 13.9809 0.834603 14 0.737724 14C0.640844 14 0.544914 13.9809 0.455409 13.9438C0.365904 13.9068 0.284578 13.8524 0.216074 13.7839C0.14757 13.7154 0.09323 13.6341 0.0561559 13.5446C0.0190818 13.4551 0 13.3592 0 13.2623C0 13.1654 0.0190818 13.0695 0.0561559 12.98C0.09323 12.8905 0.14757 12.8091 0.216074 12.7406L12.7416 0.215143Z" fill="white"/>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </header>

                        <section className="section checkout-section thank-you">
                            <div className="wrapper">
                            <div className="wrap position-relative" id="remove-loader">
                                
                            <div className="loader-spinner">
                                <span className="loader big"></span>
                            </div>
                            <div className="d-block-header">
                                
                                <h2 className="value" id="asset-title">Joe</h2>
                            </div>
                            <div className="flex-wrap flex-justify-between large-score-area">
                                    <div className="credit-last-scan">
                                    <div className="icon"><svg width="19" height="13" viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.3047 0.445312C18.5391 0.679688 18.5391 1.10938 18.3047 1.34375L7.67969 11.9688C7.44531 12.2031 7.01562 12.2031 6.78125 11.9688L1.15625 6.34375C0.921875 6.10938 0.921875 5.67969 1.15625 5.44531C1.39062 5.21094 1.82031 5.21094 2.05469 5.44531L7.25 10.6406L17.4062 0.445312C17.6406 0.210938 18.0703 0.210938 18.3047 0.445312Z" fill="black"></path></svg></div>
                                    <div className="label">We scanned the dark web and found <span className="numer-of-risks" id="number-of-risks">0 records</span> compromised passwords
                                    <div className="last-scan-text">Last scanned: 1:05PM - Feb 10, 2024</div></div></div>
                                    <div className="large-score"><div className="value" id="asset-score">10/10</div><div className="" id="asset-risk-type"><div className="risk-type high">High Risk</div></div></div></div><div className="risk-records-blocks">
                                    
                                    <div id="risk-record-blocks"></div>

                                    { reports && reports.slice(0, 11).map((item) => {
                                        return(<>
                                    <div className="risk-record-block">
                                    <div className="risk-record-name">
                                    <div className="icon"><svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.8685 0.130434C23.0105 0.130434 29.6512 6.77106 29.6512 14.913C29.6512 23.1128 23.0105 29.6957 14.8685 29.6957C6.66882 29.6957 0.0859375 23.1128 0.0859375 14.913C0.0859375 6.77106 6.66882 0.130434 14.8685 0.130434ZM14.8685 27.8478C15.7925 27.8478 17.1783 27.0394 18.391 24.5564C18.9684 23.4015 19.4881 22.0156 19.7768 20.4565H9.90251C10.1912 22.0156 10.7109 23.4015 11.2884 24.5564C12.501 27.0394 13.8869 27.8478 14.8685 27.8478ZM9.55605 18.6087H20.1233C20.2965 17.4538 20.412 16.2412 20.412 14.913C20.412 13.6427 20.2965 12.43 20.1233 11.2174H9.55605C9.38281 12.43 9.32507 13.6427 9.32507 14.913C9.32507 16.2412 9.38281 17.4538 9.55605 18.6087ZM19.7768 9.36956C19.4881 7.81046 18.9684 6.48234 18.391 5.32745C17.1783 2.84443 15.7925 1.97826 14.8685 1.97826C13.8869 1.97826 12.501 2.84443 11.2884 5.32745C10.7109 6.48234 10.1912 7.81046 9.90251 9.36956H19.7768ZM21.9711 11.2174C22.1444 12.43 22.2599 13.6427 22.2599 14.913C22.2599 16.2412 22.1444 17.4538 21.9711 18.6087H27.2259C27.5724 17.4538 27.8033 16.2412 27.8033 14.913C27.8033 13.6427 27.5724 12.43 27.2259 11.2174H21.9711ZM18.9684 2.6712C20.181 4.28804 21.1627 6.65557 21.6824 9.36956H26.5329C25.0316 6.25136 22.3176 3.82609 18.9684 2.6712ZM10.7109 2.6712C7.36175 3.82609 4.64776 6.25136 3.1464 9.36956H7.99694C8.51664 6.65557 9.4983 4.28804 10.7109 2.6712ZM1.93376 14.913C1.93376 16.2412 2.107 17.4538 2.45346 18.6087H7.70822C7.53499 17.4538 7.47724 16.2412 7.47724 14.913C7.47724 13.6427 7.53499 12.43 7.70822 11.2174H2.45346C2.107 12.43 1.93376 13.6427 1.93376 14.913ZM26.5329 20.4565H21.6824C21.1627 23.2283 20.181 25.538 18.9684 27.2126C22.3176 26.0577 25.0316 23.6325 26.5329 20.4565ZM7.99694 20.4565H3.1464C4.64776 23.6325 7.36175 26.0577 10.7109 27.2126C9.4983 25.538 8.51664 23.2283 7.99694 20.4565Z" fill="#3D3ABB"></path></svg></div>
                                    <div className="labels">
                                    <div className="title">{ item.password }</div>
                                    <div className="url">{ item.exposures.length } Records</div>
                                    </div>
                                    </div>
                                    <div className="risk-score-and-type" style={{ display: "none" }}>
                                    <div className="risk-score">10/10</div>
                                    <div className="risk-type">High Risk</div>
                                    </div>
                                    </div>
                                        </>)
                                    }) }
                                </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
    </>)
}


export default CheckoutReports