import { useEffect } from "react";
import IconArrowRight from "../assets/svg/icon-arrow-right";
import DashboardHeader from "./dbHeader";
import DashboardPageTitle from "./dbPageTitle";
import IconPlus from "../assets/svg/icon-plus";
import { addAssets, getMainAssets, getMainIdentities, openIdentityModal } from "../helper";



const AssetsPage = () =>{

    useEffect(() => {
        getMainAssets()
        getMainIdentities()
    }, [] );

    return (
        <>
        <DashboardHeader active="assets" />
        <div className="d-wrapper">
            <DashboardPageTitle title="Your Assets" show="add-asset" />
            <div className="d-block d-main-asset-block layout-full-width">
                <span id="asset-item-main-page"></span>
                <a href="/dashboard/assets/1" className="asset-block asset-add-item" id="add-asset-item-block" onClick={ addAssets }>
                    <div className="asset-block-main-details">
                        <div className="icon email">
                            <IconPlus />
                        </div>
                        <div className="title">
                            <div className="main-title">Add New Asset</div>
                            <div className="asset-details">
                                <div className="detail">You can add <span id="maximum-number-of-assets">12</span> more</div>
                            </div>
                        </div>
                        <div className="arrow">
                            <IconArrowRight />
                        </div>
                    </div>
                </a>
            </div>
            
            <DashboardPageTitle title="Identities" show="add-identities" extraclasses="hide-metalinks" />

            <div className="d-block d-main-identity-block layout-full-width">
                <span id="identity-item-main-page"></span>
                <a href="/dashboard/create-your-identity" className="identity-block identity-add-item" id="add-identity-item-block" onClick={ openIdentityModal }>
                    <div className="asset-block-main-details">
                        <div className="icon email">
                            <IconPlus />
                        </div>
                        <div className="title">
                            <div className="main-title">Add New Identity</div>
                            <div className="asset-details">
                                <div className="detail">You can add <span id="maximum-number-of-identities">5</span> more</div>
                            </div>
                        </div>
                        <div className="arrow">
                            <IconArrowRight />
                        </div>
                    </div>
                </a>
            </div>

        </div>
        </>
    );
}
export default AssetsPage