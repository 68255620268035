import { useEffect } from "react";
import BannerHomepage2 from "../sections/BannerHomepage2";
import Footer from "../sections/Footer";
import Nav from "../sections/Nav";
import BannerAbout from "../sections/BannerAbout";
import ImageWithTextAbout1 from "../sections/ImageWithTextAbout1";
import MissionAbout from "../sections/MissionAbout";
import IconBoxAbout from "../sections/IconBoxAbout";
import ImageWithTextAbout2 from "../sections/ImageWithTextAbout2";
import MeetTheTeamAbout from "../sections/MeetTheTeamAbout";

const About = ({ classes }) =>{

    useEffect(() => {
        document.querySelectorAll('.lazyload-bg').forEach( (div) => {
            div.classList.add('loaded')
        })
    }, [] );

    return (
        <>
            <div className={ classes }>
                <Nav />
                <BannerAbout />
            </div>
            <ImageWithTextAbout1 />
            <MissionAbout />
            <IconBoxAbout />
            <ImageWithTextAbout2 />
            <MeetTheTeamAbout />
            <BannerHomepage2 />
            <Footer />
        </>
    );
}
export default About