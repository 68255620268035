import { useEffect } from "react";
import IconArrowRight from "../assets/svg/icon-arrow-right";
import DashboardHeader from "./dbHeader";
import DashboardPageTitle from "./dbPageTitle";
import IconPlus from "../assets/svg/icon-plus";
import { getMainIdentities } from "../helper";



const IdentitiesMainPage = () =>{

    useEffect(() => {
        getMainIdentities()
    }, [] );

    return (
        <>
        <DashboardHeader active="assets" />
        <div className="d-wrapper">
            
            <DashboardPageTitle title="Identities" show="add-identities" extraclasses="hide-metalinks" />

            <div className="d-block d-main-identity-block layout-full-width position-relative" id="d-main-identity-block">
                <div className="loader-spinner">
                    <span className="loader big"></span>
                </div>
                <span id="identity-item-main-page"></span>
                <a href="/dashboard/create-your-identity" className="identity-block identity-add-item" id="add-identity-item-block">
                    <div className="asset-block-main-details">
                        <div className="icon email">
                            <IconPlus />
                        </div>
                        <div className="title">
                            <div className="main-title">Add New Identity</div>
                            <div className="asset-details">
                                <div className="detail">You can add <span id="maximum-number-of-identities">5</span> more</div>
                            </div>
                        </div>
                        <div className="arrow">
                            <IconArrowRight />
                        </div>
                    </div>
                </a>
            </div>

        </div>
        </>
    );
}
export default IdentitiesMainPage