import Slider from 'react-slick'
import theBoxIcon1 from '../assets/images/prevent-icon-1.png'
import theBoxIcon2 from '../assets/images/prevent-icon-2.png'
import theBoxIcon3 from '../assets/images/prevent-icon-3.png'
import theBoxIcon4 from '../assets/images/prevent-icon-4.png'
import theBoxIcon5 from '../assets/images/prevent-icon-5.png'
import theBoxIcon6 from '../assets/images/prevent-icon-6.png'
import ButtonCheckout from './ButtonCheckout'
import ButtonGetInTouch from './ButtonGetInTouch'

const SliderIconBoxes = () => {
    var settings = {
        auto: false,
        infinite: true,
        speed: 300,
        arrows:false,
        slidesToShow: 1,
        variableWidth: true,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 1120,
                settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                variableWidth: false,
                }
            }
        ]
    };

    return (
        <>
            <section className="the-slider-icon-boxes section">
                <div className="page-wrapper med-padding-section ">
                    <div className="page-content-block">
                        <div className="intro">FEATURES</div>
                        <h2>Protecting Your Digital Footprint</h2>
                        <p>In an increasingly interconnected world, protecting your sensitive information from falling into the wrong hands is essential. Our comprehensive Dark Web Monitoring service empowers you to stay one step ahead of cybercriminals and potential threats.</p>
                    </div>
                </div>
                <div className="faded-sliders">
                <div className="page-wrapper">
                    <Slider {...settings} className="the-slider-icons">
                        <div className="the-box">
                            <div className="icon"><img loading="lazy" src={ theBoxIcon1 } alt="" className="img-as-block" /></div>
                            <h3>Real-Time Alerts</h3>
                            <p>Users receive instant notifications if their personal data is found on the dark web. </p>
                        </div>
                        <div className="the-box">
                            <div className="icon"><img loading="lazy" src={ theBoxIcon2 } alt="" className="img-as-block" /></div>
                            <h3>Comprehensive Database Search</h3>
                            <p>Using multiple API integrations, the tool searches extensively across various databases to ensure thorough coverage.</p>
                        </div>
                        <div className="the-box">
                            <div className="icon"><img loading="lazy" src={ theBoxIcon3 } alt="" className="img-as-block" /></div>
                            <h3>Personal Dashboard</h3>
                            <p>A user-friendly interface where consumers can check their current risk status, see potential threats, and get advice on how to protect themselves.</p>
                        </div>
                        <div className="the-box">
                            <div className="icon"><img loading="lazy" src={ theBoxIcon4 } alt="" className="img-as-block" /></div>
                            <h3>Monthly Reports</h3>
                            <p>Regular insights into personal data exposure trends, potential threats, and security recommendations.</p>
                        </div>
                        <div className="the-box">
                            <div className="icon"><img loading="lazy" src={ theBoxIcon5 } alt="" className="img-as-block" /></div>
                            <h3>Multi-device Support</h3>
                            <p>Ability to get alerts on all devices – desktop, mobile, and tablet.</p>
                        </div>
                        <div className="the-box">
                            <div className="icon"><img loading="lazy" src={ theBoxIcon6 } alt="" className="img-as-block" /></div>
                            <h3>User Education</h3>
                            <p>A built-in resource center that educates users about the latest Cyber Security threats, preventive measures, and safe online practices.  </p>
                        </div>
                    </Slider>
                </div>
                </div>
                <div className="page-wrapper med-padding-section">
                    <div className="btn-flex-holder">
                        <ButtonCheckout />
                        <ButtonGetInTouch />
                    </div>
                </div>
            </section>
        </>
    );
}
export default SliderIconBoxes