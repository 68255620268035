
import logo6 from './images/logo-6.png';
import logo7 from './images/logo-7.png';
import logo8 from './images/logo-8.png';
const Logo678 = () =>{
    return (
        <>
            <img src={ logo6 } className="img-as-block" alt="" />
            <img src={ logo7 } className="img-as-block" alt="" />
            <img src={ logo8 } className="img-as-block" alt="" />
        </>
    );
}
export default Logo678