const IconImage = () =>{
    return (
        <>
            
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M2.50065 5.83333C2.50065 4.91286 3.24684 4.16667 4.16732 4.16667H13.334C13.7942 4.16667 14.1673 3.79357 14.1673 3.33333C14.1673 2.8731 13.7942 2.5 13.334 2.5H4.16732C2.32637 2.5 0.833984 3.99238 0.833984 5.83333V15C0.833984 16.8409 2.32637 18.3333 4.16732 18.3333H15.834C17.6749 18.3333 19.1673 16.8409 19.1673 15V8.33333C19.1673 7.8731 18.7942 7.5 18.334 7.5C17.8737 7.5 17.5006 7.8731 17.5006 8.33333V12.2077L14.4538 8.39918C13.0445 6.63752 10.3289 6.75537 9.07745 8.63249L8.12776 10.057C6.99239 9.43387 5.55999 9.78186 4.84025 10.8714L2.50065 14.413V5.83333ZM13.1524 9.44033L17.5006 14.8757V15C17.5006 15.9205 16.7545 16.6667 15.834 16.6667H7.28151C6.61593 16.6667 6.21894 15.9249 6.58814 15.3711L10.4642 9.55699C11.0899 8.61843 12.4477 8.55951 13.1524 9.44033ZM5.20139 14.4466C4.72833 15.1562 4.67457 15.9686 4.92381 16.6667H4.58398C4.12375 16.6667 3.75065 16.2936 3.75065 15.8333V15.7949C3.75065 15.6316 3.79864 15.4719 3.88867 15.3356L6.23088 11.79C6.44319 11.4686 6.84155 11.3408 7.19269 11.4596L5.20139 14.4466Z" fill="#3D3ABB"/>
<path d="M4.31982 7.3319C4.72072 7.46553 5.03531 7.78012 5.16894 8.18102C5.30495 8.58903 5.88206 8.58903 6.01807 8.18102C6.1517 7.78012 6.46629 7.46553 6.86719 7.3319C7.2752 7.19589 7.2752 6.61878 6.86719 6.48277C6.46629 6.34914 6.1517 6.03455 6.01807 5.63365C5.88206 5.22564 5.30495 5.22564 5.16894 5.63365C5.03531 6.03455 4.72072 6.34914 4.31982 6.48277C3.91181 6.61878 3.91181 7.19589 4.31982 7.3319Z" fill="#3D3ABB"/>
<path d="M17.5006 3.33333C17.5006 2.8731 17.1276 2.5 16.6673 2.5C16.2071 2.5 15.834 2.8731 15.834 3.33333V4.16667H15.0006C14.5404 4.16667 14.1673 4.53976 14.1673 5C14.1673 5.46024 14.5404 5.83333 15.0006 5.83333H15.834V6.66667C15.834 7.1269 16.2071 7.5 16.6673 7.5C17.1276 7.5 17.5006 7.1269 17.5006 6.66667V5.83333H18.334C18.7942 5.83333 19.1673 5.46024 19.1673 5C19.1673 4.53976 18.7942 4.16667 18.334 4.16667H17.5006V3.33333Z" fill="#3D3ABB"/>
</svg>

        </>
    );
}
export default IconImage