
import { useEffect } from 'react';
import theBoxIcon1 from '../assets/images/the-box-icon-1.png'
import theBoxIcon2 from '../assets/images/the-box-icon-2.png'
import theBoxIcon3 from '../assets/images/the-box-icon-3.png'
import ButtonCheckout from './ButtonCheckout';
import ButtonGetInTouch from './ButtonGetInTouch';

const ThreeColumnsServicePage = () => {

    useEffect(() => {
        document.querySelectorAll('.lazyload-bg').forEach( (div) => {
            div.classList.add('loaded')
        })
    }, [] );

    return (
        <>
            <section className="three-column-icon-boxes radial2-bg lazyload-bg section">
                <div className="page-wrapper med-padding-section ">
                    <div className="small-page-content-block">
                        <div className="page-content-block text-center">
                            <div className="intro">HOW IT CAN HELP ME</div>
                            <h2>Elevate the Value of Your Offering by Elevating the Security of Your Users</h2>
                            <p>Be Instantly Alerted to Compromised PII including Credit Cards, Social Security Numbers, Cryptocurrency Wallets, and Much More</p>
                        </div>
                    </div>
                    <div className="flex-wrap the-boxes">
                        <div className="the-box">
                            <div className="icon"><img loading="lazy" src={ theBoxIcon1 } alt="" className="img-as-block" /></div>
                            <h3>Customizable for Unique Requirements</h3>
                            <p>Continuously track the PII that’s relevant for your workflows by subscribing to other data elements outside Enzoic’s default PII list.</p>
                        </div>
                        <div className="the-box">
                            <div className="icon"><img loading="lazy" src={ theBoxIcon2 } alt="" className="img-as-block" /></div>
                            <h3>Exposure Notifications Without Delay</h3>
                            <p>Enzoic gathers and provides data in real-time, without the delays and resources required for you to periodically query an unstructured database.</p>
                        </div>
                        <div className="the-box">
                            <div className="icon"><img loading="lazy" src={ theBoxIcon3 } alt="" className="img-as-block" /></div>
                            <h3>Proactive Prevention</h3>
                            <p>Prompt notification allows for swift remediation, enabling users to safeguard their accounts and data from identity theft, fraud, and unauthorized access</p>
                        </div>
                    </div>
                    <div className="btn-flex-holder flex-justify-center">
                        <ButtonCheckout />
                        <ButtonGetInTouch />
                    </div>
                </div>
            </section>
        </>
    );
}
export default ThreeColumnsServicePage