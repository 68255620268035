import BannerCriticalCVE from "../sections/BannerCriticalCVE";
import BannerHomepage2 from "../sections/BannerHomepage2";
import FAQBlock from "../sections/FAQBlock";
import Footer from "../sections/Footer";
import ImageWithTextServicePage from "../sections/ImageWithTextServicePage";
import SliderIconBoxes from "../sections/SliderIconBoxes";
import ThreeColumnsServicePage from "../sections/ThreeColumnsServicePage";

const PageCriticalCVE = () =>{
    return (
        <>
            <BannerCriticalCVE />
            <ImageWithTextServicePage />
            <ThreeColumnsServicePage />
            <SliderIconBoxes />
            <FAQBlock classes='section flexbox flexbox-spacing-2 faq-blocks radial3-bg lazyload-bg' />
            <BannerHomepage2 />
            <Footer />
        </>
    );
}
export default PageCriticalCVE