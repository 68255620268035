const MissionAbout = () => {
    return (
        <>
            <section className="flexbox section small-padding-section">
                <div className="page-wrapper">
                    <div className="custom-block-style-2 bg-1 box-style-1">
                        <div className="styled-content">
                            <h2>Our mission</h2>
                            <p className="big">At CyberAlerts, your security is our priority.</p>
                            <p>Our mission is clear: to empower you to reclaim control over your digital presence. The online world offers boundless opportunities, but these opportunities come with risks. At CyberAlerts, we are driven by a singular purpose - to shield you from these risks and provide you with the tools you need to guard your digital identity and assets.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default MissionAbout