
const IconFilters = () =>{
    return (
        <>
            <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 15.75C0 15.4375 0.273438 15.125 0.625 15.125H3.16406C3.47656 13.7188 4.72656 12.625 6.25 12.625C7.73438 12.625 8.98438 13.7188 9.29688 15.125H19.375C19.6875 15.125 20 15.4375 20 15.75C20 16.1016 19.6875 16.375 19.375 16.375H9.29688C8.98438 17.8203 7.73438 18.875 6.25 18.875C4.72656 18.875 3.47656 17.8203 3.16406 16.375H0.625C0.273438 16.375 0 16.1016 0 15.75ZM8.125 15.75C8.125 14.7344 7.26562 13.875 6.25 13.875C5.19531 13.875 4.375 14.7344 4.375 15.75C4.375 16.8047 5.19531 17.625 6.25 17.625C7.26562 17.625 8.125 16.8047 8.125 15.75ZM13.75 6.375C15.2344 6.375 16.4844 7.46875 16.7969 8.875H19.375C19.6875 8.875 20 9.1875 20 9.5C20 9.85156 19.6875 10.125 19.375 10.125H16.7969C16.4844 11.5703 15.2344 12.625 13.75 12.625C12.2266 12.625 10.9766 11.5703 10.6641 10.125H0.625C0.273438 10.125 0 9.85156 0 9.5C0 9.1875 0.273438 8.875 0.625 8.875H10.6641C10.9766 7.46875 12.2266 6.375 13.75 6.375ZM15.625 9.5C15.625 8.48438 14.7656 7.625 13.75 7.625C12.6953 7.625 11.875 8.48438 11.875 9.5C11.875 10.5547 12.6953 11.375 13.75 11.375C14.7656 11.375 15.625 10.5547 15.625 9.5ZM19.375 2.625C19.6875 2.625 20 2.9375 20 3.25C20 3.60156 19.6875 3.875 19.375 3.875H10.5469C10.2344 5.32031 8.98438 6.375 7.5 6.375C5.97656 6.375 4.72656 5.32031 4.41406 3.875H0.625C0.273438 3.875 0 3.60156 0 3.25C0 2.9375 0.273438 2.625 0.625 2.625H4.41406C4.72656 1.21875 5.97656 0.125 7.5 0.125C8.98438 0.125 10.2344 1.21875 10.5469 2.625H19.375ZM5.625 3.25C5.625 4.30469 6.44531 5.125 7.5 5.125C8.51562 5.125 9.375 4.30469 9.375 3.25C9.375 2.23438 8.51562 1.375 7.5 1.375C6.44531 1.375 5.625 2.23438 5.625 3.25Z" fill="#241FFF"/>
            </svg>

        </>
    );
}
export default IconFilters