import { useState } from "react";
import DCustomHeader from "../globals/DCustomHeader";
import { Form } from "react-router-dom";
import IconImage from "../assets/svg/icon-image";
import IconUserAlt from "../assets/svg/icon-user-alt";
import IconCamera from "../assets/svg/icon-camera";
import IconEmail from "../assets/svg/icon-email";
import IconCheck from "../assets/svg/icon-check";
import IconArrowRight from "../assets/svg/icon-arrow-right";
import IconPlus from "../assets/svg/icon-plus";
import { addAssets, fetchData, mongodbURL } from "../helper";
import IconPhone from "../assets/svg/icon-phone";
import IconLock from "../assets/svg/icon-lock";

const CreateYourIdentityPage = () =>{


    const [assetEmails, setAssetEmails] = useState("");
    const [assetPasswords, setAssetPasswords] = useState("");
    const [assetPhones, setAssetPhones] = useState("");
    const [assetPerson, setAssetPerson] = useState("");

    
    const [image, setImage] = useState('')
    const [firstLoad, setFirstLoad] = useState("");


    let emailAssets = [], phoneAssets = [], passwordAssets = [], personAssets = []
    const getAllAssets = async () => {
        let email = fetchData('userName')
        let assetType = 'all'
        let result = await fetch(
        mongodbURL + 'getuser-protection-assets', {
            method: "post",
            body: JSON.stringify({ email, assetType }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        result = await result.json();
        if( result ){
            for (let i = 0; i < result.data.length; i++) {
                switch( result.data[i].type ){
                    case 'email':
                        emailAssets.push(result.data[i])
                    break;
                    case 'password':
                        passwordAssets.push(result.data[i])
                    break;
                    case 'phone':
                        phoneAssets.push(result.data[i])
                    break;
                    case 'person':
                        personAssets.push(result.data[i])
                    break;
                    default:
                }
            }

            setAssetEmails(emailAssets)
            setAssetPasswords(passwordAssets)
            setAssetPhones(phoneAssets)
            setAssetPerson(personAssets)

            document.querySelectorAll('.form-blocks-for-assets').forEach(el => el.classList.add('loaded') )

        }
    }
    
    if( firstLoad.length === 0){
        getAllAssets()
        setFirstLoad(1)
    }

    const formSubmitAddIdentity = async() =>{

        document.querySelector('#modal-loader-title').innerText = 'Loading...'
        document.querySelector('#modal-pop-bg-loader').classList.toggle('fadeIn')
        document.querySelector('#modal-loader').classList.toggle('fadeIn')

        let checkboxIDs = [];

        document.querySelectorAll('.input-checkbox input').forEach(checkbox => {
            if( checkbox.checked ){
                checkboxIDs.push(checkbox.value)
            }
        })

        let identitytitle = document.querySelector('#identity-name').value
        let email = fetchData('userName')

        const formdatax = new FormData();
        if( document.querySelector('#file').files.length ){
            formdatax.append("image", document.querySelector('#file').files[0], URL.createObjectURL(document.querySelector('#file').files[0]) );
        }
        formdatax.append("checkboxIDs", checkboxIDs )
        formdatax.append("identitytitle", identitytitle )
        formdatax.append("email", email )

        const requestOptions = {
            method: "POST",
            body: formdatax,
            redirect: "follow"
        };

        console.log(formdatax)

        await fetch( mongodbURL + 'add-main-identity', requestOptions)
        .then((response) => response.text())
        .then((result) =>{
            result = JSON.parse(result)
            setTimeout( function(){
                document.querySelector('#modal-loader-title').innerText = 'Identity Updated'
                document.querySelector('#modal-loader').classList.add('success')
                document.querySelector('#modal-loader .position-relative').classList.add('loaded')

                setTimeout( () => {
                    document.querySelector('#modal-pop-bg-loader').classList.toggle('fadeIn')
                    document.querySelector('#modal-loader').classList.toggle('fadeIn')
                }, 2000)
            }, 1000)
        });
    }

    const checkboxOnChange = (e) => {
        let count = 0;
        document.querySelectorAll('.input-checkbox input').forEach(checkbox => {
            if( checkbox.checked ){
                count++;
            }
        })

        if( count >= 10 ){
            document.querySelectorAll('.input-checkbox input').forEach(checkbox => {
                if( !checkbox.checked ){
                    checkbox.disabled = 'disabled'
                }
            });
        } else {
            document.querySelectorAll('.input-checkbox input').forEach(checkbox => {
                if( !checkbox.checked ){
                    checkbox.removeAttribute('disabled')
                }
            });
        }

        document.querySelector('#total-selected-assets').innerText = count

    }
    const handleChangeFile = (e) => {
        setImage(e.target.files[0])

        if( document.querySelector('#file').files[0] ){
            document.querySelector('#identity-image-holder').src = URL.createObjectURL(document.querySelector('#file').files[0])
            document.querySelector('#identity-image-holder').classList.remove('hide-element')
            document.querySelector('#profile-photo-holder').classList.add('selected-photo')
        } else {
            document.querySelector('#identity-image-holder').classList.add('hide-element')
            document.querySelector('#profile-photo-holder').classList.remove('selected-photo')
        }
        console.log(image)
    }

    const openFileInput = (e) => {
        e.preventDefault();
        document.querySelector('#file').click()
    }

    return (
        <>
            <DCustomHeader />
            <div className="custom-page-wrapper">
                <div className="custom-page-wrap-med add-asset-container">
                    <div id="create-your-identity-trigger" onClick={ getAllAssets }></div>
                    <h3 className="text-center">Create your Identity</h3>
                    <Form onSubmit={ formSubmitAddIdentity }>
                        <div className="form-field">
                            <div className="form-field-label">Name your Identity</div>
                            <div className="form-field-input">
                                <input type="text" name="identity-name" id="identity-name" className="input-text" required />
                            </div>
                        </div>
                        <div className="form-field next-section">
                            <div className="profile-photo position-relative">
                                <div className="form-field-wrap">
                                    <div className="form-field-label">
                                        Profile Photo
                                        <div className="desc">Having a photo can help you quickly locate an identity</div>
                                    </div>
                                </div>
                                <div id="profile-photo-holder" className="profile-photo-holder" onClick={ openFileInput }>
                                    <span className="account-icon">
                                        <img src="" alt="" id="identity-image-holder" className="hide-element" />
                                        <IconUserAlt />
                                    </span>
                                    <span className="icon-camera"><IconCamera /></span>
                                </div>
                                <div className="form-field-wrap">
                                    <a href="/" className="button-with-icon" onClick={ openFileInput }>
                                        <span className="icon"><IconImage /></span>
                                        <span className="text">Edit Photo</span>
                                    </a>
                                    <input type="file" id="file" name="file" onChange={ handleChangeFile } />
                                </div>
                            </div>
                        </div>
                        <div className="form-field">
                            <strong>Select your assets <span className="text-blue"><span id="total-selected-assets">0</span> of 10 Selected</span></strong>
                        </div>


                        <div className="form-field form-field-section">
                            <div className="form-field-label">Email Assets</div>
                            <div className="form-blocks-for-assets position-relative">
                                <div className="loader-spinner">
                                    <span className="loader big"></span>
                                </div>
                                { assetEmails && assetEmails.map((item) => {
                                    return(
                                    <label htmlFor={ 'checkbox-' + item._id }  className="form-blocks-for-asset" key={ 'checkbox-' + item._id }>
                                        <div className="asset-email flex-wrap flex-align-center">
                                            <div className="asset-icon">
                                                <IconEmail />
                                            </div>
                                            <div className="asset-detail">
                                                <div className="label">{ item.value }</div>
                                                <div className="info">{ item.nickname }</div>
                                            </div>
                                            <div className="input-checkbox">
                                                <input type="checkbox" value={ item._id } id={ 'checkbox-' + item._id } onChange={ checkboxOnChange } />
                                                <span className="input-checkbox-span">
                                                    <IconCheck />
                                                </span>
                                            </div>
                                        </div>
                                    </label>
                                    )
                                }) }
                            </div>
                        </div>

                        
                        <div className="form-field form-field-section">
                            <div className="form-field-label">Phone Numbers</div>
                            <div className="form-blocks-for-assets position-relative">
                                <div className="loader-spinner">
                                    <span className="loader big"></span>
                                </div>
                                { assetPhones && assetPhones.map((item) => {
                                    return(
                                    <label htmlFor={ 'checkbox-' + item._id }  className="form-blocks-for-asset" key={ 'checkbox-' + item._id }>
                                        <div className="asset-email flex-wrap flex-align-center">
                                            <div className="asset-icon">
                                                <IconPhone />
                                            </div>
                                            <div className="asset-detail">
                                                <div className="label">{ item.value }</div>
                                                <div className="info">{ item.nickname }</div>
                                            </div>
                                            <div className="input-checkbox">
                                                <input type="checkbox" value={ item._id } id={ 'checkbox-' + item._id } onChange={ checkboxOnChange } />
                                                <span className="input-checkbox-span">
                                                    <IconCheck />
                                                </span>
                                            </div>
                                        </div>
                                    </label>
                                    )
                                }) }
                            </div>
                        </div>

                        
                        <div className="form-field form-field-section">
                            <div className="form-field-label">People</div>
                            <div className="form-blocks-for-assets position-relative">
                                <div className="loader-spinner">
                                    <span className="loader big"></span>
                                </div>
                                { assetPerson && assetPerson.map((item) => {
                                    return(
                                    <label htmlFor={ 'checkbox-' + item._id }  className="form-blocks-for-asset" key={ 'checkbox-' + item._id }>
                                        <div className="asset-email flex-wrap flex-align-center">
                                            <div className="asset-icon">
                                                <IconUserAlt />
                                            </div>
                                            <div className="asset-detail">
                                                <div className="label">{ item.value }</div>
                                                <div className="info">{ item.nickname }</div>
                                            </div>
                                            <div className="input-checkbox">
                                                <input type="checkbox" value={ item._id } id={ 'checkbox-' + item._id } onChange={ checkboxOnChange } />
                                                <span className="input-checkbox-span">
                                                    <IconCheck />
                                                </span>
                                            </div>
                                        </div>
                                    </label>
                                    )
                                }) }
                            </div>
                        </div>

                        <div className="form-field form-field-section last">
                            <div className="form-field-label">Passwords</div>
                            <div className="form-blocks-for-assets position-relative">
                                <div className="loader-spinner">
                                    <span className="loader big"></span>
                                </div>
                                { assetPasswords && assetPasswords.map((item) => {
                                    return(
                                    <label htmlFor={ 'checkbox-' + item._id }  className="form-blocks-for-asset" key={ 'checkbox-' + item._id }>
                                        <div className="asset-email flex-wrap flex-align-center">
                                            <div className="asset-icon">
                                                <IconLock />
                                            </div>
                                            <div className="asset-detail">
                                                <div className="label">{ item.value }</div>
                                                <div className="info">{ item.nickname }</div>
                                            </div>
                                            <div className="input-checkbox">
                                                <input type="checkbox" value={ item._id } id={ 'checkbox-' + item._id } onChange={ checkboxOnChange } />
                                                <span className="input-checkbox-span">
                                                    <IconCheck />
                                                </span>
                                            </div>
                                        </div>
                                    </label>
                                    )
                                }) }
                            </div>
                        </div>

                        
                        <div className="form-field form-field-section">
                            <a href="/" className="add-new-asset-trigger form-blocks-for-asset" onClick={ addAssets }>
                                <div className="asset-email flex-wrap flex-align-center">
                                    <div className="asset-icon">
                                        <IconPlus />
                                    </div>
                                    <div className="asset-detail">
                                        <div className="label">Create New Asset</div>
                                    </div>
                                    <div className="input-checkbox">
                                        <span className="input-checkbox-span">
                                            <IconArrowRight />
                                        </span>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className="form-field form-field-section">
                            <div className="form-block flex-wrap flex-wrap-block">
                                <div className="form-field checkbox-field">
                                    <input type="checkbox" id="checkbox1" name="checkbox1" value="agree" required />
                                    <label htmlFor="checkbox1">
                                        <span></span>
                                        <p>By checking this box and clicking ‘next’ you agree to be bound by theTerms of Service Agreement, Privacy Policy, and to receive important notices and other communications electronically.</p>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="form-field form-field-section">
                            <div className="form-block flex-wrap flex-wrap-block">
                                <div className="form-field submit-field">
                                <button type="submit" className="btn blue-btn btn-next" id="checkout-next-step" data-step="1">
                                    <span id="continue-label" className="label">Add Identity</span></button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </>
    );
}
export default CreateYourIdentityPage