import { useEffect } from "react";

const FAQBlock = ({ classes }) =>{
    const faqClick = (e) =>{
        e.target.closest('.faq-block').classList.toggle('active')
        console.log(e);
    }

    useEffect(() => {
        document.querySelectorAll('.lazyload-bg').forEach( (div) => {
            div.classList.add('loaded')
        })
    }, [] );


    return (
        <>
        <section id="" className={ classes }>
            <div className="wrapper">
                <div className="content-block text-center small-wrapper-auto">
                    <div className="intro">Frequently Asked Questions</div>
                    <h2 className="small-wrapper-auto">Everything you need to know about the product and billing.</h2>
                </div>
                <div className="faq-accord">
                    <div className="faq-block">
                        <div className="faq-title" onClick={ faqClick }>
                            <div className="faq-title-text med-text">How does Dark Web monitoring work?</div>
                            <div className="faq-icon">
                                <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M19.0622 0.84695C19.3425 1.12728 19.5 1.50728 19.5 1.90348C19.5 2.29968 19.3425 2.67968 19.0622 2.96001L11.0773 10.9338C10.7966 11.2138 10.4161 11.3711 10.0193 11.3711C9.62258 11.3711 9.24206 11.2138 8.96134 10.9338L0.976511 2.96001C0.829416 2.82314 0.711436 2.65808 0.629608 2.47468C0.547779 2.29129 0.50378 2.09331 0.500233 1.89256C0.496686 1.69182 0.533665 1.49241 0.608964 1.30625C0.684263 1.12008 0.796339 0.95097 0.938506 0.808998C1.08067 0.667026 1.25002 0.555104 1.43644 0.479908C1.62286 0.404713 1.82254 0.367784 2.02357 0.371326C2.22459 0.374868 2.42284 0.418808 2.60649 0.500524C2.79014 0.58224 2.95543 0.700058 3.09249 0.84695L10.0193 7.76425L16.9462 0.84695C17.2269 0.566968 17.6074 0.409704 18.0042 0.409704C18.4009 0.409704 18.7814 0.566968 19.0622 0.84695Z" fill="black"/>
                                </svg>
                            </div>
                        </div>
                        <div className="faq-content">
                            <p>Identity theft protection covers monitoring of your personal data, financial alerts, credit monitoring, and expert fraud remediation.
                            </p>
                        </div>
                    </div>
                    <div className="faq-block">
                        <div className="faq-title" onClick={ faqClick }>
                            <div className="faq-title-text med-text">Why should I choose CyberAlerts over other Cyber Security solutions?</div>
                            <div className="faq-icon">
                                <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M19.0622 0.84695C19.3425 1.12728 19.5 1.50728 19.5 1.90348C19.5 2.29968 19.3425 2.67968 19.0622 2.96001L11.0773 10.9338C10.7966 11.2138 10.4161 11.3711 10.0193 11.3711C9.62258 11.3711 9.24206 11.2138 8.96134 10.9338L0.976511 2.96001C0.829416 2.82314 0.711436 2.65808 0.629608 2.47468C0.547779 2.29129 0.50378 2.09331 0.500233 1.89256C0.496686 1.69182 0.533665 1.49241 0.608964 1.30625C0.684263 1.12008 0.796339 0.95097 0.938506 0.808998C1.08067 0.667026 1.25002 0.555104 1.43644 0.479908C1.62286 0.404713 1.82254 0.367784 2.02357 0.371326C2.22459 0.374868 2.42284 0.418808 2.60649 0.500524C2.79014 0.58224 2.95543 0.700058 3.09249 0.84695L10.0193 7.76425L16.9462 0.84695C17.2269 0.566968 17.6074 0.409704 18.0042 0.409704C18.4009 0.409704 18.7814 0.566968 19.0622 0.84695Z" fill="black"/>
                                </svg>
                            </div>
                        </div>
                        <div className="faq-content">
                            <p>CyberAlerts provides comprehensive, real-time protection, multi-device support, and top-tier customer service for you and your family.
                            </p>
                        </div>
                    </div>
                    <div className="faq-block">
                        <div className="faq-title" onClick={ faqClick }>
                            <div className="faq-title-text med-text">How often does CyberAlerts provide vulnerability scanning and assessment reports?</div>
                            <div className="faq-icon">
                                <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M19.0622 0.84695C19.3425 1.12728 19.5 1.50728 19.5 1.90348C19.5 2.29968 19.3425 2.67968 19.0622 2.96001L11.0773 10.9338C10.7966 11.2138 10.4161 11.3711 10.0193 11.3711C9.62258 11.3711 9.24206 11.2138 8.96134 10.9338L0.976511 2.96001C0.829416 2.82314 0.711436 2.65808 0.629608 2.47468C0.547779 2.29129 0.50378 2.09331 0.500233 1.89256C0.496686 1.69182 0.533665 1.49241 0.608964 1.30625C0.684263 1.12008 0.796339 0.95097 0.938506 0.808998C1.08067 0.667026 1.25002 0.555104 1.43644 0.479908C1.62286 0.404713 1.82254 0.367784 2.02357 0.371326C2.22459 0.374868 2.42284 0.418808 2.60649 0.500524C2.79014 0.58224 2.95543 0.700058 3.09249 0.84695L10.0193 7.76425L16.9462 0.84695C17.2269 0.566968 17.6074 0.409704 18.0042 0.409704C18.4009 0.409704 18.7814 0.566968 19.0622 0.84695Z" fill="black"/>
                                </svg>
                            </div>
                        </div>
                        <div className="faq-content">
                            <p>We offer continuous vulnerability scanning and provide monthly reports to keep you informed.
                            </p>
                        </div>
                    </div>
                    <div className="faq-block">
                        <div className="faq-title" onClick={ faqClick }>
                            <div className="faq-title-text med-text">What is CyberAlerts?</div>
                            <div className="faq-icon">
                                <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M19.0622 0.84695C19.3425 1.12728 19.5 1.50728 19.5 1.90348C19.5 2.29968 19.3425 2.67968 19.0622 2.96001L11.0773 10.9338C10.7966 11.2138 10.4161 11.3711 10.0193 11.3711C9.62258 11.3711 9.24206 11.2138 8.96134 10.9338L0.976511 2.96001C0.829416 2.82314 0.711436 2.65808 0.629608 2.47468C0.547779 2.29129 0.50378 2.09331 0.500233 1.89256C0.496686 1.69182 0.533665 1.49241 0.608964 1.30625C0.684263 1.12008 0.796339 0.95097 0.938506 0.808998C1.08067 0.667026 1.25002 0.555104 1.43644 0.479908C1.62286 0.404713 1.82254 0.367784 2.02357 0.371326C2.22459 0.374868 2.42284 0.418808 2.60649 0.500524C2.79014 0.58224 2.95543 0.700058 3.09249 0.84695L10.0193 7.76425L16.9462 0.84695C17.2269 0.566968 17.6074 0.409704 18.0042 0.409704C18.4009 0.409704 18.7814 0.566968 19.0622 0.84695Z" fill="black"/>
                                </svg>
                            </div>
                        </div>
                        <div className="faq-content">
                            <p>CyberAlerts is a comprehensive Cyber Security solution that protects individuals and families from cyber threats, identity theft, and data breaches.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
}
export default FAQBlock