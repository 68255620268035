const IconVerified = () =>{
    return (
        <>
        
<svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_1059_4444)">
<path d="M29.2089 6.89258C29.5651 7.75394 30.2487 8.43861 31.1096 8.79606L34.128 10.0464C34.9895 10.4032 35.6739 11.0876 36.0307 11.949C36.3875 12.8104 36.3875 13.7783 36.0307 14.6397L34.7813 17.6561C34.4243 18.5179 34.4238 19.4867 34.7824 20.3481L36.0297 23.3636C36.2065 23.7902 36.2976 24.2475 36.2977 24.7094C36.2978 25.1713 36.2068 25.6286 36.0301 26.0553C35.8533 26.4821 35.5942 26.8698 35.2676 27.1963C34.9409 27.5228 34.5532 27.7818 34.1264 27.9584L31.1101 29.2078C30.2487 29.564 29.564 30.2476 29.2066 31.1084L27.9563 34.1269C27.5995 34.9884 26.9151 35.6728 26.0537 36.0296C25.1922 36.3864 24.2243 36.3864 23.3629 36.0296L20.3466 34.7802C19.4851 34.4242 18.5176 34.425 17.6567 34.7822L14.6382 36.0307C13.7772 36.3867 12.8102 36.3864 11.9495 36.0299C11.0887 35.6733 10.4047 34.9897 10.0477 34.1292L8.797 31.1099C8.44084 30.2485 7.7572 29.5638 6.89638 29.2064L3.87789 27.9561C3.01684 27.5994 2.33264 26.9154 1.97569 26.0545C1.61875 25.1936 1.61826 24.2261 1.97435 23.3649L3.22375 20.3485C3.57969 19.4871 3.57896 18.5195 3.22173 17.6586L1.97412 14.638C1.79723 14.2113 1.70615 13.754 1.70608 13.2921C1.706 12.8303 1.79694 12.3729 1.97369 11.9462C2.15044 11.5195 2.40954 11.1318 2.73618 10.8052C3.06282 10.4787 3.4506 10.2197 3.87737 10.0431L6.8937 8.79371C7.7543 8.43787 8.43856 7.75509 8.79628 6.89526L10.0466 3.87677C10.4034 3.01534 11.0878 2.33094 11.9492 1.97413C12.8106 1.61731 13.7785 1.61731 14.64 1.97413L17.6563 3.22353C18.5177 3.57947 19.4853 3.57874 20.3462 3.22151L23.3659 1.97606C24.2273 1.61945 25.1949 1.61952 26.0562 1.97627C26.9175 2.33301 27.6018 3.01722 27.9586 3.87842L29.2093 6.89781L29.2089 6.89258Z" fill="#1671D9"/>
<path fillRule="evenodd" clipRule="evenodd" d="M26.3436 14.0861C26.5941 13.6926 26.678 13.2158 26.5769 12.7604C26.4758 12.305 26.198 11.9085 25.8045 11.658C25.411 11.4075 24.9342 11.3235 24.4788 11.4246C24.0235 11.5257 23.6269 11.8036 23.3764 12.197L16.4928 23.0134L13.3428 19.0759C13.0516 18.7117 12.6276 18.478 12.1641 18.4264C11.7006 18.3747 11.2356 18.5093 10.8713 18.8006C10.5071 19.0918 10.2734 19.5158 10.2218 19.9793C10.1701 20.4427 10.3047 20.9078 10.5959 21.272L15.2834 27.1314C15.4581 27.3501 15.6825 27.5238 15.9379 27.6383C16.1933 27.7527 16.4723 27.8045 16.7518 27.7894C17.0312 27.7743 17.303 27.6926 17.5445 27.5513C17.7861 27.4099 17.9904 27.2129 18.1405 26.9767L26.3436 14.0861V14.0861Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_1059_4444">
<rect width="37.5" height="37.5" fill="white" transform="translate(0.25 0.25)"/>
</clipPath>
</defs>
</svg>

        </>
    );
}
export default IconVerified