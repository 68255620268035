import { useEffect } from "react";
import IconAlerts from "../assets/svg/icon-alerts";
import { customSelectWrap, recentAlertsFilter } from "../helper";


const DashboardSectionAlerts = ({ title }) =>{
    useEffect(() => {
        recentAlertsFilter();
    });

    return (
        <>
            <div className="d-block d-alerts-block layout-2">
                <h2>Alerts</h2>
                <div className="wrap">
                    <div className="flex-wrap flex-justify-between flex-align-center">
                        <h3 className="flex-wrap flex-align-center">
                            <IconAlerts />
                            <span className="label">Recent Alerts</span>
                        </h3>
                        <div className="d-block-dropdown">
                            <div className='custom-select-dropdown-wrap'>
                          <select>
                              <option>All</option>
                              <option>Passwords</option>
                              <option>Phone Numbers</option>
                              <option>Email Address</option>
                              <option>Identities</option>
                          </select>
                          <div id="value-select-2" className='value-selected' onClick={ customSelectWrap }>All</div>
                          <div className='custom-select-dropdown' data-value-seletected-id="value-select-2">
                            <div className='custom-select-item' onClick={ recentAlertsFilter } data-type="all">All</div>
                            <div className='custom-select-item' onClick={ recentAlertsFilter } data-type="password">Passwords</div>
                            <div className='custom-select-item' onClick={ recentAlertsFilter } data-type="phone">Phone Numbers</div>
                            <div className='custom-select-item' onClick={ recentAlertsFilter } data-type="email">Email Addresses</div>
                            <div className='custom-select-item' onClick={ recentAlertsFilter } data-type="person">Identities</div>
                          </div>
                        </div>
                        </div>
                    </div>
                    <div id="d-alerts-loader" className="position-relative">
                        <div className="loader-spinner">
                        <span className="loader big"></span>
                        </div>
                        <div id="d-alerts-list" className="d-alerts-list">
                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default DashboardSectionAlerts