import { useEffect } from "react";
import Footer from "../sections/Footer";
import Nav from "../sections/Nav";
import social1 from '../assets/images/social-fb.png'
import social2 from '../assets/images/social-ig.png'
import social3 from '../assets/images/social-in.png'
import social4 from '../assets/images/social-tw.png'
import BannerHomepage2 from "../sections/BannerHomepage2";
import RelatedBlogPosts from "../sections/RelatedBlogPosts";
import { useParams } from "react-router-dom";
import BlogImage1 from '../assets/images/blog-single-1.jpg'


const SingleArticlePage = ({ classes }) =>{


    useEffect(() => {
        document.querySelectorAll('.lazyload-bg').forEach( (div) => {
            div.classList.add('loaded')
        })
    }, [] );

    let params = useParams();

    console.log(params)

    let title = 'Worried About Identity Theft? There’s a Reason for That.'

    if( params.id === 'blog-article2' ){
        title = 'Blog Article 2'
    }
    if( params.id === 'blog-article3' ){
        title = 'Blog Article 3'
    }


    return (
        <>
            <div className={ classes }>
                <Nav />
            </div>
            <section id="blog-post-single" className="section large-padding-section">
                <div className="page-wrapper">
                    <div className="blog-post-header text-center">
                        <div className="intro"><a href="/blog">Back to block</a></div>
                        <h1 className="med">{ title }</h1>
                        <div className="blog-categories">
                            <a href="/">Account Takeover</a>
                            <a href="/">Active Directory</a>
                            <a href="/">Password Protection</a>
                        </div>
                    </div>
                    <div className="blog-post-content">
                        <div className="blog-post-image">
                            <img src={ BlogImage1 } loading="lazy" alt="" className="img-as-block" />
                        </div>
                        <p>Did you know identity theft occurs every 22 seconds?</p>
                        <p>Identity theft is, to put it plainly, a multi-billion dollar industry… and the state of affairs has gotten substantially worse just in the last few years. In 2021, losses were estimated to cost US consumers $52 billion, compared to a jarring $635 billion predicted for 2023 alone.</p>
                        <h4>What is identity theft, and what’s happening to increase the impact so dramatically?</h4>
                        <p>Mike Wilson explains for Forbes that the goal of identity theft is to “exploit stolen information for financial gain or fraud,” which can lead immediately, and irreparably, to debt, destroyed credit scores, and personal tragedy. The Department of Justice defines identity theft as “all types of crime in which someone wrongfully obtains and uses another person’s personal data in some way that involves fraud or deception, typically for economic gain.”</p>
                        <p>When considering all the ways personal information can be stolen online, a clearer picture comes into focus.</p>
                        <p>Personal data, also known as personally identifiable information (PII) includes anything an individual might use to identify themselves. Everything from your date of birth to your driver’s license number is in this category, as well as your fingerprints, your name, your social security number, and your passwords.</p>
                        <p>Given enough of these pieces of information, a threat actor can effectively steal your identity, and use it to profit in several ways:</p>
                        <h4>1. Financial theft and fraud</h4>
                        <p>One of the most common motivations is money—a surprise to no one. When threat actors steal an individual’s credit card information, they don’t just buy Amazon gift cards and pizza. Cybercriminals can also use the information to apply for more lines of credit, request government benefits, file fraudulent tax refunds, and open new e-commerce accounts.</p>
                        <h4>2. Medical identity theft</h4>
                        <p>With key pieces of personal information, a threat actor can use your identity to obtain prescription drugs, purchase medical devices, and commit fraud through Medicare.</p>
                        <h4>3. Synthetic identity theft</h4>
                        <p>Currently, the fastest-growing type of identity theft, synthetic theft occurs when a real person’s social security number is combined with a fake identity to obtain a loan. Often elderly people are targeted as they are less likely to use credit monitoring services.</p>
                        <h4>4. Child identity theft</h4>
                        <p>Perhaps one of the more horrifying categories, cybercriminals aren’t above stealing kids’ personal information. Threat actors might even spend years building out a stolen, fake identity so they can obtain services and money.</p>
                        <p>Your PII can be stolen in many ways. Everything from phishing scams, corporate malware attacks, and hacking can lead to millions of pieces of personal information ending up on the web, as well as the dark web, being given or sold along to other nefarious actors.</p>
                        <div className="blog-post-share">
                            <a href="/"><img src={ social1 } alt="" loading="lazy" className="img-as-block" /></a>
                            <a href="/"><img src={ social2 } alt="" loading="lazy" className="img-as-block" /></a>
                            <a href="/"><img src={ social3 } alt="" loading="lazy" className="img-as-block" /></a>
                            <a href="/"><img src={ social4 } alt="" loading="lazy" className="img-as-block" /></a>
                        </div>
                    </div>
                </div>
            </section>
            <RelatedBlogPosts />
            <BannerHomepage2 />
            <Footer />
        </>
    );
}
export default SingleArticlePage