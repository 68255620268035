import { RecurlyProvider, Elements } from '@recurly/react-recurly';
import React from "react"
import { MyPaymentForm } from './recurly-payment-form';
import { fetchData } from '../helper';
import '../dashboard/assets/dashboard-responsive.css'

export async function CheckoutLoader(){
    

    /*
    let cancel = new URLSearchParams(window.location.search).get("cancel");
    console.log(cancel)
    if( cancel === 'true' ){

        console.log('its true')

        let subscription_id = new URLSearchParams(window.location.search).get("subscription_id");
        let ba_token = new URLSearchParams(window.location.search).get("ba_token");
        let token = new URLSearchParams(window.location.search).get("token");

        if( subscription_id && ba_token && token ){
            let result = await fetch(
            mongodbURL + 'update-user-account-details', {
                method: "post",
                body: JSON.stringify({ subscription_id : subscription_id, ba_token : ba_token, token : token }),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            result = await result.json();

            console.log(result)
        }
    }
    */

    const userName = fetchData('userName')
    return { userName }
}


const CheckoutSignup = ({ classes }) =>{
    return (
        <>
        <RecurlyProvider publicKey="ewr1-qKKPV0ugOiDxlrd9VFGYvn">
            <Elements>
                <MyPaymentForm />
            </Elements>
        </RecurlyProvider>
        </>
    )
}

export default CheckoutSignup