import { useEffect } from "react";
import Logo678 from "../assets/Logo678";
import ButtonCheckout from "./ButtonCheckout";
import ButtonGetInTouch from "./ButtonGetInTouch";
import ServicePageBannerImage from "../assets/images/service-page-banner.png"

const BannerVulnerableSAA = () =>{
    
    useEffect(() => {
        document.querySelectorAll('.lazyload-bg').forEach( (div) => {
            div.classList.add('loaded')
        })
    }, [] );

    return (
        <>
           <section id="page-banner" className="page-banner page-banner-style-1 service-banner radial-bg lazyload-bg section">
                <div className="page-wrapper">
                    <div className="page-banner-content">
                        <div className="intro">Vulnerability scanning and assessment</div>
                        <h1 className="med">Our platform provides</h1>
                        <p className="big">regular vulnerability scans and assessments to identify and prioritize potential security risks, allowing our clients to take proactive measures to protect their systems.</p>
                        
                        <div className="btn-flex-holder">
                            <ButtonCheckout />
                            <ButtonGetInTouch />
                        </div>
                        <div className="in-partnership">
                            <div className="logos flex-wrap flex-align-center">
                                <Logo678 />
                            </div>
                        </div>
                    </div>
                    <div className="page-banner-image">
                        <img src={ ServicePageBannerImage } alt="" className="img-as-block" />
                    </div>
                    
                </div>
            </section>
        </>
    );
}
export default BannerVulnerableSAA