import { useEffect } from "react";
import BannerHomepage2 from "../sections/BannerHomepage2";
import Footer from "../sections/Footer";
import Nav from "../sections/Nav";
import BannerFAQ from "../sections/BannerFAQ";
import FAQGeneral from "../sections/FAQGeneral";

const FAQPage = ({ classes }) =>{

    useEffect(() => {
        document.querySelectorAll('.lazyload-bg').forEach( (div) => {
            div.classList.add('loaded')
        })
    }, [] );

    return (
        <>
            <div className={ classes }>
                <Nav />
                <BannerFAQ />
            </div>
            <FAQGeneral />
            <BannerHomepage2 />
            <Footer />
        </>
    );
}
export default FAQPage