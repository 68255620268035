const IconCamera = () =>{
    return (
        <>
        
        
<svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.1875 11.125C6.1875 8.50391 8.33594 6.3125 11 6.3125C13.6211 6.3125 15.8125 8.50391 15.8125 11.125C15.8125 13.7891 13.6211 15.9375 11 15.9375C8.33594 15.9375 6.1875 13.7891 6.1875 11.125ZM11 7.6875C9.06641 7.6875 7.5625 9.23438 7.5625 11.125C7.5625 13.0586 9.06641 14.5625 11 14.5625C12.8906 14.5625 14.4375 13.0586 14.4375 11.125C14.4375 9.23438 12.8906 7.6875 11 7.6875ZM15.5547 1.54297L16.0273 2.875H19.25C20.7539 2.875 22 4.12109 22 5.625V16.625C22 18.1719 20.7539 19.375 19.25 19.375H2.75C1.20312 19.375 0 18.1719 0 16.625V5.625C0 4.12109 1.20312 2.875 2.75 2.875H5.92969L6.40234 1.54297C6.66016 0.726562 7.43359 0.125 8.33594 0.125H13.6211C14.5234 0.125 15.2969 0.726562 15.5547 1.54297ZM2.75 4.25C1.97656 4.25 1.375 4.89453 1.375 5.625V16.625C1.375 17.3984 1.97656 18 2.75 18H19.25C19.9805 18 20.625 17.3984 20.625 16.625V5.625C20.625 4.89453 19.9805 4.25 19.25 4.25H15.0391L14.2656 1.97266C14.1797 1.71484 13.9219 1.5 13.6211 1.5H8.33594C8.03516 1.5 7.77734 1.71484 7.69141 1.97266L6.91797 4.25H2.75Z" fill="#0E0F0C"/>
</svg>


        </>
    );
}
export default IconCamera