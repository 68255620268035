
import { Form, Link, useActionData } from "react-router-dom";
import logo from "../logo.png";
import safecureimage1 from "../assets/images/safe-secure-1.png";
import safecureimage2 from "../assets/images/safe-secure-2.png";
import safecureimage3 from "../assets/images/safe-secure-3.png";
import safecureimage4 from "../assets/images/safe-secure-payments.png";
import { mongodbURL } from "../helper";

export async function forgotAction({ request }){
    const data = await request.formData();
    const formData = Object.fromEntries(data)

    console.log(formData)

    let result = await fetch(
        mongodbURL + 'checkemail', {
            method: "post",
            body: JSON.stringify({ email : formData.userName }),
            headers: {
                'Content-Type': 'application/json'
            }
    })

    result = await result.json();

    if(result.status === 'email-registered') {
        return { error:'Email Sent to your mailbox containing your password.'};
    } else {
        return { error:'Something went wrong. Please Try again.'};
    }
}

const ForgotPassword = () =>{
    const data = useActionData()
    return (
        <>
        <div id="page-container" className="login-page">
            <div className="login-container">
                <div className="logo-block"><Link to="/" className="logo"><img loading="lazy" src={logo} alt="" className="img-as-block" /></Link></div>
                <div className="logo-form-wrapper">
                    <h3>Reset Password</h3>
                    <p>Enter your email to reset your password.</p>
                    <Form method="post" className="form-block">
                        { data && data.error && <p>{data.error}</p>}
                        <div className="form-field">
                            <div className="form-field-label">
                                <label>Email</label>
                            </div>
                            <input type="email" id="userName" name="userName" className="input-text" tabIndex={1} placeholder="Enter email address"  />
                        </div>
                        <div className="form-field submit">
                            <button className="btn blue-btn full-btn" tabIndex={2} type="submit">
                                <span>Reset</span>
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.5264 1.47359H2.98494C2.78953 1.47359 2.60213 1.39596 2.46395 1.25778C2.32578 1.11961 2.24815 0.932203 2.24815 0.736793C2.24815 0.541384 2.32578 0.353977 2.46395 0.215802C2.60213 0.0776263 2.78953 0 2.98494 0H13.2632C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793V11.0519C14 11.2473 13.9224 11.4347 13.7842 11.5729C13.646 11.7111 13.4586 11.7887 13.2632 11.7887C13.0678 11.7887 12.8804 11.7111 12.7422 11.5729C12.604 11.4347 12.5264 11.2473 12.5264 11.0519V1.47359Z" fill="white"></path>
                                <path d="M12.7416 0.215143C12.8799 0.0767931 13.0676 0 13.2632 0C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793C14 0.93245 13.9232 1.12009 13.7849 1.25844L1.25937 13.7839C1.19087 13.8524 1.10954 13.9068 1.02004 13.9438C0.930534 13.9809 0.834603 14 0.737724 14C0.640844 14 0.544914 13.9809 0.455409 13.9438C0.365904 13.9068 0.284578 13.8524 0.216074 13.7839C0.14757 13.7154 0.09323 13.6341 0.0561559 13.5446C0.0190818 13.4551 0 13.3592 0 13.2623C0 13.1654 0.0190818 13.0695 0.0561559 12.98C0.09323 12.8905 0.14757 12.8091 0.216074 12.7406L12.7416 0.215143Z" fill="white"></path>
                                </svg>
                            </button>
                        </div>
                    </Form>
                </div>
                <div className="secure-images">
                    <div className="first flex-wrap flex-justify-center flex-align-center col-gap-30">
                        <img loading="lazy" src={ safecureimage1 } alt="" className="img-as-block"/>
                        <img loading="lazy" src={ safecureimage2 } alt="" className="img-as-block"/>
                        <img loading="lazy" src={ safecureimage3 } alt="" className="img-as-block"/>
                    </div>
                    <div className="flex-wrap flex-justify-center flex-align-center col-gap-30">
                        <img loading="lazy" src={ safecureimage4 } alt="" className="img-as-block"/>
                    </div>
                </div>
                <div className="text-center login-copyright">
                    <div>This site is protected by reCAPTCHA and the Google <a href="/privacy-policy">Privacy Policy</a> and <a href="/terms-and-conditions">Terms of Service</a> apply.</div>
                    <div className="login-copyright-text">&copy; 2024  Copyright CyberAlerts Inc.  |  All Rights Reserved</div>
                </div>
            </div>
            </div>
        </>
    );
}
export default ForgotPassword