/* eslint-disable no-shadow */
import TimeAgo from 'react-timeago'
import React, { PureComponent } from 'react';
import { PieChart, Pie, Cell } from 'recharts';
import { fetchData } from '../helper';

const RADIAN = Math.PI / 180;
const data = [
  { name: 'A', value: 0, color: '#3D3ABB' },
  { name: 'B', value: 100, color: '#D8D8F1' },
];
const data1 = [
  { name: 'A', value: 10, color: '#3D3ABB' },
  { name: 'B', value: 90, color: '#D8D8F1' },
];
const data2 = [
  { name: 'A', value: 20, color: '#3D3ABB' },
  { name: 'B', value: 80, color: '#D8D8F1' },
];
const data3 = [
  { name: 'A', value: 30, color: '#3D3ABB' },
  { name: 'B', value: 70, color: '#D8D8F1' },
];
const data4 = [
  { name: 'A', value: 40, color: '#3D3ABB' },
  { name: 'B', value: 60, color: '#D8D8F1' },
];
const data5 = [
  { name: 'A', value: 50, color: '#3D3ABB' },
  { name: 'B', value: 50, color: '#D8D8F1' },
];
const data6 = [
  { name: 'A', value: 60, color: '#3D3ABB' },
  { name: 'B', value: 40, color: '#D8D8F1' },
];
const data7 = [
  { name: 'A', value: 70, color: '#3D3ABB' },
  { name: 'B', value: 30, color: '#D8D8F1' },
];
const data8 = [
  { name: 'A', value: 80, color: '#3D3ABB' },
  { name: 'B', value: 20, color: '#D8D8F1' },
];
const data9 = [
  { name: 'A', value: 90, color: '#3D3ABB' },
  { name: 'B', value: 10, color: '#D8D8F1' },
];
const data10 = [
  { name: 'A', value: 100, color: '#3D3ABB' },
  { name: 'B', value: 0, color: '#D8D8F1' },
];
const cx = 150;
const cy = 100;
const iR = 75;
const oR = 100;

const needle = (value, data, cx, cy, iR, oR, color) => {
  let total = 0;
  data.forEach((v) => {
    total += v.value;
  });
  const ang = 180.0 * (1 - value / total);
  const length = (iR + 2 * oR) / 3;
  const sin = Math.sin(-RADIAN * ang);
  const cos = Math.cos(-RADIAN * ang);
  const r = 5;
  const x0 = cx + 5;
  const y0 = cy + 5;
  const xba = x0 + r * sin;
  const yba = y0 - r * cos;
  const xbb = x0 - r * sin;
  const ybb = y0 + r * cos;
  const xp = x0 + length * cos;
  const yp = y0 + length * sin;

  return [
    <circle cx={x0} cy={y0} r={r} fill={color} stroke="none" />,
    <path d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`} stroke="#none" fill={color} />,
  ];
};

export default class DashboardCustomGauge extends PureComponent {
  render() {
    return (
        <>
          <h3>CyberAlerts Score</h3>
          <div className='wrapper-setup'>
          <div className="loader-spinner">
              <span className="loader big"></span>
          </div>
          <div className="db-gauge-score hide-element"><span id="gauge-risk-num"></span>/10</div>
          <div style={{ minHeight : '125px' }}>
          <div className='hide-element risk-0-block'>
          <PieChart width={300} height={125}>
              <Pie
              dataKey="value"
              startAngle={180}
              endAngle={0}
              data={data}
              cx={cx}
              cy={cy}
              innerRadius={iR}
              outerRadius={oR}
              fill="#8884d8"
              stroke="none"
              >
              {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
              </Pie>
              {needle(0, data, cx, cy, iR, oR, '#000000')}
          </PieChart>
          </div>
          <div className='hide-element risk-1-block'>
          <PieChart width={300} height={125}>
              <Pie
              dataKey="value"
              startAngle={180}
              endAngle={0}
              data={data1}
              cx={cx}
              cy={cy}
              innerRadius={iR}
              outerRadius={oR}
              fill="#8884d8"
              stroke="none"
              >
              {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
              </Pie>
              {needle(10, data1, cx, cy, iR, oR, '#000000')}
          </PieChart>
          </div>
          <div className='hide-element risk-2-block'>
          <PieChart width={300} height={125}>
              <Pie
              dataKey="value"
              startAngle={180}
              endAngle={0}
              data={data2}
              cx={cx}
              cy={cy}
              innerRadius={iR}
              outerRadius={oR}
              fill="#8884d8"
              stroke="none"
              >
              {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
              </Pie>
              {needle(20, data2, cx, cy, iR, oR, '#000000')}
          </PieChart>
          </div>
          <div className='hide-element risk-3-block'>
          <PieChart width={300} height={125}>
              <Pie
              dataKey="value"
              startAngle={180}
              endAngle={0}
              data={data3}
              cx={cx}
              cy={cy}
              innerRadius={iR}
              outerRadius={oR}
              fill="#8884d8"
              stroke="none"
              >
              {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
              </Pie>
              {needle(30, data3, cx, cy, iR, oR, '#000000')}
          </PieChart>
          </div>
          <div className='hide-element risk-4-block'>
          <PieChart width={300} height={125}>
              <Pie
              dataKey="value"
              startAngle={180}
              endAngle={0}
              data={data4}
              cx={cx}
              cy={cy}
              innerRadius={iR}
              outerRadius={oR}
              fill="#8884d8"
              stroke="none"
              >
              {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
              </Pie>
              {needle(40, data4, cx, cy, iR, oR, '#000000')}
          </PieChart>
          </div>
          <div className='hide-element risk-5-block'>
          <PieChart width={300} height={125}>
              <Pie
              dataKey="value"
              startAngle={180}
              endAngle={0}
              data={data5}
              cx={cx}
              cy={cy}
              innerRadius={iR}
              outerRadius={oR}
              fill="#8884d8"
              stroke="none"
              >
              {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
              </Pie>
              {needle(50, data8, cx, cy, iR, oR, '#000000')}
          </PieChart>
          </div>
          <div className='hide-element risk-6-block'>
          <PieChart width={300} height={125}>
              <Pie
              dataKey="value"
              startAngle={180}
              endAngle={0}
              data={data6}
              cx={cx}
              cy={cy}
              innerRadius={iR}
              outerRadius={oR}
              fill="#8884d8"
              stroke="none"
              >
              {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
              </Pie>
              {needle(60, data8, cx, cy, iR, oR, '#000000')}
          </PieChart>
          </div>
          <div className='hide-element risk-7-block'>
          <PieChart width={300} height={125}>
              <Pie
              dataKey="value"
              startAngle={180}
              endAngle={0}
              data={data7}
              cx={cx}
              cy={cy}
              innerRadius={iR}
              outerRadius={oR}
              fill="#8884d8"
              stroke="none"
              >
              {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
              </Pie>
              {needle(70, data8, cx, cy, iR, oR, '#000000')}
          </PieChart>
          </div>
          <div className='hide-element risk-8-block'>
          <PieChart width={300} height={125}>
              <Pie
              dataKey="value"
              startAngle={180}
              endAngle={0}
              data={data8}
              cx={cx}
              cy={cy}
              innerRadius={iR}
              outerRadius={oR}
              fill="#8884d8"
              stroke="none"
              >
              {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
              </Pie>
              {needle(80, data8, cx, cy, iR, oR, '#000000')}
          </PieChart>
          </div>
          <div className='hide-element risk-9-block'>
          <PieChart width={300} height={125}>
              <Pie
              dataKey="value"
              startAngle={180}
              endAngle={0}
              data={data9}
              cx={cx}
              cy={cy}
              innerRadius={iR}
              outerRadius={oR}
              fill="#8884d8"
              stroke="none"
              >
              {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
              </Pie>
              {needle(90, data9, cx, cy, iR, oR, '#000000')}
          </PieChart>
          </div>
          <div className='hide-element risk-10-block'>
          <PieChart width={300} height={125}>
              <Pie
              dataKey="value"
              startAngle={180}
              endAngle={0}
              data={data10}
              cx={cx}
              cy={cy}
              innerRadius={iR}
              outerRadius={oR}
              fill="#8884d8"
              stroke="none"
              >
              {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
              </Pie>
              {needle(100, data10, cx, cy, iR, oR, '#000000')}
          </PieChart>
          </div>
          </div>
          <div className='text-center'>
              <div className="gauge-risk-level r-low custom-risk-text">Low Risk</div>
              <div className="gauge-risk-level r-med custom-risk-text">Med Risk</div>
              <div className="gauge-risk-level r-high custom-risk-text">High Risk</div>
              <div className='gauge-last-check'>Last updated: <TimeAgo date={ fetchData('account-last-scanned') } /></div>
          </div>
          </div>
      </>
    );
  }
}
