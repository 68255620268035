import { RecurlyProvider, Elements } from '@recurly/react-recurly';
import React from "react"
import { MyPaymentForm } from './recurly-payment-form';
import '../dashboard/assets/dashboard-responsive.css'

const CheckoutSignupV2 = ({ classes }) =>{
    return (
        <>
        <RecurlyProvider publicKey="ewr1-qKKPV0ugOiDxlrd9VFGYvn">
            <Elements>
                <MyPaymentForm />
            </Elements>
        </RecurlyProvider>
        </>
    )
}

export default CheckoutSignupV2