import SecureImage1 from '../assets/images/safe-secure-1.png';
import SecureImage2 from '../assets/images/safe-secure-2.png';
import SecureImage3 from '../assets/images/safe-secure-3.png';
import ButtonCheckout from './ButtonCheckout';
import ButtonGetInTouch from './ButtonGetInTouch';

import TeamMember1 from '../assets/images/team-member-1.jpg';
import TeamMember2 from '../assets/images/team-member-2.jpg';
import TeamMember3 from '../assets/images/team-member-3.jpg';
import TeamMember4 from '../assets/images/team-member-4.jpg';
import TeamMember5 from '../assets/images/team-member-5.jpg';
import TeamMember6 from '../assets/images/team-member-6.jpg';
import TeamMember7 from '../assets/images/team-member-7.jpg';
import TeamMember8 from '../assets/images/team-member-8.jpg';

const MeetTheTeamAbout = () => {
    return (
        <>
        <section className="meet-the-team section med-padding-section">
            <div className="page-wrapper">
                <div className="small-page-content-block mb-50">
                    <div className="page-content-block text-center">
                        <div className="intro">MEET THE TEAM</div>
                        <h2>Our team of Cyber Security experts is the backbone of CyberAlerts.</h2>
                        <p>Comprising industry veterans, ethical hackers, data analysts, and passionate professionals, our diverse team collectively stands against the rising tide of cyber threats. We believe in collaboration, innovation, and a relentless pursuit of excellence.</p>
                    </div>
                </div>
                <div className="meet-the-team-block flex-wrap flex-wrap-block justify-between col-gap-48 mb-50">
                    <div className="one-half">
                        <div className="team-member-block">
                            <div className="image">
                                <img src={ TeamMember1 } alt="" className="img-as-block" loading="lazy" />
                            </div>
                            <h3>John Kennedy Doe</h3>
                            <div className="position med-text">Founder & CEO</div>
                            <div className="team-member-content">
                                <p>John is the driving force behind CyberAlerts. With over 15 years of experience in Cyber Security, he's a visionary leader dedicated to making the digital landscape safer for everyone.</p>
                            </div>
                        </div>
                    </div>
                    <div className="one-half">
                        <div className="team-member-block">
                            <div className="image">
                                <img src={ TeamMember2 } alt="" className="img-as-block" loading="lazy" />
                            </div>
                            <h3>Emily Martinez</h3>
                            <div className="position med-text">Chief Technology Officer</div>
                            <div className="team-member-content">
                                <p>Emily is our tech genius. With a knack for turning complex problems into elegant solutions, she leads our technology team in developing cutting-edge tools for dark web monitoring.</p>
                            </div>
                        </div>
                    </div>
                    <div className="one-third">
                        <div className="team-member-block">
                            <div className="image">
                                <img src={ TeamMember3 } alt="" className="img-as-block img-auto" loading="lazy" />
                            </div>
                            <h3>David Johnson</h3>
                            <div className="position med-text">Lead Cyber Security Analyst</div>
                        </div>
                    </div>
                    <div className="one-third">
                        <div className="team-member-block">
                            <div className="image">
                                <img src={ TeamMember4 } alt="" className="img-as-block img-auto" loading="lazy" />
                            </div>
                            <h3>Davidson Chen</h3>
                            <div className="position med-text">Identity Protection Specialist</div>
                        </div>
                    </div>
                    <div className="one-third">
                        <div className="team-member-block">
                            <div className="image">
                                <img src={ TeamMember5 } alt="" className="img-as-block img-auto" loading="lazy" />
                            </div>
                            <h3>Michael Reynolds</h3>
                            <div className="position med-text">Customer Support Guru</div>
                        </div>
                    </div>
                    <div className="one-third">
                        <div className="team-member-block">
                            <div className="image">
                                <img src={ TeamMember6 } alt="" className="img-as-block img-auto" loading="lazy" />
                            </div>
                            <h3>Mark Kenneth Doe</h3>
                            <div className="position med-text">Data Privacy Advocate</div>
                        </div>
                    </div>
                    <div className="one-third">
                        <div className="team-member-block">
                            <div className="image">
                                <img src={ TeamMember7 } alt="" className="img-as-block img-auto" loading="lazy" />
                            </div>
                            <h3>Alex Carter</h3>
                            <div className="position med-text">Ethical Hacker</div>
                        </div>
                    </div>
                    <div className="one-third">
                        <div className="team-member-block">
                            <div className="image">
                                <img src={ TeamMember8 } alt="" className="img-as-block img-auto" loading="lazy" />
                            </div>
                            <h3>Peter Smith</h3>
                            <div className="position med-text">Communications Specialist</div>
                        </div>
                    </div>
                </div>
                <div className="btn-flex-holder flex-justify-center">
                    <ButtonCheckout />
                    <ButtonGetInTouch />
                </div>
                <div className="first flex-wrap flex-justify-center flex-align-center col-gap-30">
                    <img loading="lazy" src={ SecureImage1 } alt="" className="img-as-block" />
                    <img loading="lazy" src={ SecureImage2 } alt="" className="img-as-block" />
                    <img loading="lazy" src={ SecureImage3 } alt="" className="img-as-block" />
                </div>
            </div>
        </section>
        </>
    );
}
export default MeetTheTeamAbout