const IconGhost = () =>{
    return (
        <>
        
        <svg width="16" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.875 0.625C12.2227 0.625 15.75 4.15234 15.75 8.5V19.6562C15.75 20.0664 15.4219 20.3125 15.0527 20.3125C14.8887 20.3125 14.7246 20.2715 14.6016 20.1484L13.5762 19.3691C13.4941 19.3281 13.3301 19.2461 13.207 19.2461C13.043 19.2461 12.7969 19.3281 12.7148 19.4512L10.9512 21.4609C10.8281 21.584 10.6641 21.666 10.459 21.666C10.2949 21.666 10.1309 21.584 10.0078 21.4609L8.32617 19.5742C8.20312 19.4102 8.03906 19.3691 7.875 19.3691C7.66992 19.3691 7.50586 19.4102 7.38281 19.5742L5.70117 21.4609C5.57812 21.584 5.41406 21.666 5.25 21.666C5.04492 21.666 4.88086 21.584 4.75781 21.4609L2.99414 19.4512C2.87109 19.3281 2.70703 19.2461 2.50195 19.2461C2.37891 19.2461 2.25586 19.2871 2.13281 19.3691L1.10742 20.1484C0.984375 20.2715 0.820312 20.3125 0.65625 20.3125C0.287109 20.3125 0 20.0664 0 19.6562V8.86914C0 4.52148 3.32227 0.789062 7.62891 0.666016C7.71094 0.666016 7.79297 0.625 7.875 0.625ZM14.4375 18.3438V8.5C14.4375 4.89062 11.4844 1.9375 7.875 1.9375L7.66992 1.97852C4.14258 2.06055 1.3125 5.13672 1.3125 8.86914V18.3438H1.35352C1.68164 18.0566 2.0918 17.9336 2.50195 17.9336C2.99414 17.9336 3.65039 18.2207 3.97852 18.5898L5.25 19.9844L6.39844 18.7129C6.76758 18.2617 7.30078 18.0566 7.875 18.0566C8.4082 18.0566 8.94141 18.2617 9.31055 18.7129L10.5 19.9844L11.7305 18.5898C12.0996 18.1797 12.6328 17.9336 13.207 17.9336C13.6172 17.9336 14.0273 18.0566 14.3555 18.3438H14.4375ZM5.25 7.1875C5.94727 7.1875 6.5625 7.80273 6.5625 8.5C6.5625 9.23828 5.94727 9.8125 5.25 9.8125C4.51172 9.8125 3.9375 9.23828 3.9375 8.5C3.9375 7.80273 4.51172 7.1875 5.25 7.1875ZM10.5 7.1875C11.1973 7.1875 11.8125 7.80273 11.8125 8.5C11.8125 9.23828 11.1973 9.8125 10.5 9.8125C9.76172 9.8125 9.1875 9.23828 9.1875 8.5C9.1875 7.80273 9.76172 7.1875 10.5 7.1875Z" fill="black"/>
        </svg>

        </>
    );
}
export default IconGhost