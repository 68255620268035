
const IconUserCircle = () =>{
    return (
        <>
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.7826 0.130434C22.9246 0.130434 29.5652 6.77106 29.5652 14.913C29.5652 23.1128 22.9246 29.6957 14.7826 29.6957C6.58288 29.6957 0 23.1128 0 14.913C0 6.77106 6.58288 0.130434 14.7826 0.130434ZM14.7826 27.8478C17.4966 27.8478 20.0374 27.0394 22.1162 25.5958C21.8852 22.7086 19.5177 20.4565 16.6304 20.4565H12.9348C9.98981 20.4565 7.62228 22.7086 7.3913 25.5958C9.47011 27.0394 12.0109 27.8478 14.7826 27.8478ZM23.733 24.2099C26.1583 21.8424 27.7174 18.551 27.7174 14.913C27.7174 7.81046 21.8852 1.97826 14.7826 1.97826C7.62228 1.97826 1.84783 7.81046 1.84783 14.913C1.84783 18.551 3.34918 21.8424 5.77446 24.2099C6.58288 21.034 9.47011 18.6087 12.9348 18.6087H16.6304C20.0374 18.6087 22.9246 21.034 23.733 24.2099ZM14.7826 7.52174C17.3234 7.52174 19.4022 9.60054 19.4022 12.1413C19.4022 14.7398 17.3234 16.7609 14.7826 16.7609C12.1841 16.7609 10.163 14.7398 10.163 12.1413C10.163 9.60054 12.1841 7.52174 14.7826 7.52174ZM14.7826 14.913C16.284 14.913 17.5543 13.7004 17.5543 12.1413C17.5543 10.6399 16.284 9.36956 14.7826 9.36956C13.2235 9.36956 12.0109 10.6399 12.0109 12.1413C12.0109 13.7004 13.2235 14.913 14.7826 14.913Z" fill="#0E0F0C"/>
            </svg>

        </>
    );
}
export default IconUserCircle