import imageWithText1 from '../assets/images/about-image-2.jpg'

const ImageWithTextAbout2 = () => {
    return (
        <>
            <section className="image-with-text content-first section">
                <div className="page-wrapper">
                    <div className="image-with-text-block">
                        <div className="image">
                            <img src={ imageWithText1 } loading="lazy" alt="" />
                        </div>
                        <div className="page-content-block">
                            <div className="intro">YOUR SECURITY MATTERS</div>
                            <h2>Solving the Growing Compromised Credentials Problem</h2>
                            <p>Attackers are getting more sophisticated each year. Organizations need solutions that combine cloud security expertise and innovative, easy-to-deploy tools to layer-in with other security measures.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default ImageWithTextAbout2