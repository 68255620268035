const BannerCBox2 = () =>{
    return (
        <>
        <section className="small-padding-top flexbox section">
            <div className="wrapper">
                <div className="custom-block-style-2 box-style-1">
                    <div className="styled-content">
                        <h2>Defense against today’s cyber threats.</h2>
                        <p>Cyber threats are ever-evolving. Cybercriminals can steal personal information through your computers and mobile devices, and there are a number of ways that they do it.</p>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
}
export default BannerCBox2