import ButtonCheckout from "./ButtonCheckout";
import ButtonGetInTouch from "./ButtonGetInTouch";
import preventIcon1 from '../assets/images/prevent-icon-1.png'
import preventIcon2 from '../assets/images/prevent-icon-2.png'
import preventIcon3 from '../assets/images/prevent-icon-3.png'
import preventIcon4 from '../assets/images/prevent-icon-4.png'
import preventIcon5 from '../assets/images/prevent-icon-5.png'
import preventIcon6 from '../assets/images/prevent-icon-6.png'

const GridBox1 = () =>{
    return (
        <>
        <section className="flexbox section">
            <div className="wrapper text-center">
                <div className="small-wrap">
                    <h2 className="big gradient-text"><span>Prevent Breaches</span></h2>
                    <h3>Defend against today’s cyber threats.</h3>
                    <p>At the core of our mission is the belief that everyone deserves peace of mind when navigating the online realm. We strive to empower individuals and families with the tools and information needed to defend against cyber-attacks, data breaches, and identity theft.</p>
                </div>

                <div className="flex-wrap flex-wrap-block col-gap-30 icon-grid">
                    <div className="one-third">
                        <div className="content-setup">
                            <div className="icon"><img loading="lazy" src={ preventIcon1 } alt="" /></div>
                            <h3>Real-Time Alerts</h3>
                            <p>You will receive instant notifications if your personal data is found on the dark web.</p>
                        </div>
                    </div>
                    <div className="one-third">
                        <div className="content-setup">
                            <div className="icon"><img loading="lazy" src={ preventIcon2 } alt="" /></div>
                            <h3>Comprehensive Database Search</h3>
                            <p>Using multiple API integrations, the tool searches extensively across various databases to ensure thorough coverage.</p>
                        </div>
                    </div>
                    <div className="one-third">
                        <div className="content-setup">
                            <div className="icon"><img loading="lazy" src={ preventIcon3 } alt="" /></div>
                            <h3>Personal Dashboard</h3>
                            <p>A user-friendly interface where you can check your current risk status, see potential threats, and get advice on how to protect yourself.</p>
                        </div>
                    </div>
                    <div className="one-third">
                        <div className="content-setup">
                            <div className="icon"><img loading="lazy" src={ preventIcon4 } alt="" /></div>
                            <h3>Monthly Reports</h3>
                            <p>Regular insights into personal data exposure trends, potential threats, and security recommendations.</p>
                        </div>
                    </div>
                    <div className="one-third">
                        <div className="content-setup">
                            <div className="icon"><img loading="lazy" src={ preventIcon5 } alt="" /></div>
                            <h3> Multi-device Support</h3>
                            <p>Get alerts on all your devices – desktop, mobile, and tablet.</p>
                        </div>
                    </div>
                    <div className="one-third">
                        <div className="content-setup">
                            <div className="icon"><img loading="lazy" src={ preventIcon6 } alt="" /></div>
                            <h3>User Education</h3>
                            <p>A built-in resource center that educates you about the latest Cyber Security threats, preventive measures, and safe online practices.</p>
                        </div>
                    </div>
                </div>

                <div className="btn-flex-holder flex-justify-center">
                    <ButtonCheckout />
                    <ButtonGetInTouch />
                </div>

            </div>
        </section>
        </>
    );
}
export default GridBox1