
const UserBlockedPage = () =>{
    return (
        <>
        <div className="blocked-page">
            <h2 className="text-black">User Blocked</h2>
            <p className="text-black">User will be restricted after 3 failed validations</p>
        </div>
        </>
    );
}
export default UserBlockedPage