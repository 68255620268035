import ButtonCheckout from "./ButtonCheckout";
import ButtonGetInTouch from "./ButtonGetInTouch";

import OverflowingImage1 from '../assets/images/overflowing-image-1.png'

const BannerHomepage2 = () =>{
    return (
        <>
        <div className="gradient-border ">
            <section className="large-padding-section section gradient-bg lazyload-bg loaded">
                <div className="wrapper">
                    <div className="custom-block-style-3 box-style-2">
                        <div className="styled-content">
                                <div className="content-area">
                                    <h2 className="big gradient-text">Safeguarding your <span>digital world</span></h2>
                                    <h4>Comprehensive Cyber Security Solutions for Businesses and Consumers</h4>
                                    <div className="btn-flex-holder">
                                        <ButtonCheckout />
                                        <ButtonGetInTouch classes="btn white-btn" />
                                    </div>
                                </div>
                                <div className="position-relative overflowing-image-1">
                                    <img loading="lazy" src={ OverflowingImage1 } alt="" className="img-as-block" />
                                </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        </>
    );
}
export default BannerHomepage2