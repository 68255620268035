import BannerCBox1 from "../sections/BannerCBox1";
import BannerCBox2 from "../sections/BannerCBox2";
import BannerHomepage from "../sections/BannerHomepage";
import GridBox1 from "../sections/GridBox1";
import GridIconBox1 from "../sections/GridIconBox1";
import SliderCustomers from "../sections/SliderCustomers";
import "../assets/js/slick.css";
import PlansAndPricing from "../sections/PlansAndPricing";
import FAQBlock from "../sections/FAQBlock";
import BannerHomepage2 from "../sections/BannerHomepage2";
import Footer from "../sections/Footer";

const Homepage = () =>{
    return (
        <>
            <BannerHomepage/>
            <BannerCBox1 />
            <GridIconBox1 />
            <BannerCBox2 />
            <GridBox1 />
            <SliderCustomers />
            <PlansAndPricing showButton="true" />
            <FAQBlock classes="section flexbox flexbox-spacing-2 faq-blocks linear-bg lazyload-bg" />
            <BannerHomepage2 />
            <Footer />
        </>
    );
}
export default Homepage