const IconMobile = () =>{
    return (
        <>
            
        <svg width="24" height="38" viewBox="0 0 24 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.5666 30.6971C14.5666 31.9969 13.4834 33.0078 12.2559 33.0078C10.9561 33.0078 9.94513 31.9969 9.94513 30.6971C9.94513 29.4695 10.9561 28.3863 12.2559 28.3863C13.4834 28.3863 14.5666 29.4695 14.5666 30.6971ZM23.8095 4.12364V34.1632C23.8095 36.1129 22.2209 37.6293 20.3434 37.6293H4.16829C2.21861 37.6293 0.702191 36.1129 0.702191 34.1632V4.12364C0.702191 2.24617 2.21861 0.657543 4.16829 0.657543H20.3434C22.2209 0.657543 23.8095 2.24617 23.8095 4.12364ZM21.4988 4.12364C21.4988 3.54596 20.9211 2.96828 20.3434 2.96828H4.16829C3.5184 2.96828 3.01292 3.54596 3.01292 4.12364V34.1632C3.01292 34.8131 3.5184 35.3185 4.16829 35.3185H20.3434C20.9211 35.3185 21.4988 34.8131 21.4988 34.1632V4.12364Z" fill="black" />
        </svg>

        </>
    );
}
export default IconMobile