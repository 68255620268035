
const BannerFAQ = () =>{
    return (
        <>
        <section className="section flexbox price-table">
            <div className="wrapper">
                <div className="small-page-content-block mb-50">
                    <div className="page-content-block text-center">
                        <div className="intro">FAQS</div>
                        <h1 className="med">Frequently Asked Questions</h1>
                        <p className="big text-black">A simple way to start protecting yourself and your family from emerging cyber threats.</p>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
}
export default BannerFAQ