import IconAssets from "../assets/svg/icon-assets";
import IconPlus from "../assets/svg/icon-plus";
import { useEffect } from "react";
import { getIdentityBubbles } from "../helper";

const DashboardSectionIdentities = ({ title }) =>{
    useEffect(() => {
        getIdentityBubbles()
    },[]);

    return (
        <>

            <div id="dashboard-block-identities" className="d-block d-identities layout-1 position-relative">
                <div className="wrap">
                    <h3><a href="/dashboard/identities"><IconAssets /> Identities at Risk</a></h3>
                    <div className="loader-spinner">
                        <span className="loader big"></span>
                    </div>
                    <a href="/dashboard/create-your-identity" className="add-new-item" id="add-new-identity-btn">
                        <IconPlus />
                    </a>

                    <div id="identities-bubbles" className="identities-bubbles">
                        <span id="identities-bubbles-insert"></span>
                    </div>

                    <div className="identities-total">
                        <span className="identity-total-large identity-overall-total">1</span>
                        <div>of <span className="identity-total-small identity-overall-total">1</span></div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default DashboardSectionIdentities