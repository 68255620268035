
import {  createBrowserRouter,  RouterProvider } from "react-router-dom";
import Sitepage, { sitepageLoader } from './layouts/sitepage';
import Error from './pages/Error';
import Homepage from './pages/Homepage';
import ServicePageTemplate from './pages/ServicePageTemplate';
import Login, { loginAction } from './pages/Login';
import Dashboard from './dashboard/Dashboard';
import { Navigate } from 'react-router-dom';
import { fetchData } from './helper';
import Logout, { LogoutLoader } from './pages/Logout';
import CheckoutSignup, { CheckoutLoader } from './pages/CheckoutSignup';
import Pricing from './pages/Pricing';
import About from './pages/About';
import ForgotPassword, { forgotAction } from './pages/ForgotPassword';
import CheckoutSignupV2 from './pages/CheckoutSignupV2';
import CheckoutThankYou from './pages/CheckoutThankYou';
import LayoutThankYou, { LayoutThankYouLoader } from './layouts/LayoutThankyou';
import FAQPage from './pages/FAQPage';
import ContactPage, { contactAction } from './pages/Contact';
import PrivacyPolicyPage, { privacyAction } from './pages/PrivacyPolicy';
import TermsAndConditionsPage, { termsAction } from './pages/TermsAndConditions';
import BlogPage from './pages/Blog';
import SingleArticlePage from './pages/SingleArticle';
import DashboardLayout, { DashboardLayoutLoader } from './layouts/DashboardLayout';
import ProtectionPage from "./dashboard/ProtectionPage";
import AssetsPage from "./dashboard/AssetsPage";
import IdentityPage from "./dashboard/IdentityPage";
import ProtectionReportPage from "./dashboard/ProtectionReportPage";
import AlertsPage from "./dashboard/AlertsPage";
import HelpPage from "./dashboard/HelpPage";
import SettingsPage from "./dashboard/SettingsPage";
import AccountPage from "./dashboard/AccountPage";
import ApiPage from "./dashboard/ApiPage";
import SupportPage from "./dashboard/SupportPage";
import AssetSinglePage from "./dashboard/AssetSinglePage";
import AlertsReportPage from "./dashboard/AlertsReportPage";
import LandingPageV2 from "./pages/LandingPageV2";
import CreateYourIdentityPage from "./dashboard/CreateYourIdentity";
import UserBlockedPage from "./pages/UserBlocked";
import IdentitiesMainPage from "./dashboard/IdentitiesMainPage";
import CheckoutReports from "./pages/CheckoutReports";
import LandingPageV2R2 from "./pages/LandingPageV2R2";
import PageDarkWebMonitoring from "./pages/PageDarkWebMonitoring";
import PageIdentityTheft from "./pages/PageIdentityTheft";
import PageCriticalCVE from "./pages/PageCriticalCVE";
import PageVulnerableSAA from "./pages/PageVulnerableSAA";

let user = fetchData('userName')

const router = createBrowserRouter([
  {
    path: "/",
    element: <Sitepage />,
    loader : sitepageLoader,
    errorElement : <Error />,
    children : [
      {
        index : true,
        element: <Homepage />,
        errorElement : <Error />
      },
      {
        path : '/service-page-template',
        element: <ServicePageTemplate />,
        errorElement : <Error />
      },
      {
        path : '/dark-web-monitoring',
        element: <PageDarkWebMonitoring />,
        errorElement : <Error />
      },
      {
        path : '/identity-theft-protection',
        element: <PageIdentityTheft />,
        errorElement : <Error />
      },
      {
        path : '/critical-cve-alerts',
        element: <PageCriticalCVE />,
        errorElement : <Error />
      },
      {
        path : '/vulnerability-scanning-and-assessment',
        element: <PageVulnerableSAA />,
        errorElement : <Error />
      }
    ]
  },
  {
    path : '/lp',
    element: <LandingPageV2 />,
    errorElement : <Error />
  },
  {
    path : '/lp-2',
    element: <LandingPageV2R2 />,
    errorElement : <Error />
  },
  {
    path : '/about',
    element: <About classes="radial2-bg lazyload-bg" />,
    errorElement : <Error />
  },
  {
    path : '/pricing',
    element: <Pricing classes="radial-bg lazyload-bg" />,
    errorElement : <Error />
  },
  {
    path : '/faq',
    element: <FAQPage classes="radial-bg lazyload-bg" />,
    errorElement : <Error />
  },
  {
    path : '/contact',
    element: <ContactPage classes="radial-bg lazyload-bg" />,
    action: contactAction,
    errorElement : <Error />
  },
  {
    path : '/login',
    element:  user ? <Navigate to="/dashboard" /> : <Login />,
    action : loginAction,
    errorElement : <Error />
  },
  {
    path : '/forgotpassword',
    element:  <ForgotPassword />,
    action : forgotAction,
    errorElement : <Error />
  },
  {
    path : '/logout',
    element: <Logout />,
    loader: LogoutLoader,
    errorElement : <Error />
  },
  {
    path: "/checkout",
    element: <LayoutThankYou />,
    loader : LayoutThankYouLoader,
    errorElement : <Error />,
    children : [
      {
        index : true,
        loader : CheckoutLoader,
        element:  <CheckoutSignup />,
        errorElement : <Error />
      },
      {
        path : '/checkout/thank-you',
        element: <CheckoutThankYou />,
        errorElement : <Error />
      },
      {
        path : '/checkout/reports',
        element: <CheckoutReports />,
        errorElement : <Error />
      }
    ]
  },
  {
    path : '/checkoutv2',
    element:  user ? <Navigate to="/dashboard" /> : <CheckoutSignupV2 />,
    errorElement : <Error />
  },
  {
    path : '/privacy-policy',
    element: <PrivacyPolicyPage classes="radial-bg lazyload-bg" />,
    action: privacyAction,
    errorElement : <Error />
  },
  {
    path : '/terms-and-conditions',
    element: <TermsAndConditionsPage classes="radial-bg lazyload-bg" />,
    action: termsAction,
    errorElement : <Error />
  },
  {
    path: "/blog",
    element: <LayoutThankYou />,
    loader : LayoutThankYouLoader,
    errorElement : <Error />,
    children : [
      {
        index : true,
        element:  <BlogPage classes="radial-bg lazyload-bg" />,
        errorElement : <Error />
      },
      {
        path : '/blog/articles/:id',
        element: <SingleArticlePage />,
        errorElement : <Error />
      }
    ]
  },
  {
    path: "/dashboard",
    element: <DashboardLayout />,
    loader : DashboardLayoutLoader,
    errorElement : <Error />,
    children : [
      {
        index : true,
        element:  user ? <Dashboard /> : <Navigate to="/login" />,
        errorElement : <Error />
      },
      {
        path : '/dashboard/protection',
        element:  user ? <ProtectionPage /> : <Navigate to="/login" />,
        errorElement : <Error />
      },
      {
        path : '/dashboard/protection/:id',
        element:  user ? <ProtectionReportPage /> : <Navigate to="/login" />,
        errorElement : <Error />
      },
      {
        path : '/dashboard/identities',
        element:  user ? <IdentitiesMainPage /> : <Navigate to="/login" />,
        errorElement : <Error />
      },
      {
        path : '/dashboard/assets',
        element:  user ? <AssetsPage /> : <Navigate to="/login" />,
        errorElement : <Error />
      },
      {
        path : '/dashboard/assets/:id',
        element:  user ? <AssetSinglePage /> : <Navigate to="/login" />,
        errorElement : <Error />
      },
      {
        path : '/dashboard/identity/:id',
        element:  user ? <IdentityPage /> : <Navigate to="/login" />,
        errorElement : <Error />
      },
      {
        path : '/dashboard/alerts',
        element:  user ? <AlertsPage /> : <Navigate to="/login" />,
        errorElement : <Error />
      },
      {
        path : '/dashboard/alerts/reports/:id/:assetid/:notificationid',
        element:  user ? <AlertsReportPage /> : <Navigate to="/login" />,
        errorElement : <Error />
      },
      {
        path : '/dashboard/help',
        element:  user ? <HelpPage /> : <Navigate to="/login" />,
        errorElement : <Error />
      },
      {
        path : '/dashboard/settings',
        element:  user ? <SettingsPage /> : <Navigate to="/login" />,
        errorElement : <Error />
      },
      {
        path : '/dashboard/account',
        element:  user ? <AccountPage /> : <Navigate to="/login" />,
        errorElement : <Error />
      },
      {
        path : '/dashboard/api',
        element:  user ? <ApiPage /> : <Navigate to="/login" />,
        errorElement : <Error />
      },
      {
        path : '/dashboard/support',
        element:  user ? <SupportPage /> : <Navigate to="/login" />,
        errorElement : <Error />
      },
      {
        path : '/dashboard/create-your-identity',
        element:  user ? <CreateYourIdentityPage /> : <Navigate to="/login" />,
        errorElement : <Error />
      }
    ]
  },
  {
    path : '/user-blocked',
    element: <UserBlockedPage />
  }
])

function App() {
  
  return <div className="App">
    <RouterProvider router={router} />
  </div>;
}

export default App;
