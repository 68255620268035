import { useEffect } from "react";
import DashboardHeader from "./dbHeader";
import DashboardPageTitle from "./dbPageTitle";
import { Form } from "react-router-dom";
import { deleteUserDataSingle, fetchData, getUserKlaviyoProfile, getUserSubscription, klaviyoAddUserToSuccessList, mongodbURL } from "../helper";

async function formSubmitSettings(e) {
    e.preventDefault()
    let currentPassword = document.querySelector('#current-password').value
    let newPassword = document.querySelector('#new-password').value
    let confirmNewPassword = document.querySelector('#confirm-new-password').value
    let errorText = document.querySelector('#error-text-holder')
    let proceed = 1

    if( currentPassword.length === 0){
        errorText.innerText = 'Please enter your current password.'
        errorText.classList.remove('hide-element')
        proceed = 2
    } else if( currentPassword.length === 0 && ( newPassword.length !== 0 || confirmNewPassword.length !== 0 ) ){
        errorText.innerText = 'Please enter your current password.'
        errorText.classList.remove('hide-element')
        proceed = 2
    } else if( currentPassword.length !== 0 && ( newPassword.length === 0 || confirmNewPassword.length === 0 ) ){
        errorText.innerText = 'Please enter your new password.'
        errorText.classList.remove('hide-element')
        proceed = 2
    } else {
        errorText.innerText = ''
        errorText.classList.add('hide-element')
    }

    if( newPassword !== confirmNewPassword && currentPassword.length !== 0){
        errorText.innerText = 'New Password doesn\'t match.'
        errorText.classList.remove('hide-element')
        proceed = 2
    }

    if( proceed === 1 ){
        document.querySelector('#modal-pop-bg-save-settings').classList.toggle('fadeIn')
        document.querySelector('#modal-save-settings').classList.toggle('fadeIn')

        let email = fetchData('userName')
        let oldpassword = currentPassword
        let password = newPassword

        let result = await fetch(
        mongodbURL + 'update-user-settings', {
            method: "post",
            body: JSON.stringify({ email, oldpassword, password }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        result = await result.json();
        console.warn(result);
        if( result ){
            setTimeout( function(){
                document.querySelector('#modal-pop-bg-save-settings').classList.toggle('fadeIn')
                document.querySelector('#modal-save-settings').classList.toggle('fadeIn')
                if( result.statustype === 2 ){
                    errorText.innerText = result.status
                    errorText.classList.remove('hide-element')
                } else {
                    errorText.innerText = result.status
                    errorText.classList.remove('hide-element')
                    errorText.classList.add('success')

                    document.querySelector('#current-password').value = ''
                    document.querySelector('#new-password').value = ''
                    document.querySelector('#confirm-new-password').value = ''
                }
            }, 1000)
        }
    }
}

const SettingsPage = () =>{

    const sendEmail = async() =>{
        let email = fetchData('userName')
        let result = await fetch(
        mongodbURL + 'cron-send-monthly-email', {
            method: "post",
            body: JSON.stringify({ email }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        result = await result.json();

        console.log(result)
    }

    useEffect(() => {
    }, [] );

    return (
        <>
        <DashboardHeader active="settings" />
        <div className="d-wrapper">
            <DashboardPageTitle title="Change Password" />
            <div className="d-block classic-d-block dashboard-change-password layout-full-width">
                <div className="wrap">
                    <span onClick={ sendEmail }>Send Email</span>
                    <Form className="dashboard-forms" onSubmit={ formSubmitSettings } >
                        <div id="error-text-holder" className="error-text-holder hide-element"></div>
                        <div className="dashboard-form-field">
                            <div className="label">
                                <label htmlFor="current-password" className="text-bold">Current Password <span className="desc">(leave blank to leave unchanged)</span></label>
                            </div>
                            <div className="field">
                                <input type="text" name="current-password" id="current-password" className="input-field" />
                            </div>
                        </div>
                        <div className="dashboard-form-field">
                            <div className="label">
                                <label htmlFor="new-password" className="text-bold">New Password <span className="desc">(leave blank to leave unchanged)</span></label>
                            </div>
                            <div className="field">
                                <input type="text" name="new-password" id="new-password" className="input-field" />
                            </div>
                        </div>
                        <div className="dashboard-form-field">
                            <div className="label">
                                <label htmlFor="confirm-new-password" className="text-bold">Confirm New Password</label>
                            </div>
                            <div className="field">
                                <input type="text" name="confirm-new-password" id="confirm-new-password" className="input-field" />
                            </div>
                        </div>
                        <div className="dashboard-form-field submit-field">
                            <div className="field flex-wrap flex-align-center flex-justify-center">
                                <input type="submit" name="submit" id="submit" value="Save Changes" />
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
        <div style={{ display: 'none' }}>
        <a href="/" onClick={ getUserSubscription }>Get User Subscription</a>
        <a href="/" onClick={ klaviyoAddUserToSuccessList }>Add User To Klaviyo List</a> | <a href="/" onClick={ deleteUserDataSingle }>Delete</a> | <a href="/" onClick={ getUserKlaviyoProfile }>Delete</a>
        </div>
        </>
    );
}
export default SettingsPage