

import { useEffect } from "react";
import DashboardSectionAlerts from "./DashboardSectionAlerts";
import DashboardSectionAssets from "./DashboardSectionAssets";
import DashboardSectionGauge from "./DashboardSectionGauge";
import DashboardSectionIdentities from "./DashboardSectionIdentities";
import DashboardSectionProtection from "./DashboardSectionProtection";
import DashboardSectionRiskActivity from "./DashboardSectionRiskActivity";
import DashboardHeader from "./dbHeader";
import DashboardPageTitle from "./dbPageTitle";
import { getProtectionAssets, getUserDetailsOnProtectionPage } from "../helper";

const Dashboard = () =>{

    useEffect(() => {
            getUserDetailsOnProtectionPage()
            getProtectionAssets()
    },[]);
    return (
        <>
        <DashboardHeader active="home" />
        <div className="d-wrapper">
            <DashboardPageTitle title="Overview" />
            <DashboardSectionGauge />
            <DashboardSectionProtection />
            <DashboardSectionAssets />
            <DashboardSectionIdentities />
            <DashboardSectionRiskActivity />
            <DashboardSectionAlerts />
        </div>
        </>
    );
}
export default Dashboard