import { useEffect } from "react";
import Footer from "../sections/Footer";
import Nav from "../sections/Nav";
import { Form, useActionData } from "react-router-dom";
import { mongodbURL } from "../helper";


export async function privacyAction({ request }){
    const data = await request.formData();
    const formData = Object.fromEntries(data)

    const formFirstName = formData.firstname
    const formLastName = formData.lastname
    const formEmail = formData.email
    const formMessage = formData.message

    let result = await fetch(
        mongodbURL + 'contact-form', {
            method: "post",
            body: JSON.stringify({ formFirstName, formLastName, formEmail, formMessage }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
    result = await result.json();
    console.warn(result);
    
    document.querySelector('#contactForm').reset()
    if( result.status === 'success' ){
        return { message:'Message Sent. We will contact you soon.' };
    } else {
        return { error:'Message Sent. We will contact you soon.' };
    }
    
}

const PrivacyPolicyPage = ({ classes }) =>{

    const data = useActionData()

    useEffect(() => {
        document.querySelectorAll('.lazyload-bg').forEach( (div) => {
            div.classList.add('loaded')
        })
    }, [] );


    return (
        <>
            <div className={ classes }>
                <Nav />
                <section className="section flexbox">
                    <div className="wrapper">
                        <div className="small-page-content-block">
                            <div className="page-content-block text-center">
                                <h1 className="med">Privacy Policy</h1>
                                <div className="intro">Effective Date: March 13, 2019</div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <section className="section flexbox small-padding-section">
                <div className="page-wrapper">
                    <div className="page-content-block large-gap">
                        <p>PasswordPing Ltd. d/b/a Enzoic and its affiliates (collectively, “Enzoic,” “PasswordPing,” “we,” “us,” or “our”) developed this Privacy Policy to explain how we collect, use, share, and protect Personal Information (defined below), and your choices about the collection and use of Personal Information.</p>
                        <p>This Privacy Policy applies to Personal Information collected or processed through our services, solutions, websites, applications, and platforms that link to this Privacy Policy (collectively, the “Service”). This Privacy Policy does not govern other websites to which we may provide links, because Enzoic is not responsible for, and cannot control the privacy practices or content of, those websites. Acceptance of this Privacy Policy, as updated from time to time, is a condition of your use of our Services, and you agree to be bound by this Privacy Policy if you use our Services.</p>
                        <p>NOTICE CONCERNING YOUR CALIFORNIA PRIVACY RIGHTS: If you are a California resident, in addition to other rights provided to you under other laws and under this Privacy Policy, California Civil Code Section 1798.83 permits you to request information regarding the disclosure of Personal Information about you by Enzoic to third parties for the third parties’ direct marketing purposes. To make such a request, please send an email to info@enzoic.com.</p>
                        <h3>Personal Information</h3>
                        <p>“Personal Information” is information that, either alone or in combination with other information, identifies you. Examples of Personal Information include, among others, name, email address, phone number, mailing address, Social Security number, date of birth, credit card information or other account or payment information. In addition, we may obtain information about you from other third parties in connection with providing you with the products or services that you request and to help us better serve our customers. For example, we may obtain information about you from sources such as public record vendors, technology providers, the Internet and other sources. When we combine Personal Information with other information (i.e., information that does not, on its own, identify you), we treat the combined information as Personal Information.</p>
                        <h3>Scope of this Privacy Policy</h3>
                        <p>This Privacy Policy only applies to Personal Information about you that we collect through the Services. This Privacy Policy does not apply to the processing of Personal Information of third parties through your use of the Services. If you provide us with Personal Information of any third parties, you (or your company) are responsible for providing any required notice to such individuals of your privacy practices.</p>
                        <h3>Collection and Use of Personal Information</h3>
                        <p>We collect and use Personal Information in various ways depending on the context and the particular Service. As part of the standard operation of our Services, we may collect Personal Information (1) provided by you; (2) from public record vendors, technology providers, the Internet and other sources; and (3) automatically through your use of our Services, including cookies. We use Personal Information about you to provide products and services to you, to verify your identity, and to protect against fraud. We also use this information for our everyday business purposes, such as quality assurance and testing; to process your transactions and communicate with you; maintain your account; respond to court orders and legal investigations; or interact with our service providers. In each case, we only collect and use a limited amount of Personal Information as necessary to provide the Services to you.</p>
                        <h3>Provided Information</h3>
                        <p>In order for you to use our Services, we collect your name, email address, and log-in credentials. We may also collect your social security number, physical address, and other Personal Information. We use this information to provide the Services, to enable to you to securely log in to the Services, to send you Service-related notifications, and to administer your account.</p>
                        <h3>Automatically Collected Information, including Cookies:</h3>
                        <p>When you use the Services, we keep logs relating to your activities on the Services (including, among others, changes you make to your account, event configurations, actions to launch broadcasts and Polls, URLs within the Services you visit, device and browser information, approximate geographic location of your device, and IP address) in order to help detect potential unauthorized use of your account and to help us troubleshoot any problems with our Services.</p>
                        <p>We also use cookies and similar technologies, which may include Personal Information, as necessary to maintain a secure log-in session. Most Internet browsers automatically accept cookies. You can decline cookies using your browser settings, but by doing so you will not be able to log into and use the Services.</p>
                        <h3>Children</h3>
                        <p>None of our products and services or websites is designed for use by minors under the age of 18. We do not direct our Services to, nor do we knowingly collect any Personal Information from, children. If we become aware that a child has provided us with Personal Information, we take commercially reasonable steps to remove such information. If you are under 18 years old, please do not submit any Personal Information to us. We may collect information from our adult consumer customers or guardians about their children if relevant to the product or service being offered.</p>
                        <h3>Sharing and Disclosure of Information</h3>
                        <p>We will not disclose Personal Information, except as set forth in this Privacy Policy or with your consent. We may disclose Personal Information as follows: (1) to our business customers in the course of providing Services to them; (2) to our service providers, who may use it only on our behalf; (3) to comply with a law, regulation or legal request or if we believe that there has been a violation of our rights or the rights of any third party; (4) to respond to judicial process and governmental investigations, and to provide information to law enforcement agencies or in connection with an investigation on matters related to national security, public safety, or otherwise as required by law; and (5) as disclosed to you at the point of collection. In addition, if Enzoic is involved in a merger, acquisition, reorganization or sale of assets, Personal Information may be transferred as part of that transaction.</p>
                        <p>We will not disclose your Personal Information except as set forth in this Privacy Policy or with your consent.</p>
                        <blockquote>
                            <h4>Security</h4>
                            <p>We take reasonable physical, electronic, contractual and administrative steps to protect the confidentiality, security and integrity of your personally identifiable information. However, no method of transmission over the Internet or method of electronic storage is completely secure, and we cannot guarantee its absolute security. Please notify us immediately at info@enzoic.com of any actual or suspected unauthorized use or breach of security with respect to our Services.</p>
                            <p>Further, while we attempt to ensure the integrity and security of Personal Information, we cannot guarantee that our security measures will prevent third parties such as so-called hackers from illegally obtaining access to Personal Information. We do not represent or warrant that Personal Information about you will be protected against, loss, misuse, or alteration by third parties.</p>
                            <h4>Retention of Personal Information</h4>
                            <p>We will retain your Personal Information for the period necessary to fulfill the purposes for which your Personal Information has been collected as outlined in this Privacy Policy unless a longer retention period is required by law.</p>
                            <h4>Your Rights Relating to Personal Information</h4>
                            <p>If you would like to access or correct your Personal Information, you may submit a request to info@enzoic.com or contact us by mail at PO Box 20896, Boulder, CO 80308. We will promptly review all such requests in accordance with applicable laws.</p>
                        </blockquote>
                        <h3>Tracking</h3>
                        <p>We do not include any features on the Services that allow a third party to track you, in personally identifiable form, over time and across third party websites. Similarly, we do not track users of the Services over time and across third party websites. Accordingly, we do not currently respond or take any action on the Services with respect to web browser “do not track” signals or other mechanisms that provide consumers the ability to exercise choice regarding the collection of Personal Information about an individual consumer’s online activities over time and across third-party web sites or online services.</p>
                        <h3>Where We Store Your Personal Information</h3>
                        <p>We are located in, and the Services are hosted and provided from, the United States of America. In order for us to provide the Services to you, it will be necessary for us to process and store Personal Information you provide to us in the United States of America and in other facility locations, which may have different data protection laws than those in the country in which you reside. By using the Services, you are consenting to the transfer of your Personal Information to facilities located in the United States and other facility locations selected by us.</p>
                        <h3>Changes to this Privacy Policy</h3>
                        <p>We may revise and update this Privacy Policy from time to time and will post the updated Privacy Policy to the Services. If these changes are material (i.e., changes we believe you will find important), we will post notice of the changes on the website we maintain in connection with the Services. To the extent required by applicable law, we will obtain your consent. Unless otherwise indicated, any changes to this Privacy Policy will apply immediately upon posting to the Services.</p>
                    </div>
                </div>
            </section>
            <section className="section flexbox large-padding-section radial3-bg lazyload-bg">
                <div className="page-wrapper">
                    <div className="flex-wrap mobile-one-block col-gap-30">
                        <div className="one-half">
                            <div className="page-content-block large-gap">
                                <h4>We'd love to hear from you if you have questions about CyberAlerts</h4>
                                <p>Please use the message form to get in touch with our sales, HR, or leadership team. If you are a client seeking support, our team is available via the help desk or by contacting your dedicated account managers.</p>
                                <div>
                                <div className="contact-email"><a href="mailto:info@cyberalerts.net">info@cyberalerts.net</a></div>
                                <div className="contact-phone"><a href="tel:1800 000 000">1800 000 000</a></div>
                                </div>
                            </div>
                        </div>
                        <div className="one-half contact-form">
                            <h2 className="med">Send us a message</h2>
                            <Form id="contactForm" method="post" className="form-block">
                                { data && data.error && <p>{data.error}</p>}
                                { data && data.message && <div className="form-notification successful">{data.message}</div>}
                                <div className="form-field">
                                    <input type="text" className="input-text" name="firstname" id="firstname" placeholder="First Name" required />
                                </div>
                                <div className="form-field">
                                    <input type="text" className="input-text" name="lastname" id="lastname"  placeholder="Last Name" required />
                                </div>
                                <div className="form-field">
                                    <input type="email" className="input-text" name="email" id="email"  placeholder="Email" required />
                                </div>
                                <div className="form-field">
                                    <input type="text" className="input-text" name="message" id="message"  placeholder="Message" required />
                                </div>
                                <div className="form-field submit">
                                    <button className="btn blue-btn" type="submit">
                                        <span>Send Message</span>
                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.5264 1.47359H2.98494C2.78953 1.47359 2.60213 1.39596 2.46395 1.25778C2.32578 1.11961 2.24815 0.932203 2.24815 0.736793C2.24815 0.541384 2.32578 0.353977 2.46395 0.215802C2.60213 0.0776263 2.78953 0 2.98494 0H13.2632C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793V11.0519C14 11.2473 13.9224 11.4347 13.7842 11.5729C13.646 11.7111 13.4586 11.7887 13.2632 11.7887C13.0678 11.7887 12.8804 11.7111 12.7422 11.5729C12.604 11.4347 12.5264 11.2473 12.5264 11.0519V1.47359Z" fill="white"/>
                                        <path d="M12.7416 0.215143C12.8799 0.0767931 13.0676 0 13.2632 0C13.4586 0 13.646 0.0776263 13.7842 0.215802C13.9224 0.353977 14 0.541384 14 0.736793C14 0.93245 13.9232 1.12009 13.7849 1.25844L1.25937 13.7839C1.19087 13.8524 1.10954 13.9068 1.02004 13.9438C0.930534 13.9809 0.834603 14 0.737724 14C0.640844 14 0.544914 13.9809 0.455409 13.9438C0.365904 13.9068 0.284578 13.8524 0.216074 13.7839C0.14757 13.7154 0.09323 13.6341 0.0561559 13.5446C0.0190818 13.4551 0 13.3592 0 13.2623C0 13.1654 0.0190818 13.0695 0.0561559 12.98C0.09323 12.8905 0.14757 12.8091 0.216074 12.7406L12.7416 0.215143Z" fill="white"/>
                                        </svg>
                                    </button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}
export default PrivacyPolicyPage