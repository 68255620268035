const IconUserAlt = () =>{
    return (
        <>
        
        <svg width="44" height="45" viewBox="0 0 44 45" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22 3.25C16.6719 3.25 12.375 7.63281 12.375 12.875C12.375 18.2031 16.6719 22.5 22 22.5C27.2422 22.5 31.625 18.2031 31.625 12.875C31.625 7.63281 27.2422 3.25 22 3.25ZM33 30.75C25.6094 30.75 27.1562 32.125 22 32.125C16.7578 32.125 18.3047 30.75 11 30.75C6.44531 30.75 2.75 34.5312 2.75 39V41.75H41.25V39C41.25 34.5312 37.4688 30.75 33 30.75ZM22 0.5C28.7891 0.5 34.375 6.08594 34.375 12.875C34.375 19.75 28.7891 25.25 22 25.25C15.125 25.25 9.625 19.75 9.625 12.875C9.625 6.08594 15.125 0.5 22 0.5ZM33 28C39.0156 28 44 32.9844 44 39V41.75C44 43.2969 42.7109 44.5 41.25 44.5H2.75C1.20312 44.5 0 43.2969 0 41.75V39C0 32.9844 4.89844 28 11 28C18.9062 28 17.1016 29.375 22 29.375C26.8984 29.375 25.0078 28 33 28Z" fill="black"/>
        </svg>

        </>
    );
}
export default IconUserAlt