const IconPhone = () =>{
    return (
        <>
        
        
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.7344 9.30078C14.2812 9.54688 14.582 10.1484 14.4453 10.7227L13.8711 13.293C13.7344 13.8672 13.2422 14.25 12.6406 14.25C5.94141 14.25 0.5 8.80859 0.5 2.10938C0.5 1.50781 0.882812 1.01562 1.45703 0.90625L4.02734 0.304688C4.60156 0.167969 5.20312 0.46875 5.44922 1.01562L6.625 3.80469C6.84375 4.29688 6.70703 4.89844 6.29688 5.25391L5.17578 6.15625C5.96875 7.57812 7.17188 8.78125 8.59375 9.57422L9.52344 8.48047C9.85156 8.04297 10.4531 7.90625 10.9453 8.09766L13.7344 9.30078ZM12.9961 13.1016L13.5977 10.5312C13.6523 10.3398 13.543 10.1758 13.3789 10.0938L10.6172 8.91797C10.4531 8.86328 10.2891 8.89062 10.1797 9.02734L9.05859 10.3945C8.92188 10.5586 8.70312 10.6133 8.53906 10.5039C6.67969 9.60156 5.14844 8.07031 4.24609 6.23828C4.13672 6.04688 4.19141 5.82812 4.35547 5.69141L5.72266 4.57031C5.85938 4.46094 5.88672 4.29688 5.83203 4.13281L4.65625 1.37109C4.57422 1.23438 4.4375 1.125 4.30078 1.125C4.27344 1.125 4.24609 1.15234 4.21875 1.15234L1.64844 1.75391C1.48438 1.78125 1.375 1.91797 1.375 2.10938C1.375 8.31641 6.43359 13.4023 12.6406 13.4023C12.832 13.4023 12.9688 13.2656 12.9961 13.1016Z" fill="#3C39B7"/>
        </svg>


        </>
    );
}
export default IconPhone