import imageWithText1 from '../assets/images/image-with-text-1.jpg'
import imageWithText2 from '../assets/images/image-with-text-2.jpg'

const ImageWithTextServicePage = () => {
    return (
        <>
            <section className="image-with-text section">
                <div className="page-wrapper">
                    <div className="image-with-text-block">
                        <div className="image">
                            <img src={ imageWithText1 } loading="lazy" alt="" />
                        </div>
                        <div className="page-content-block">
                            <div className="intro">HOW IT WORKS</div>
                            <h2>CyberAlerts Identity Breach Monitoring</h2>
                            <p>CyberAlerts helps organizations continuously monitor for the exposure of personally identifiable information (PII), reducing the risk of identity theft and data abuse for financial gain, fraud, or account takeover. Our straightforward monitoring service allows organizations to safeguard their users without building complex queries and parsing data.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="image-with-text content-first section">
                <div className="page-wrapper">
                    <div className="image-with-text-block">
                        <div className="image">
                            <img src={ imageWithText2 } loading="lazy" alt='' />
                        </div>
                        <div className="page-content-block">
                            <div className="intro">YOUR SECURITY MATTERS</div>
                            <h2>Take Control of Your Online Security</h2>
                            <p>The dark web, a hidden part of the internet not indexed by traditional search engines, is a hotbed of illicit activities. It's where stolen data, personal information, and other valuable assets are bought, sold, and traded. By actively monitoring the dark web, we can identify and mitigate potential risks to your personal and business information, giving you peace of mind.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default ImageWithTextServicePage