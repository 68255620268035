
const IconArrowLeft = () =>{
    return (
        <>
        <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.3926 18.0664C10.2285 18.2715 9.90039 18.2715 9.69531 18.0664L1.12305 9.49414C0.917969 9.28906 0.917969 9.00195 1.12305 8.79688L9.69531 0.224609C9.90039 0.0195312 10.2285 0.0195312 10.3926 0.224609L11.2129 1.00391C11.418 1.20898 11.418 1.53711 11.2129 1.70117L4.85547 8.05859H18.8828C19.1289 8.05859 19.375 8.30469 19.375 8.55078V9.69922C19.375 9.98633 19.1289 10.1914 18.8828 10.1914H4.85547L11.2129 16.5898C11.418 16.7539 11.418 17.082 11.2129 17.2871L10.3926 18.0664Z" fill="#241FFF"/>
        </svg>

        </>
    );
}
export default IconArrowLeft