
const BannerContact = () =>{
    return (
        <>
        <section className="section flexbox">
            <div className="wrapper">
                <div className="small-page-content-block mb-50">
                    <div className="page-content-block text-center">
                        <div className="intro">CONTACT US</div>
                        <h1 className="med">We’re always here to help</h1>
                        <p className="big text-black">Whether you’d like a demo, have a support question, or a pricing related query, just get in touch.</p>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
}
export default BannerContact