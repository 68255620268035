import imageWithText1 from '../assets/images/about-image-1.jpg'

const ImageWithTextAbout1 = () => {
    return (
        <>
            <section className="image-with-text section">
                <div className="page-wrapper">
                    <div className="image-with-text-block">
                        <div className="image">
                            <img src={ imageWithText1 } alt="" loading="lazy" />
                        </div>
                        <div className="page-content-block">
                            <div className="intro">COMPANY</div>
                            <h2>Keeping our customers several steps ahead of attackers.</h2>
                            <p>Praetorian is an offensive Cyber Security company whose mission is to prevent breaches before they occur. Praetorian applies an offensive filter to help organizations optimize and prioritize their Cyber Security budget on the initiatives that yield the greatest material risk reduction for the fewest dollars spent.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default ImageWithTextAbout1