import { Form } from "react-router-dom";
import IconFilters from "../assets/svg/icon-filters";
import IconProtection from "../assets/svg/icon-protection";
import { AssetProtectionCountFilter, clearProtectionFilter, fetchData, getProtectionAssets, getProtectionAssetsOnly, getUserDetailsOnProtectionPage, protectionShowFilter } from "../helper";
import DashboardSectionCreditScoreMain from "./DashboardSectionCreditScoreMain";
import DashboardHeader from "./dbHeader";
import DashboardPageTitle from "./dbPageTitle";
import IconGhost from "../assets/svg/icon-ghost";
import DashboardSectionWeeklyActivity from "./DashboardSectionWeeklyActivity";
import { useEffect } from "react";
import IconCloseBlue from "../assets/svg/icon-close-blue";

function protectionAssetFilter(e){
    e.preventDefault()
    document.querySelectorAll('.filters-asset-risk-type').forEach(el => el.classList.remove('active') )
    document.querySelectorAll('.user-assets-protection-single').forEach(el => el.classList.add('hide') )
    let dataTarget = e.target.closest('.filters-asset-risk-type').getAttribute('data-type')
    e.target.closest('.filters-asset-risk-type').classList.add('active')

    if( dataTarget === 'all'){ document.querySelectorAll('.user-assets-protection-single').forEach(el => el.classList.remove('hide') ) }
    if( dataTarget === 'high'){ document.querySelectorAll('.user-assets-protection-single.risk-type-1').forEach(el => el.classList.remove('hide') ) }
    if( dataTarget === 'low'){ document.querySelectorAll('.user-assets-protection-single.risk-type-2').forEach(el => el.classList.remove('hide') ) }
    if( dataTarget === 'need-cleaning'){ document.querySelectorAll('.user-assets-protection-single.risk-type-3').forEach(el => el.classList.remove('hide') ) }
    console.log(dataTarget)
}

const ProtectionPage = () => {

    const applyFiltersFunction = async (e) => {
        e.preventDefault();

        document.querySelector('#protection-asset-wrap').classList.remove('loaded')

        let FilterCount = AssetProtectionCountFilter();

        document.querySelector('#protection-filter .value').innerText = FilterCount.count

        if( FilterCount.count > 0 ){
            document.querySelector('#protection-filter').classList.remove('empty')
            document.querySelector('#form-filter-buttons').classList.remove('hide-element')
        } else {

            document.querySelector('#form-filter-buttons').classList.add('hide-element')
            document.querySelector('#protection-filter').classList.add('empty')
        }

        document.querySelector('#protection-filter-blocks').classList.add('hide-element');

        getProtectionAssetsOnly()
    }


    const handleChange = (e) => {
        let selectAssetType = document.querySelector('#asset-type').value
        let value = document.querySelector('#search').value
        let count = 0

        if( value.length === 0){
            document.querySelectorAll('.user-assets-protection-single').forEach(el => el.classList.remove('hide') )
            document.querySelector('#protection-assets-holder').classList.add('hide')
        } else {
            document.querySelectorAll('.user-assets-protection-single').forEach(el => el.classList.add('hide') )
            document.querySelector('#protection-assets-holder').classList.add('hide')
            document.querySelectorAll('.asset-title-search').forEach(el => {
                if( el.textContent.toLocaleLowerCase().includes(value.toLocaleLowerCase()) ){
                    if( selectAssetType === 'all'){
                        count++
                        el.closest('.user-assets-protection-single').classList.remove('hide')
                    } else {
                        if( el.closest('.user-assets-protection-single.asset-type-' + selectAssetType) ){
                            count++
                            el.closest('.user-assets-protection-single.asset-type-' + selectAssetType).classList.remove('hide')
                        }
                    }
                }
            })

            if( count === 0 ){
                document.querySelector('#protection-assets-holder').classList.remove('hide')
            } else {
                document.querySelector('#protection-assets-holder').classList.add('hide')
            }
            console.log( value )
        }
    }

    useEffect(() => {
        getUserDetailsOnProtectionPage()
        getProtectionAssets()
    },[]);


    return (
        <>
        <DashboardHeader active="protection" />
        <div className="d-wrapper">
            <DashboardPageTitle title={ <span>Hi { fetchData('userFullname') }, let’s see how well you’re protected.</span> } extraclasses={ 'reverse' } />
            <div className="custom-size-setup-1">
            <DashboardSectionCreditScoreMain />
            <div className="d-block d-asset-protection-block layout-2">
                <div className="wrap">
                    <div className="d-block-header">
                        <h3><IconProtection /> Asset Protection</h3>
                        <div id="protection-filter" className="button-style-layout empty" onClick={ protectionShowFilter }>
                            <span className="icon"><IconFilters /></span>
                            <span className="label">Filters</span>
                            <span className="value">0</span>
                        </div>
                    </div>
                    <Form>
                        <div id="protection-filter-blocks" className="form-filter-blocks hide-element">
                            <h5>Select your filters</h5>
                            <a href="/" className="filter-close" onClick={ protectionShowFilter }><IconCloseBlue /></a>
                            <div className="form-actions">
                                <div className="form-custom-dropdown">
                                    <div className="form-custom-dropdown-wrap">
                                        <label htmlFor="filter-asset-type">Asset type</label>
                                        <select name="filter-asset-type" id="filter-asset-type">
                                            <option value="all">All</option>
                                            <option value="email">Email</option>
                                            <option value="phone">Phone</option>
                                            <option value="password">Password</option>
                                            <option value="identity">Person</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="form-custom-dropdown">
                                    <div className="form-custom-dropdown-wrap">
                                        <label htmlFor="filter-date-range">Date Range</label>
                                        <select name="filter-date-range" id="filter-date-range">
                                            <option value="all" selected>Display All</option>
                                            <option value="today">Today</option>
                                            <option value="last-7-days">Last 7 Days</option>
                                            <option value="last-week">Last Week</option>
                                            <option value="this-month">This Month</option>
                                            <option value="last-30-days">Last 30 days</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="form-actions">
                                <div className="form-custom-dropdown">
                                    <div className="form-custom-dropdown-wrap">
                                        <label htmlFor="filter-identity">Identity</label>
                                        <select name="filter-identity" id="filter-identity">
                                            <option value="all">All</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="form-custom-dropdown">
                                    <div className="form-custom-dropdown-wrap">
                                        <label htmlFor="filter-asset-status">Asset Status</label>
                                        <select name="filter-asset-status" id="filter-asset-status">
                                            <option value="active">Active</option>
                                            <option value="inactive">Inactive</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="form-actions-button">
                                <a id="p-apply-filter-btn" href="/dashboard/protection" className="apply-filter-btn btn" onClick={ applyFiltersFunction }>Apply Filters</a> <a href="/dashboard/protection">Clear all Filters</a>
                            </div>
                        </div>

                        <div className="form-actions">
                            <div className="form-custom-dropdown" style={{ display: 'none' }}>
                                <div className="form-custom-dropdown-wrap">
                                    <label htmlFor="asset-type">Search asset type</label>
                                    <select name="asset-type" id="asset-type" onChange={ handleChange }>
                                        <option value="all">All</option>
                                        <option value="email">Email</option>
                                        <option value="phone">Phone</option>
                                        <option value="password">Password</option>
                                        <option value="identity">Person</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-searchbar">
                                <input type="search" name="search" id="search" placeholder="Search assets" onChange={ handleChange } />
                            </div>
                        </div>
                        <div id="form-filter-buttons" className="form-filter-buttons hide-element">
                            <div className="label-block">Result filtered by</div>
                            <div id="form-filter-buttons-wrap" class="form-filter-button-wrap">
                                <span class="filter-button hide-element" id="protection-filter-block-1" onClick={ clearProtectionFilter }>
                                    <span class="label">Asset Type - Email</span>
                                    <span class="icon"></span>
                                </span>
                                <span class="filter-button hide-element" id="protection-filter-block-2" onClick={ clearProtectionFilter }>
                                    <span class="label">Date Range - Last 7 Days</span>
                                    <span class="icon"></span>
                                </span>
                                <span class="filter-button hide-element" id="protection-filter-block-3" onClick={ clearProtectionFilter }>
                                    <span class="label">Identity - Inactive</span>
                                    <span class="icon"></span>
                                </span>
                                <span class="filter-button hide-element" id="protection-filter-block-4" onClick={ clearProtectionFilter }>
                                    <span class="label">Status - Inactive</span>
                                    <span class="icon"></span>
                                </span>
                            </div>
                        </div>
                        <div className="position-relative" id="protection-asset-wrap">
                            <div className="loader-spinner">
                                <span className="loader big"></span>
                            </div>
                            <div className="filters-asset-risk-types">
                                <a href="/" id="filter-protection-show-all-assets" className="filters-asset-risk-type active" onClick={ protectionAssetFilter } data-type="all">
                                    <span className="intro-label">See All</span>
                                    <span className="label">All Assets</span>
                                    <span className="value count-all-assets">0</span>
                                </a>
                                <a href="/" id="filter-protection-show-high-risks" className="filters-asset-risk-type" onClick={ protectionAssetFilter } data-type="high">
                                    <span className="intro-label high-risk">High Risk</span>
                                    <span className="label">High Risk Assets</span>
                                    <span className="value count-high-risks">0</span>
                                </a>
                                <a href="/" id="filter-protection-show-low-risks" className="filters-asset-risk-type" onClick={ protectionAssetFilter } data-type="low">
                                    <span className="intro-label">Low Risk</span>
                                    <span className="label">Low Risk Asset</span>
                                    <span className="value count-low-risks">0</span>
                                </a>
                                <a href="/" id="filter-protection-show-attention" className="filters-asset-risk-type" onClick={ protectionAssetFilter } data-type="need-cleaning">
                                    <span className="intro-label">Premium</span>
                                    <span className="label">Marked Safe</span>
                                    <span className="value count-need-cleaning">0</span>
                                </a>
                            </div>
                            <div className="user-assets-protection-layout d-block d-main-asset-block layout-full-width" id="user-assets-protection-layout">
                                <h4 id="protection-assets-holder" className="hide">No Assets Found</h4>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
            </div>
            <div className="d-block d-multiple-blocks-small layout-2">
                <div className="wrap d-block-plan-details">
                    <div className="d-block-header">
                        <h3><IconGhost /> Plan Details</h3>
                    </div>
                    <div className="d-block-plan-block">
                        <div className="title">Current Plan:</div>
                        <div className="value">Dark Web Monitoring<a href="/dashboard/account"><span>(</span>change<span>)</span></a></div>
                    </div>
                    <div className="d-block-plan-block">
                        <div className="title">Current Usage:</div>
                        <div className="table-row-setup">
                            <div className="label">Monitored Identities:</div>
                            <div className="value-bar number-of-identities">
                                <span className="score">0/5</span>
                                <span className="bar" data-score="0"></span>
                            </div>
                        </div>
                        <div className="table-row-setup">
                            <div className="label">Monitored Assets:</div>
                            <div className="value-bar number-of-assets">
                                <span className="score">4/10</span>
                                <span className="bar" data-score="4"></span>
                            </div>
                        </div>
                        <div className="table-row-setup">
                            <div className="label">Monitored Domains:</div>
                            <div className="value-bar number-of-domains">
                                <span className="score">1/5</span>
                                <span className="bar" data-score="1"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <DashboardSectionWeeklyActivity />
            </div>
        </div>
        </>
    );
}
export default ProtectionPage