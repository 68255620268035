import Slider from "react-slick";
import AuthorImage1 from "../assets/images/author-image-1.jpg"
import AuthorImage2 from "../assets/images/author-image-2.jpg"
import ratingStars from "../assets/images/ratings-stars.png"

const SliderCustomersV2 = () =>{
    var settings = {
        dots: true,
        auto: false,
        infinite: false,
        speed: 300,
        arrows:false,
        slidesToShow: 2,
        variableWidth: true,
        adaptiveHeight: true,
        responsive: [
            {
            breakpoint: 1120,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                variableWidth: false,
            }
            }
        ]
    };
    return (
        <>
        <section className="flexbox section slider-block">
            <div className="wrapper">
                <div className="small-wrap text-center">
                    <h3 className="text-uppercase">testimonials</h3>
                    <h2 className="big gradient-text"><span>See what our customers say about us</span></h2>
                </div>
                <div className="slider-area-wrapa">
                    <div className="slideshow-masterb">
                    <Slider {...settings}>
                    <div className="custom-slide-setup">
                            <div className="custom-slide-wrapper">
                                <div className="flex-wrap slider-testimonial">
                                    <div className="content">
                                        <h4>Ever since I started using CyberAlerts, my peace of mind has skyrocketed! </h4>
                                        <p>As someone who was hacked in the past, I understand the significance of Cyber Security, but I was always concerned about how to effectively protect my identity from potential threats. Their Dark Web monitoring service is a revelation. I had no idea that my employees' email addresses and passwords were circulating on the dark web. With CyberAlerts, I now receive immediate alerts whenever there's a potential data leak, allowing me to take prompt action and secure our accounts. It's like having a constant watchful eye on all our digital credentials.</p>
                                        <div className="author">
                                            <div class="author-image">
                                            <img src={ AuthorImage1 } alt="" className="img-as-block" />
                                            </div>
                                            <div>
                                                <div className="author-rating"><img src={ ratingStars } alt="" className="img-as-block" /></div>
                                                <div className="author-name">Jeff Wake</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="custom-slide-setup">
                            <div className="custom-slide-wrapper">
                                <div className="flex-wrap slider-testimonial">
                                    <div className="content">
                                        <h4>Absolutely love our Cyber Security service! </h4>
                                        <p>Their proactive threat monitoring and real-time alerts provide unparalleled peace of mind. With their expert team available 24/7, we know our systems are safeguarded from potential breaches. The personalized security strategies they offer have significantly bolstered our defenses, ensuring our sensitive data remains protected. Their dedication to excellence and customer support is unmatched. Highly recommend to anyone serious about securing their digital environment!"</p>
                                        <div className="author">
                                            <div class="author-image">
                                            <img src={ AuthorImage2 } alt="" className="img-as-block" />
                                            </div>
                                            <div>
                                                <div className="author-rating"><img src={ ratingStars } alt="" className="img-as-block" /></div>
                                                <div className="author-name">Jeff Wake</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="custom-slide-setup">
                            <div className="custom-slide-wrapper">
                                <div className="flex-wrap slider-testimonial">
                                    <div className="content">
                                        <h4>Ever since I started using CyberAlerts, my peace of mind has skyrocketed! </h4>
                                        <p>As someone who was hacked in the past, I understand the significance of Cyber Security, but I was always concerned about how to effectively protect my identity from potential threats. Their Dark Web monitoring service is a revelation. I had no idea that my employees' email addresses and passwords were circulating on the dark web. With CyberAlerts, I now receive immediate alerts whenever there's a potential data leak, allowing me to take prompt action and secure our accounts. It's like having a constant watchful eye on all our digital credentials.</p>
                                        <div className="author">
                                            <div class="author-image">
                                            <img src={ AuthorImage1 } alt="" className="img-as-block" />
                                            </div>
                                            <div>
                                                <div className="author-rating"><img src={ ratingStars } alt="" className="img-as-block" /></div>
                                                <div className="author-name">Jeff Wake</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="custom-slide-setup">
                            <div className="custom-slide-wrapper">
                                <div className="flex-wrap slider-testimonial">
                                    <div className="content">
                                        <h4>Absolutely love our Cyber Security service! </h4>
                                        <p>Their proactive threat monitoring and real-time alerts provide unparalleled peace of mind. With their expert team available 24/7, we know our systems are safeguarded from potential breaches. The personalized security strategies they offer have significantly bolstered our defenses, ensuring our sensitive data remains protected. Their dedication to excellence and customer support is unmatched. Highly recommend to anyone serious about securing their digital environment!"</p>
                                        <div className="author">
                                            <div class="author-image">
                                            <img src={ AuthorImage2 } alt="" className="img-as-block" />
                                            </div>
                                            <div>
                                                <div className="author-rating"><img src={ ratingStars } alt="" className="img-as-block" /></div>
                                                <div className="author-name">Jeff Wake</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
}
export default SliderCustomersV2